import { DateTime } from 'luxon'
import {
  clearTokens,
  getRefreshToken,
  getToken,
  saveTokens,
} from '../services/storage'
import { UserRole } from './enums'
import { UserSettings } from './userSettings'
import { UserChecklist } from './userChecklist'
import { roundTo2Decimals } from '../Modules/DisputeModules/AppFunctions'

export class User {
  firstName: string
  lastName: string
  email: string
  role: UserRole
  hasRegistered: string
  registeredDate: DateTime | undefined
  passwordDate: DateTime | undefined
  tfaType: string | undefined
  token: string
  refreshToken: string
  companyName: string
  companyDomain: string
  companyLogo: string
  settings: UserSettings
  checklist: UserChecklist

  constructor(
    firstName: string,
    lastName: string,
    email: string,
    role: UserRole,
    hasRegistered: string,
    registeredDate: DateTime | undefined,
    passwordDate: DateTime | undefined,
    tfaType: string | undefined,
    token: string,
    refreshToken: string,
    companyName: string,
    companyDomain: string,
    companyLogo: string,
    settings: UserSettings,
    checklist: UserChecklist,
  ) {
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.role = role
    this.hasRegistered = hasRegistered
    this.registeredDate = registeredDate
    this.passwordDate = passwordDate
    this.tfaType = tfaType
    this.token = token
    this.refreshToken = refreshToken
    this.companyName = companyName
    this.companyDomain = companyDomain
    this.companyLogo = companyLogo
    this.settings = settings
    this.checklist = checklist
  }

  fullName(): string {
    return this.firstName + ' ' + this.lastName
  }

  static UserFromDB(data: any): User {
    if (data.token) {
      saveTokens(data.token, data.refresh_token)
    }

    return new User(
      data.first_name,
      data.last_name,
      data.username,
      User.userRoleFromDB(data.user_role)!,
      data.has_registered,
      data.registered_date === 'None'
        ? undefined
        : DateTime.fromISO(data.registered_date.replace(' ', 'T')),
      data.password_date === 'None'
        ? undefined
        : DateTime.fromISO(data.password_date.replace(' ', 'T')),
      data.device_type,
      data.token ?? getToken(),
      data.refresh_token ?? getRefreshToken(),
      data.company_name,
      data.company_domain,
      data.company_logo,
      UserSettings.FromJson(data.settings),
      UserChecklist.FromJson(data.checklist),
    )
  }

  static EmptyUser(withoutClear?: boolean): User {
    if (!withoutClear) {
      clearTokens()
    }
    return new User(
      '',
      '',
      '',
      UserRole.SIMPLE_USER,
      '',
      undefined,
      undefined,
      undefined,
      '',
      '',
      '',
      '',
      '',
      UserSettings.FromJson(),
      UserChecklist.FromJson(),
    )
  }

  static EmptyUserFreemium(): User {
    return new User(
      '',
      '',
      '',
      UserRole.SIMPLE_USER,
      '',
      undefined,
      undefined,
      undefined,
      '',
      '',
      '',
      '',
      '',
      UserSettings.DefaultFreemiumUserSettings() as any,
      UserChecklist.FromJson(),
    )
  }

  isLoggedIn(): boolean {
    return this.token !== ''
  }

  calculateChecklistDone(isFreemium: boolean) {
    let done = 0
    if (this.checklist.introVideo) {
      done++
    }
    if (this.checklist.accessTutorial) {
      done++
    }
    if (this.checklist.createCase) {
      done++
    }
    if (this.checklist.analyzeScenario) {
      done++
    }
    if (this.checklist.downloadReport && !isFreemium) {
      done++
    }

    return roundTo2Decimals(done / (isFreemium ? 4 : 5)) * 100
  }

  static userRoleFromDB(role: string) {
    switch (role) {
      case 'EPEROTO_ADMIN':
        return UserRole.EPEROTO_ADMIN
      case 'GLOBAL_ADMIN':
        return UserRole.GLOBAL_ADMIN
      case 'SIMPLE_USER':
        return UserRole.SIMPLE_USER
      case 'COMPANY_ADMIN':
        return UserRole.COMPANY_ADMIN
      case 'APP_VIEWER':
        return UserRole.APP_VIEWER
      default:
        return undefined
    }
  }
}
