import { useRecoilState, useRecoilValue } from 'recoil'
import { recoveryModeState } from '../../../../../../states/RecoveryModeState'
import { onlineState } from '../../../../../../states/OnlineState'
import { viewerState } from '../../../../../../states/ViewerState'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import clientImg from '../../../../../../resources/images/279-clientSymbol.svg'
import krogerusClientImg from '../../../../../../resources/images/303-clientSymbolKrogerus.svg'
import opposingPartyImg from '../../../../../../resources/images/280-opposingPartySymbol.svg'
import krogerusOpposingPartyImg from '../../../../../../resources/images/304-krogerusOpposingPartySymbol.svg'
import { getText } from '../../../../../../services/textFunctions'
import { userState } from '../../../../../../states/UserState'
import { krogerusColorsState } from '../../../../../../states/KrogerusColorsState'
import { resultsSettingsState } from '../../../../../../states/ResultsSettingsState'
import tickedImg from '../../../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../../../resources/images/018-untickedGrayBlue.svg'
import { saveResultsSettings } from '../../../../../../services/requests'
import { scenarioIdentityState } from '../../../../../../states/ScenarioIdentityState'
import { freemiumState } from '../../../../../../states/FreemiumState'
import { logActivity } from '../../../../../../services/commonFunctions'
import { FreemiumMessageType } from '../../../../../../models/freemiumEnums'
import PremiumFeatureIcon from '../../../../../../Components/PremiumFeatureIcon/PremiumFeatureIcon'

type Props = {
  resultsViewParty: 'client' | 'opposing'
  setResultsViewParty: (value: 'client' | 'opposing') => void
}

const PartyViewSwitch = (props: Props) => {
  const recoveryMode = useRecoilValue(recoveryModeState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)
  const online = useRecoilValue(onlineState)
  const user = useRecoilValue(userState)
  const isViewer = useRecoilValue(viewerState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const [resultsSettings, setResultsSettings] =
    useRecoilState(resultsSettingsState)

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const thereIsAnotherLowerScreenMessage =
    recoveryMode.showLowScreenMessage || !online.networkOn || isViewer.isViewer

  async function changeRoundedResults() {
    //Mixpanel 189 (All)
    logActivity(freemium.isFreemium, 'Pressed Round Figures in the Results')

    if (freemium.isFreemium) {
      setFreemium({
        ...freemium,
        showMessage: FreemiumMessageType.General,
      })
    } else {
      saveResultsSettings(
        scenarioIdentity.caseId,
        scenarioIdentity.scenarioId,
        {
          ...resultsSettings,
          settings: {
            ...resultsSettings.settings,
            roundedResults: !resultsSettings.settings.roundedResults,
          },
        },
      )
      setResultsSettings({
        ...resultsSettings,
        settings: {
          ...resultsSettings.settings,
          roundedResults: !resultsSettings.settings.roundedResults,
        },
      })
    }
  }
  return (
    <div
      className={`resultsPartySwitchContainer ${
        thereIsAnotherLowerScreenMessage ? 'higher' : ''
      }`}
    >
      <div
        className={`resultsPartySwitch first ${
          props.resultsViewParty === 'client' ? 'selected' : ''
        }`}
        onClick={() => props.setResultsViewParty('client')}
      >
        <img
          className="img"
          src={krogerusColors ? krogerusClientImg : clientImg}
          alt="clientImg"
        />
        <p className="resultsPartyText" id="partyViewSwitchButtonText-client">
          {getText('title-260', user.settings, partiesFormat)}
        </p>
      </div>
      <div
        className={`resultsPartySwitch ${
          props.resultsViewParty === 'opposing' ? 'selected' : ''
        }`}
        onClick={() => props.setResultsViewParty('opposing')}
      >
        <img
          className="img"
          src={krogerusColors ? krogerusOpposingPartyImg : opposingPartyImg}
          alt="opposingPartyImg"
        />
        <p
          className="resultsPartyText"
          id="partyViewSwitchButtonText-opposingParty"
        >
          {getText('title-261', user.settings, partiesFormat)}
        </p>
      </div>
      <div
        id="roundedResultsButton"
        className={`roundedResultsButton ${
          resultsSettings.settings.roundedResults ? 'active' : ''
        }`}
        onClick={changeRoundedResults}
      >
        {freemium.isFreemium && (
          <PremiumFeatureIcon targetId={`roundedResultsButtonText`} />
        )}
        <div
          className="roundedResultsButtonTickContainer"
          id="roundedResultsButtonTickContainer"
        >
          {resultsSettings.settings.roundedResults === true ? (
            <img
              src={tickedImg}
              alt="tickedImg"
              className="tickImg"
              id={`tickedButton-roundedResultsButton`}
              onClick={changeRoundedResults}
            />
          ) : (
            <img
              src={unTickedImg}
              alt="unTickedImg"
              className="tickImg"
              id={`untickedButton-roundedResultsButton`}
              onClick={() => changeRoundedResults}
            />
          )}
        </div>
        <p className="roundedResultsButtonText" id="roundedResultsButtonText">
          Round figures
        </p>
      </div>
    </div>
  )
}

export default PartyViewSwitch
