import React, { createRef, useEffect, useState } from 'react'
import { useXarrow } from 'react-xarrows'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../../../states/UserState'

import drag from '../../../../../../../../resources/images/165-crossForTree.svg'
import addMiddleChildGray from '../../../../../../../../resources/images/168-addEventCross.svg'
import addMiddleChildPurple from '../../../../../../../../resources/images/149-addEvent.svg'
import canBeConnectedImg from '../../../../../../../../resources/images/205-orangeDetector.svg'
import isConnectedImg from '../../../../../../../../resources/images/206-purpleIndicator.svg'

import { TreeEventClass } from '../../../../../../../../models/treeModels/treeEvent'
import { TreeNodeClass } from '../../../../../../../../models/treeModels/treeNode'
import {
  DeleteMenuType,
  DeleteNodeOrEventMenuObject,
  EventId,
  NodeId,
  NodeMode,
  RootNodeType,
} from '../../../../../../../../models/treeModels/treeTypes'
import {
  TreeClaim,
  TreeDetails,
} from '../../../../../../../../models/treeModels/treeClaim'

import TextArea from '../../../../../../../../Components/Inputs/TextArea/TextArea'
import DeleteNodeButton from './DeleteNodeButton'
import DuplicateNodeButton from './DuplicateNodeButton'
import SelectNodeButton from './SelectNodeButton'
import CreateLineConnection from './CreateLineConnection'

import { DefaultSnapshotState } from '../../../../../../../../models/generalTypes'
import { getText } from '../../../../../../../../services/textFunctions'
import {
  findMainTreeRoot,
  findOrphanTreeRoots,
  oneOfTheSelectedNodesHasChildren,
} from '../../../../../../../../services/treeFunctions/treeBasicFunctions'
import { isMac } from '../../../../../../../../services/commonFunctions'
import { AccessRole } from '../../../../../../../../models/enums'
import emptyImg from '../../../../../../../../resources/images/209-empty.svg'
import { allowShortcutsState } from '../../../../../../../../states/AllowShortcutsState'
import CopyNodeButton from './CopyNodeButton'
import { krogerusColorsState } from '../../../../../../../../states/KrogerusColorsState'

type Props = {
  nodeId: NodeId
  root: RootNodeType
  treeIndex: number
  events: { [eventId: EventId]: TreeEventClass }
  nodeDetails: TreeNodeClass
  children: any
  localNodeMode: NodeMode
  nodeHasChildren: boolean
  handleAddEvent: (e: any) => void
  handleNodeTitleChange: (e: any) => void
  handleDeleteTreeNode: (
    withChildren: boolean,
    nodeId: NodeId,
    selectedNodes?: NodeId[],
  ) => void
  handleDuplicateNodes: () => void
  handleCopyNodes: () => void
  selectedNodes: NodeId[]
  setSelectedNodes: (selectedNodes: NodeId[]) => void
  zoomLevel: number
  nodeCreatingLine: NodeId | undefined
  setNodeCreatingLine: (nodeCreatingLine: NodeId | undefined) => void
  setDragPosition: (param: any) => void
  lineClick: [NodeId, EventId] | undefined
  setChangingLineStartId: (changingLineStartId: string | undefined) => void
  availableTargetsForLine: (NodeId | EventId)[]
  setAvailableTargetsForLine: (
    availableEventsForLine: (NodeId | EventId)[],
  ) => void
  handleChangeLineNode: (nodeId: NodeId) => void
  showTreeInfo: boolean
  scenarioSnapshot: DefaultSnapshotState
  treeDetails: TreeDetails
  debugging: boolean
  setOpenDeleteNodeOrEventMenu: (
    openDeleteNodeorEventMenu: DeleteNodeOrEventMenuObject | undefined,
  ) => void
  orphanTrees: NodeId[][]
  connectedDot: NodeId | EventId | undefined
  setConnectedDot: (connectedDot: EventId | NodeId | undefined) => void
  handleConnectNewLineEvent: (
    eventId: EventId,
    connectionNodeId: NodeId,
  ) => void
  highlighted: boolean
  ownRole: AccessRole
  connectionCreatesLoop: EventId | NodeId | undefined
  copied: boolean
}

export default function TreeNodeTitleComponent(props: Props) {
  const updateXarrow = useXarrow()
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const allowShortcuts = useRecoilValue(allowShortcutsState)

  const circleRef = createRef<HTMLDivElement>()
  const [nodeTitleHover, setNodeTitleHover] = useState(false)
  const [addEventHover, setAddEventHover] = useState(false)
  const [dragTimeoutOn, setDragTimeoutOn] = useState(true)
  const img = new Image()
  img.src = emptyImg

  useEffect(() => {
    if (!props.nodeCreatingLine) {
      setNodeTitleHover(false)
    }
  }, [props.nodeCreatingLine])

  useEffect(() => {
    if (props.selectedNodes.includes(props.nodeId)) {
      document.addEventListener('mousedown', removeSelectedNodes)
      document.addEventListener('keydown', removeSelectedNodesKeyDown)
    }
    return () => {
      document.removeEventListener('mousedown', removeSelectedNodes)
      document.removeEventListener('keydown', removeSelectedNodesKeyDown)
    }

    // eslint-disable-next-line
  }, [props.selectedNodes, allowShortcuts])

  function removeSelectedNodes(e: any) {
    if (!e.shiftKey) {
      const eTargetNodeId =
        e.target.id.split('_').length > 0 ? e.target.id.split('_')[1] : ''
      if (
        (!e.target.id.includes('selectNode') &&
          !e.target.id.includes('tempButton') &&
          !e.target.id.includes('treeNodeMoveHandle') &&
          !e.target.id.includes('deleteNodeButton') &&
          !e.target.id.includes('treeSideMenu') &&
          !e.target.id.includes('deleteNodeOrEventMenu') &&
          !e.target.id.includes('duplicateNode') &&
          !e.target.id.includes('copyNode')) ||
        (!props.selectedNodes.includes(eTargetNodeId) &&
          e.target.id.includes('deleteNodeButton')) ||
        (!props.selectedNodes.includes(eTargetNodeId) &&
          e.target.id.includes('duplicateNode'))
      ) {
        props.setSelectedNodes([])
      }
    }
  }

  function removeSelectedNodesKeyDown(e: any) {
    if (allowShortcuts) {
      if (
        (e.code === 'KeyZ' && e.ctrlKey && e.shiftKey && !isMac()) ||
        (e.code === 'KeyZ' && e.metaKey && e.shiftKey && isMac()) ||
        (e.code === 'KeyZ' && e.ctrlKey && !e.shiftKey && !isMac()) ||
        (e.code === 'KeyZ' && e.metaKey && !e.shiftKey && isMac())
      ) {
        props.setSelectedNodes([])
      }
    }
  }

  function handleDeleteNodeClick(e: any) {
    //Not selected Nodes conditions
    const nodeIsLastNode = Object.keys(props.treeDetails.nodes).length === 1
    const nodeIsLastRoot =
      findMainTreeRoot(props.treeDetails) === props.nodeId &&
      findOrphanTreeRoots(props.treeDetails).length === 0
    const nodeHasNoChildren = props.nodeHasChildren === false
    const nodeHasChildren = props.nodeHasChildren === true

    //Selected Nodes conditions
    const thereAreSelectedNodes = props.selectedNodes.length !== 0
    const selectedNodesIncludeLastRootAndThereAreNoOrphans =
      props.selectedNodes.includes(findMainTreeRoot(props.treeDetails)) &&
      findOrphanTreeRoots(props.treeDetails).length === 0
    const selectedNodesIncludeAllRoots =
      props.selectedNodes.includes(findMainTreeRoot(props.treeDetails)) &&
      findOrphanTreeRoots(props.treeDetails).filter((orphanRoot) =>
        props.selectedNodes.includes(orphanRoot as NodeId),
      ).length === findOrphanTreeRoots(props.treeDetails).length
    const atLeastOneOfTheSelectedNodesHasChildren =
      oneOfTheSelectedNodesHasChildren(props.selectedNodes, props.treeDetails)
    const selectedNodesAreAllNodes =
      props.selectedNodes.length === Object.keys(props.treeDetails.nodes).length

    setNodeTitleHover(false)
    if (!thereAreSelectedNodes) {
      if (nodeIsLastNode) {
        props.setOpenDeleteNodeOrEventMenu({
          deleteMenuType: DeleteMenuType.lastNode,
          nodeId: props.nodeId,
          nodeMode: props.localNodeMode,
        })
      } else if (nodeIsLastRoot) {
        props.setOpenDeleteNodeOrEventMenu({
          deleteMenuType: DeleteMenuType.lastRoot,
          nodeId: props.nodeId,
          nodeMode: props.localNodeMode,
        })
      } else if (nodeHasChildren) {
        props.setOpenDeleteNodeOrEventMenu({
          deleteMenuType: DeleteMenuType.node,
          nodeId: props.nodeId,
          nodeMode: props.localNodeMode,
        })
      } else if (nodeHasNoChildren) {
        props.handleDeleteTreeNode(false, props.nodeId)
      }
    } else if (thereAreSelectedNodes) {
      if (nodeIsLastNode || selectedNodesAreAllNodes) {
        props.setOpenDeleteNodeOrEventMenu({
          deleteMenuType: DeleteMenuType.lastNode,
          nodeId: props.nodeId,
          nodeMode: props.localNodeMode,
        })
      } else if (
        selectedNodesIncludeLastRootAndThereAreNoOrphans &&
        !nodeIsLastRoot
      ) {
        props.setOpenDeleteNodeOrEventMenu({
          deleteMenuType: DeleteMenuType.deleteOnlySelectedNodes,
          nodeId: props.nodeId,
          nodeMode: props.localNodeMode,
        })
      } else if (
        selectedNodesIncludeLastRootAndThereAreNoOrphans &&
        nodeIsLastRoot
      ) {
        props.setOpenDeleteNodeOrEventMenu({
          deleteMenuType: DeleteMenuType.selectedNodesLastRoot,
          nodeId: props.nodeId,
          nodeMode: props.localNodeMode,
        })
      } else if (selectedNodesIncludeAllRoots) {
        props.setOpenDeleteNodeOrEventMenu({
          deleteMenuType: DeleteMenuType.selectedNodesLastRoot,
          nodeId: props.nodeId,
          nodeMode: props.localNodeMode,
        })
      } else if (
        !selectedNodesIncludeLastRootAndThereAreNoOrphans &&
        atLeastOneOfTheSelectedNodesHasChildren
      ) {
        props.setOpenDeleteNodeOrEventMenu({
          deleteMenuType: DeleteMenuType.selectedNodesWithChildren,
          nodeId: props.nodeId,
          nodeMode: props.localNodeMode,
        })
      } else if (!atLeastOneOfTheSelectedNodesHasChildren) {
        props.handleDeleteTreeNode(false, props.nodeId, props.selectedNodes)
      }
    }
  }

  function handleSelectNodeClick(e: any) {
    let tempSelectedNodes = [...props.selectedNodes]

    if (tempSelectedNodes.includes(props.nodeId)) {
      tempSelectedNodes.splice(
        tempSelectedNodes.findIndex(
          (tempNodeId) => tempNodeId === props.nodeId,
        ),
        1,
      )
    } else {
      tempSelectedNodes.push(props.nodeId)
    }
    props.setSelectedNodes(tempSelectedNodes)
  }

  return (
    <>
      <div
        className={
          props.localNodeMode === NodeMode.maximised
            ? 'treeNodeBackground'
            : 'treeNodeBackground minimised'
        }
        style={
          props.debugging &&
          !props.selectedNodes.includes(props.nodeId) &&
          props.orphanTrees.some((orphanTree) =>
            orphanTree.includes(props.nodeId),
          )
            ? { backgroundColor: 'red' }
            : undefined
        }
        id={`treeNodeBackground-${props.treeIndex}_${props.nodeId}`}
      >
        <div
          className={
            props.localNodeMode === NodeMode.maximised
              ? 'treeNodeMoveHandle'
              : 'treeNodeMoveHandle minimised'
          }
          id={`treeNodeMoveHandle-${props.treeIndex}_${props.nodeId}`}
        >
          {props.showTreeInfo ? (
            <p className="treeNodeNumber">
              {Object.keys(
                (
                  props.scenarioSnapshot.currentSnapshot.claims[
                    props.treeIndex
                  ] as TreeClaim
                ).treeDetails.nodes,
              ).map((nodeId, index) => {
                if (props.nodeId === nodeId) {
                  return index
                } else {
                  return ''
                }
              })}
            </p>
          ) : null}

          <img
            src={drag}
            alt="dragImg"
            id={`treeNodeMoveHandleImg-${props.treeIndex}_${props.nodeId}`}
          />
          <div
            className="treeNodeMoveHandleTransparentCover"
            id={`treeNodeMoveHandleTransparentCover-${props.treeIndex}_${props.nodeId}`}
          ></div>
        </div>

        <div
          className={`treeNodeTitle ${
            props.selectedNodes.includes(props.nodeId) || props.highlighted
              ? 'selected'
              : ''
          } ${
            props.ownRole === AccessRole.VIEWER &&
            props.nodeDetails.nodeTitle === ''
              ? 'viewerPlaceholder'
              : ''
          } ${krogerusColors ? 'krogerus' : ''}`}
          id={`treeNodeTitle-${props.treeIndex}_${props.nodeId}`}
          onMouseEnter={() => {
            if (props.ownRole !== AccessRole.VIEWER) {
              setNodeTitleHover(true)
            }
          }}
          onMouseLeave={() =>
            props.nodeCreatingLine === props.nodeId
              ? {}
              : setNodeTitleHover(false)
          }
        >
          {props.connectionCreatesLoop === props.nodeId ? (
            <div className="connectionCreatesLoopMessageContainerNode shake">
              <p
                className="connectionCreatesLoopMessageText"
                id={`connectionCreatesLoopMessageText-${props.treeIndex}_${props.nodeId}`}
              >
                This connection creates a loop
              </p>
            </div>
          ) : undefined}
          {props.availableTargetsForLine.includes(props.nodeId) ? (
            <div
              className="connectionCircleNodeAvailable"
              id={`connectionCircleNodeAvailable-${props.treeIndex}_${props.nodeId}`}
              onDragEnter={(e: any) => props.setConnectedDot(props.nodeId)}
            >
              <img
                src={
                  props.connectedDot === props.nodeId
                    ? isConnectedImg
                    : canBeConnectedImg
                }
                alt="dotConnectionImg"
              />
            </div>
          ) : null}
          <div
            className={`connectionCircle  ${
              props.selectedNodes.includes(props.nodeId) || props.highlighted
                ? 'selected'
                : null
            } ${props.lineClick?.includes(props.nodeId) ? 'clicked' : null}`}
            id={`connectionCircle-${props.treeIndex}_${props.nodeId}`}
            draggable={props.lineClick?.includes(props.nodeId)}
            onDragStart={(e: any) => {
              props.setChangingLineStartId(
                `treeAddNodeDot-${props.treeIndex}_${
                  props.events[props.lineClick![1]].nodeOfEventId
                }!${props.events[props.lineClick![1]].eventIndex}`,
              )
              if (e && e.dataTransfer) {
                e.dataTransfer.setDragImage(img, 0, 0)
              }
            }}
            onDrag={(e) => {
              if (dragTimeoutOn) {
                setDragTimeoutOn(false)
                setTimeout(() => {
                  // updateXarrow()
                  props.setDragPosition({
                    position: 'fixed',
                    left: e.clientX,
                    top: e.clientY,
                    transform: 'none',
                    opacity: 0,
                  })
                  setDragTimeoutOn(true)
                }, 60)
              }
            }}
            onDragEnd={() => {
              props.setChangingLineStartId(undefined)
              props.setDragPosition(undefined)
              if (props.connectedDot) {
                props.handleChangeLineNode(props.connectedDot as NodeId)
              }
              props.setConnectedDot(undefined)
            }}
            ref={circleRef}
          ></div>

          {nodeTitleHover ? (
            <>
              <DeleteNodeButton
                nodeId={props.nodeId}
                root={props.root}
                treeIndex={props.treeIndex}
                handleDeleteNodeClick={handleDeleteNodeClick}
              />

              <DuplicateNodeButton
                nodeId={props.nodeId}
                treeIndex={props.treeIndex}
                handleDuplicateNodeClick={props.handleDuplicateNodes}
              />

              <CopyNodeButton
                nodeId={props.nodeId}
                treeIndex={props.treeIndex}
                handleCopyNodeClick={props.handleCopyNodes}
                copied={props.copied}
              />

              <SelectNodeButton
                nodeId={props.nodeId}
                treeIndex={props.treeIndex}
                nodeSelected={props.selectedNodes.includes(props.nodeId)}
                handleSelectNodeClick={handleSelectNodeClick}
              />
              {props.lineClick === undefined ||
              (props.lineClick !== undefined &&
                props.lineClick[0] !== props.nodeId) ? (
                <CreateLineConnection
                  nodeId={props.nodeId}
                  treeIndex={props.treeIndex}
                  zoomLevel={props.zoomLevel}
                  nodeCreatingLine={props.nodeCreatingLine}
                  setNodeCreatingLine={props.setNodeCreatingLine}
                  setNodeTitleHover={setNodeTitleHover}
                  setDragPosition={props.setDragPosition}
                  connectedDot={props.connectedDot}
                  setConnectedDot={props.setConnectedDot}
                  handleConnectNewLineEvent={props.handleConnectNewLineEvent}
                />
              ) : null}
            </>
          ) : props.copied ? (
            <CopyNodeButton
              nodeId={props.nodeId}
              treeIndex={props.treeIndex}
              handleCopyNodeClick={props.handleCopyNodes}
              copied={props.copied}
            />
          ) : null}
          {props.ownRole === AccessRole.VIEWER &&
          props.nodeDetails.nodeTitle === '' ? (
            <div
              className="noNodeTitleTextArea"
              id={`noNodeTitleTextArea-${props.treeIndex}_${props.nodeId}`}
            ></div>
          ) : (
            <TextArea
              className={`treeNodeTitleTextarea ${
                props.localNodeMode === NodeMode.maximised ? '' : 'minimised'
              } ${
                props.selectedNodes.includes(props.nodeId) || props.highlighted
                  ? 'selected'
                  : ''
              }`}
              id={`treeNodeTitleTextarea-${props.treeIndex}_${props.nodeId}`}
              placeholder={
                props.localNodeMode === NodeMode.minimised &&
                props.nodeDetails.numberOfEvents === 1
                  ? getText('placeholder-3', user.settings)
                      .substring(0, 20)
                      .trim() + '...'
                  : getText('placeholder-3', user.settings)
              }
              placeholderTextAttribute="placeholder-3"
              value={props.nodeDetails ? props.nodeDetails.nodeTitle : ''}
              onChange={props.handleNodeTitleChange}
              updateLines={() => {
                updateXarrow()
              }}
              maxLength={162}
              tabIndex={
                props.nodeDetails
                  ? 1000 + 100 * props.nodeDetails.properties.position[1]
                  : 0
              }
              disabled={props.ownRole === AccessRole.VIEWER}
              withoutDisabledMessage
            />
          )}
        </div>
        <div
          className={
            props.localNodeMode === NodeMode.maximised
              ? 'treeEventsContainer'
              : 'treeEventsContainer minimised'
          }
          id={`treeEventsContainer-${props.treeIndex}_${props.nodeId}`}
        >
          {props.children}
        </div>
        {props.nodeDetails &&
        props.nodeDetails.numberOfEvents < 10 &&
        props.localNodeMode === NodeMode.maximised ? (
          <>
            {/* <WhiteTooltipMoreDelay
              title={getText('tooltip-12', user.settings)}
              textAttribute="tooltip-12"
              id="addEventTooltip"
              placement="right-end"
            > */}
            <div
              className={addEventHover ? 'treeAddEvent purple' : 'treeAddEvent'}
              onMouseEnter={() => {
                setAddEventHover(true)
              }}
              onMouseLeave={() => {
                setAddEventHover(false)
              }}
              id={`treeAddEvent-${props.treeIndex}_${props.nodeId}`}
              onClick={props.handleAddEvent}
            >
              <img
                src={addEventHover ? addMiddleChildPurple : addMiddleChildGray}
                alt="plus"
                id={`treeAddEventImg-${props.treeIndex}_${props.nodeId}`}
                className="treeAddEventImg"
              />
              {addEventHover ? (
                <p
                  className="treeAddEventText"
                  id={`treeAddEventText-${props.treeIndex}_${props.nodeId}`}
                  data-attribute="button-80"
                >
                  {getText('button-80', user.settings)}
                </p>
              ) : null}
            </div>
            {/* </WhiteTooltipMoreDelay> */}
          </>
        ) : null}
      </div>
    </>
  )
}
