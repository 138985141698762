import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  ListItem,
  Params,
  SortDirection,
  SortOption,
  UserForAccess,
} from '../../models/generalTypes'
import { useNavigate, useParams } from 'react-router-dom'
import { ScenarioSnapshot } from '../../models/scenarioSnapshot'
import {
  changeCaseName,
  changeScenarioName,
  createAllTutorials,
  deleteCase,
  deleteScenario,
  duplicateCase,
  duplicateScenario,
  getCases,
  getDeletedCases,
  getDeletedScenarios,
  getScenarios,
  restoreCase,
  restoreScenario,
  saveCase,
  saveScenario,
  saveScenarioSnapshot,
  saveUserSettings,
  createTutorial,
  updateUserChecklist,
} from '../../services/requests'
import { userState } from '../../states/UserState'
import LoadingCasesPlaceHolder from '../../pages/myCases_myScenarios/myCasesComponents/LoadingCasesPlaceHolder'
import EmptyMessage from '../../pages/myCases_myScenarios/myCasesComponents/EmptyMessage'
import FullScreenPopMessage from '../popUps/FullScreenPopMessage'
import { handlingErrorsState } from '../../states/HandlingErrorsState'
import {
  deepCloneObject,
  findHandlingErrorState,
  isJSON,
  isMac,
  logActivity,
  userIsAdmin,
} from '../../services/commonFunctions'
import { onlineState } from '../../states/OnlineState'
import MyCasesTitles from '../../pages/myCases_myScenarios/myCasesComponents/MyCasesTitles'
import { getText } from '../../services/textFunctions'
import { User } from '../../models/user'
import {
  AccessRole,
  AccessRoleKeys,
  UserRole,
  userRoleFromString,
  UserRoleKeys,
} from '../../models/enums'
import useWindowSize from '../../customHooks/useWindowSize'
import BottomPart from './components/BottomPart'
import TopPart from './components/TopPart'
import ItemPart from './components/ItemPart'
import EmptyBinPopUp from './components/EmptyBinPopUp'
import useWebSocket from 'react-use-websocket'
import { getSocketUrl } from '../../services/socket_services'
import { UserChecklist } from '../../models/userChecklist'
import { featuresState } from '../../states/FeaturesState'
import { allowShortcutsState } from '../../states/AllowShortcutsState'

type Props = {
  createItemList: (res: any, role: UserRole) => ListItem[]
  createItem: (res: any, role: UserRole, noIdentity?: boolean) => ListItem
  itemName: 'Scenario' | 'Case'
  navigateToNextPage: (uuid: string) => void
  getNavigateLink: (uuid: string) => string
  isMyScenariosPage?: boolean
  statusDiv?: (uuid: string) => JSX.Element
  caseName?: string
  tutorialId?: string
  usersListWhenNoScenarios?: UserForAccess[]
  setUsersListWhenNoScenarios?: (param: UserForAccess[] | undefined) => void
  showDeleted?: boolean
}

const ListPageTemplate = (props: Props) => {
  const [list, setList] = useState<ListItem[]>([])
  const features = useRecoilValue(featuresState)

  const [searchList, setSearchList] = useState<ListItem[]>([])
  const [loading, setLoading] = useState(true)
  const [deleteItemIndex, setDeleteItemIndex] = useState(-1)
  const [renameItemIndex, setRenameItemIndex] = useState(-1)
  const [disabledActionItemIndex, setDisabledActionItemIndex] = useState(-1)
  const [deleteWindowCoordinates, setDeleteWindowCoordinates] = useState<
    [number, number] | undefined
  >(undefined)
  const [addNewItem, setAddNewItem] = useState(false)
  const [updateUsersAccess, setUpdateUsersAccess] = useState(1)
  const [nameInput, setNameInput] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [widthOfCaseContainer, setWidthOfCaseConainer] = useState<
    number | undefined
  >(
    document.getElementById('caseContainer')
      ? document.getElementById('caseContainer')!.offsetWidth
      : undefined,
  )
  const [requestLoading, setRequestLoading] = useState<undefined | string>(
    undefined,
  )
  const [showUsersShare, setShowUsersShare] = useState(false)
  const [user, setUser] = useRecoilState(userState)
  const [caseIdForUsers, setCaseIdForUsers] = useState<
    | undefined
    | {
        caseId: string
        caseName: string
        users: UserForAccess[]
      }
  >(undefined)
  const [resize, setResize] = useState(false)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [errorFound, setErrorFound] = useState(false)
  const addNewCaseContainerRef = useRef<HTMLDivElement>(null)
  const caseTitleContainerRef = useRef<HTMLDivElement>(null)
  const [online, setOnline] = useRecoilState(onlineState)
  const [openAccessPopUp, setOpenAccessPopUp] = useState(false)
  const windowSize = useWindowSize()
  const [openEmptyBinPopUp, setOpenEmptyBinPopUp] = useState(false)
  const [rerender, setRerender] = useState(0)
  const [copyItemMessage, setCopyItemMessage] = useState<
    undefined | { index: number; message: string }
  >(undefined)
  const [nothingToPaste, setNothingToPaste] = useState(false)
  const allowShortcuts = useRecoilValue(allowShortcutsState)

  const params = useParams<Params>()
  const navigate = useNavigate()

  const currentSortOption = props.isMyScenariosPage
    ? user.settings.sortings.scenarioSorting[0]
    : user.settings.sortings.caseSorting[0]
  const currentSortDirection = props.isMyScenariosPage
    ? user.settings.sortings.scenarioSorting[1]
    : user.settings.sortings.caseSorting[1]

  const { lastJsonMessage } = useWebSocket(getSocketUrl(`groups`), {
    onOpen: () => {
      console.log('opened')
    },
    reconnectInterval: 3000,
    reconnectAttempts: 3,
    onError(event) {
      console.log(event)
    },
    shouldReconnect: () => {
      return true
    },
  })

  useEffect(() => {
    if (
      lastJsonMessage &&
      (lastJsonMessage as any).type === 'channel_group_changed'
    ) {
      const tempList = [...list]
      if (props.isMyScenariosPage) {
        const index = tempList.findIndex(
          (item) => item.uuid === (lastJsonMessage as any).case.caseid,
        )
        tempList[index] = props.createItem(
          { data: (lastJsonMessage as any).case },
          user.role,
          true,
        )
      } else {
        const index = tempList.findIndex(
          (item) => item.uuid === (lastJsonMessage as any).group.groupid,
        )
        tempList[index] = props.createItem(
          {
            data: (lastJsonMessage as any).group,
          },
          user.role,
        )
      }

      updateSortSearch(tempList)
    }

    // eslint-disable-next-line
  }, [lastJsonMessage])

  useEffect(() => {
    const noErrors = Object.values(handlingErrors).every((item) => !item)
    if (noErrors) {
      setErrorFound(false)
    } else {
      setErrorFound(true)
    }
  }, [handlingErrors])

  useEffect(() => {
    setLoading(true)
    setDeleteItemIndex(-1)
    setShowUsersShare(features.share_case)

    getItems().then((res) => {
      if (res) {
        updateSortSearch(res)
        setLoading(false)
        setResize(true)
      }
    })

    // eslint-disable-next-line
  }, [updateUsersAccess, props.showDeleted, params.caseId, rerender])

  useEffect(() => {
    if (props.isMyScenariosPage && !loading) {
      //Mixpanel 10
      logActivity(false, 'Opened a case to see the scenarios')
    }
    // eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    handleDeleteWindowPosition()
    findWidthOfCaseContainer()
    if (resize) {
      findWidthOfCaseContainer()
      setResize(false)
    }

    // eslint-disable-next-line
  }, [deleteItemIndex, windowSize, resize])

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    document.addEventListener('keydown', onKeyPress, false)

    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
      document.removeEventListener('keydown', onKeyPress, false)
    }
    // eslint-disable-next-line
  }, [
    renameItemIndex,
    addNewItem,
    nameInput,
    requestLoading,
    errorFound,
    allowShortcuts,
    list,
    searchList,
  ])

  const saveItem = async () => {
    const res = props.isMyScenariosPage
      ? await saveScenario(params.caseId!, nameInput.trim())
      : await saveCase(nameInput.trim())
    setHandlingErrors(
      findHandlingErrorState(
        res,
        handlingErrors,
        props.isMyScenariosPage ? 'saveScenario' : 'saveCase',
      ),
    )
    if ('errorCode' in res) {
      return undefined
    }
    return props.createItem(res, user.role)
  }

  const getItems = async () => {
    let res
    if (props.isMyScenariosPage) {
      res = props.showDeleted
        ? await getDeletedScenarios(params.caseId!)
        : await getScenarios(params.caseId!)
    } else {
      res = props.showDeleted ? await getDeletedCases() : await getCases()
    }

    setHandlingErrors(
      findHandlingErrorState(
        res,
        handlingErrors,
        props.isMyScenariosPage ? 'getScenarios' : 'getCases',
      ),
    )
    if ('errorCode' in res) {
      return undefined
    }
    return props.createItemList(res, user.role)
  }

  const sortBy = (
    option: SortOption,
    changeDirection: boolean,
    listToSort?: ListItem[],
  ) => {
    const comparator = (a: ListItem, b: ListItem, direction: SortDirection) => {
      let coption: keyof ListItem = option
      if (
        props.showDeleted &&
        option === 'lastEdited' &&
        a['dateDeleted'] &&
        b['dateDeleted']
      ) {
        coption = 'dateDeleted'
      }
      if (
        props.showDeleted &&
        option === 'lastEditedBy' &&
        a['deletedBy'] &&
        b['deletedBy']
      ) {
        coption = 'deletedBy'
      }

      if (
        a[coption]!.toString().toLowerCase() >
        b[coption]!.toString().toLowerCase()
      )
        return direction === 'up' ? 1 : -1
      if (
        a[coption]!.toString().toLowerCase() <
        b[coption]!.toString().toLowerCase()
      )
        return direction === 'up' ? -1 : 1
      return 0
    }

    const tempDirection: SortDirection = changeDirection
      ? option === currentSortOption
        ? currentSortDirection === 'up'
          ? 'down'
          : 'up'
        : 'up'
      : currentSortDirection

    let tempList = listToSort ? [...listToSort] : [...searchList]
    if (option === 'dateCreated' || option === 'lastEdited') {
      tempList = tempList.sort((a: ListItem, b: ListItem) =>
        comparator(b, a, tempDirection),
      )
    } else {
      tempList = tempList.sort((a: ListItem, b: ListItem) =>
        comparator(a, b, tempDirection),
      )
    }

    if (changeDirection) {
      let newUser: User = deepCloneObject(user)
      newUser.settings = {
        ...newUser.settings,
        sortings: {
          caseSorting: props.isMyScenariosPage
            ? [...newUser.settings.sortings.caseSorting]
            : [option, tempDirection],
          scenarioSorting: props.isMyScenariosPage
            ? [option, tempDirection]
            : [...newUser.settings.sortings.scenarioSorting],
        },
      }

      setUser(newUser)
      saveUserSettings(newUser.settings)
    }

    return tempList
  }

  const handleSearchList = (key: string, newList?: ListItem[]) => {
    const filterFunc = (item: ListItem) => {
      //Creates an array with tha shared users' 'firstName lastName' and filters the one that include key
      let itemSharedByUser =
        item.users &&
        item.users
          .map(
            (user) =>
              user.first_name.toLowerCase() +
              ' ' +
              user.last_name.toLowerCase(),
          )
          .filter((value) => value.includes(key)).length > 0

      return (
        item.title.toLowerCase().includes(key) ||
        item.createdBy.toLowerCase().includes(key) ||
        item.dateCreated.toLowerCase().includes(key) ||
        item.lastEdited.toLowerCase().includes(key) ||
        itemSharedByUser
      )
    }

    return newList ? newList.filter(filterFunc) : list.filter(filterFunc)
  }

  const handleDeleteWindowPosition = (
    index?: number,
    disabledAction?: boolean,
  ) => {
    let dInxex = index === undefined ? deleteItemIndex : index

    if (document.getElementById(`deleteCase${dInxex}`)) {
      let deleteButton = document.getElementById(`deleteCase${dInxex}`)
      let viewportOffset = deleteButton?.getBoundingClientRect()

      if (viewportOffset) {
        // these are relative to the viewport, i.e. the window
        let top = viewportOffset.top + 22
        let left = viewportOffset.left + 29
        let h = window.innerHeight
        if (h - top < 200) {
          top -= 130
        }
        setDeleteWindowCoordinates([top, left])

        if (index !== undefined) {
          if (disabledAction) {
            setDisabledActionItemIndex(index)
          } else {
            setDeleteItemIndex(index)
          }
        }
      }
    }
  }

  function findWidthOfCaseContainer() {
    if (document.getElementById('caseContainer'))
      setWidthOfCaseConainer(
        document.getElementById('caseContainer')!.offsetWidth,
      )
  }

  const onKeyPress = (e: any) => {
    if (allowShortcuts) {
      if (e.key === 'Escape' && requestLoading === undefined) {
        setDeleteItemIndex(-1)
        setRenameItemIndex(-1)
        setDisabledActionItemIndex(-1)
        setDeleteWindowCoordinates(undefined)
        setNameInput('')
      } else if (e.key === 'Enter' && requestLoading === undefined) {
        if (renameItemIndex > -1) {
          handleRenameItem(true)
        } else if (addNewItem) {
          handleAddNewItem()
        }
      } else if (
        ((e.code === 'KeyV' && e.ctrlKey && !isMac()) ||
          (e.code === 'KeyV' && e.metaKey && isMac())) &&
        e.target.tagName.toLowerCase() !== 'textarea' &&
        e.target.tagName.toLowerCase() !== 'input' &&
        props.isMyScenariosPage &&
        !isActionDisabled(undefined, 'paste') &&
        requestLoading === undefined
      ) {
        e.preventDefault()
        handlePasteItem()
      }
    }
  }

  const onMouseDown = (e: MouseEvent) => {
    if (!errorFound) {
      if (
        addNewItem &&
        addNewCaseContainerRef &&
        addNewCaseContainerRef.current &&
        !addNewCaseContainerRef.current.contains(e.target as Node) &&
        requestLoading === undefined
      ) {
        setAddNewItem(false)
        setNameInput('')
      }

      if (
        renameItemIndex > -1 &&
        caseTitleContainerRef &&
        caseTitleContainerRef.current &&
        !caseTitleContainerRef.current.contains(e.target as Node)
      ) {
        if (e.target) {
          const id = (e.target as HTMLImageElement).id
          if (id.includes('renameCase')) {
            // id = 'alertForRenameCase'
            alert(
              `${
                props.itemName === 'Scenario'
                  ? // text-attribute='alert-1'
                    getText('alert-1', user.settings)
                  : // text-attribute='alert-2'
                    getText('alert-2', user.settings)
              }`,
            )
          } else if (id.includes('duplicateCase')) {
            // id = 'alertForDuplicateCase'
            alert(
              `${
                props.itemName === 'Scenario'
                  ? // text-attribute='alert-3'
                    getText('alert-3', user.settings)
                  : // text-attribute='alert-4'
                    getText('alert-4', user.settings)
              }`,
            )
          } else if (id.includes('deleteCase')) {
            // id = 'alertForDeleteCase'
            alert(
              `${
                props.itemName === 'Scenario'
                  ? // text-attribute='alert-5'
                    getText('alert-5', user.settings)
                  : // text-attribute='alert-6'
                    getText('alert-6', user.settings)
              }`,
            )
          } else if (requestLoading !== 'renameItemSaveButton') {
            setRenameItemIndex(-1)
            setNameInput('')
          }
        } else if (requestLoading !== 'renameItemSaveButton') {
          setRenameItemIndex(-1)
          setNameInput('')
        }
      }
    }
  }

  const handleAddNewItem = () => {
    if (online.networkOn) {
      const newName = nameInput.trim()

      if (checkName(newName)) {
        setRequestLoading('createCaseButton')
        saveItem().then((newItem) => {
          if (newItem) {
            const newList = [...list, newItem]
            updateSortSearch(newList)
            setAddNewItem(false)
            setNameInput('')

            if (props.isMyScenariosPage) {
              saveScenarioSnapshot(params.caseId!, newItem.uuid, {
                data: ScenarioSnapshot.toJson(
                  userIsAdmin(user)
                    ? ScenarioSnapshot.EmptySnapshotWithDateAndCourt(
                        user.settings.formats.partyFormat,
                        user.settings.formats.opposingPartyFormat,
                        user.settings.formats.currency,
                      )
                    : ScenarioSnapshot.EmptySnapshot(
                        user.settings.formats.partyFormat,
                        user.settings.formats.opposingPartyFormat,
                        user.settings.formats.currency,
                      ),
                ),
                keep_results: false,
                log_entry: 'Save scenario',
              }).then((res2) => {
                setHandlingErrors(
                  findHandlingErrorState(
                    res2,
                    handlingErrors,
                    'saveScenarioSnapshot',
                    true,
                  ),
                )
                if (!('errorCode' in res2)) {
                  //Mixpanel 3
                  logActivity(false, 'Created a scenario')
                  props.navigateToNextPage(newItem.uuid)
                }
              })
            } else {
              //Mixpanel 2
              logActivity(false, 'Created a case')
              props.navigateToNextPage(newItem.uuid)
              if (!user.checklist.createCase) {
                updateUserChecklist(
                  new UserChecklist(
                    user.checklist.introVideo,
                    user.checklist.accessTutorial,
                    true,
                    user.checklist.analyzeScenario,
                    user.checklist.downloadReport,
                  ),
                ).then((res) => {
                  if (!('errorCode' in res)) {
                    let newUser = deepCloneObject(user) as User
                    newUser.checklist = UserChecklist.FromJson(res.data)
                    setUser(newUser)
                  }
                })
              }
            }
          }
          setRequestLoading(undefined)
        })
      }
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const handleRenameItem = (
    fromRenameButton: boolean,
    index?: number,
    value?: string,
  ) => {
    if (online.networkOn) {
      if (renameItemIndex === -1) {
        if (index !== undefined) {
          setRenameItemIndex(index)
          setNameInput(value ?? searchList[index].title)
          setTimeout(() => {
            document.getElementById(`renameInput${index}`)?.focus()
          }, 100)
        }
      } else {
        saveItemName().then((res) => {
          if (res) {
            if (fromRenameButton) {
              if (props.isMyScenariosPage) {
                //Mixpanel 7
                logActivity(false, 'Renamed a scenario')
              } else {
                //Mixpanel 6
                logActivity(false, 'Renamed a case')
              }
            }
            setRenameItemIndex(-1)
            setNameInput('')
          }
        })
      }
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const handleCopyItem = async (index: number) => {
    let scenarioJson = JSON.stringify({
      scenarioId: searchList[index].uuid,
      caseId: params.caseId!,
      scenarioName: searchList[index].title,
    })

    await navigator.clipboard.writeText(scenarioJson)
    setCopyItemMessage({ index: index, message: 'Copied!' })

    setTimeout(() => {
      setCopyItemMessage(undefined)
      //Mixpanel 176
      logActivity(false, 'Copied a scenario to clipboard')
    }, 1000)
  }

  const handlePasteItem = async () => {
    let scenarioFromClipboard = await navigator.clipboard.readText()
    if (!scenarioFromClipboard || !isJSON(scenarioFromClipboard)) {
      setNothingToPaste(true)
      setTimeout(() => {
        setNothingToPaste(false)
      }, 1000)
      return
    }

    const scenarioData = JSON.parse(scenarioFromClipboard)
    if (
      !scenarioData.scenarioId ||
      !scenarioData.caseId ||
      !scenarioData.scenarioName
    ) {
      setNothingToPaste(true)
      setTimeout(() => {
        setNothingToPaste(false)
      }, 1000)
      return
    }

    if (!online.networkOn) {
      setOnline({ ...online, shaking: true })
    }

    setRequestLoading('paste')
    setSearchKey('')
    const newName = findDuplicationName(scenarioData.scenarioName)
    const res = await duplicateScenario(
      scenarioData.caseId,
      scenarioData.scenarioId,
      newName,
      params.caseId!,
    )

    if (!('errorCode' in res)) {
      const newItem: ListItem = props.createItem(res, user.role)
      let tempList = [...list, newItem]
      updateSortSearch(tempList, newItem.uuid, true)
      //Mixpanel 177
      logActivity(false, 'Pasted a scenario')
    } else {
      setNothingToPaste(true)
      setTimeout(() => {
        setNothingToPaste(false)
      }, 1000)
    }

    setRequestLoading(undefined)
  }

  const handleDeleteItem = async () => {
    if (online.networkOn) {
      setRequestLoading('windowDeleteButton')
      const res = props.isMyScenariosPage
        ? await deleteScenario(
            params.caseId!,
            searchList[deleteItemIndex].uuid,
            props.showDeleted,
          )
        : await deleteCase(searchList[deleteItemIndex].uuid, props.showDeleted)

      setHandlingErrors(
        findHandlingErrorState(
          res,
          handlingErrors,
          props.isMyScenariosPage ? 'deleteScenario' : 'deleteCase',
          true,
        ),
      )
      if (!('errorCode' in res)) {
        let tempList = [...list]
        if (tempList.length === 1 && props.setUsersListWhenNoScenarios) {
          props.setUsersListWhenNoScenarios(tempList[0].users)
        }
        tempList.splice(
          list.findIndex(
            (item) => item.uuid === searchList[deleteItemIndex].uuid,
          ),
          1,
        )
        updateSortSearch(tempList)
        setDeleteItemIndex(-1)
        setDeleteWindowCoordinates(undefined)
        if (tempList.length === 0 && props.isMyScenariosPage) {
          setRerender((prevState) => prevState + 1)
        }

        if (props.isMyScenariosPage) {
          //Mixpanel 5
          logActivity(false, 'Deleted a scenario')
        } else {
          //Mixpanel 4
          logActivity(false, 'Deleted a case')
        }
      }
      setRequestLoading(undefined)
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const handleDuplicate = async (index: number) => {
    if (online.networkOn) {
      setRequestLoading('duplicate')
      const newName = findDuplicationName(searchList[index].title)
      const res = props.isMyScenariosPage
        ? await duplicateScenario(
            params.caseId!,
            searchList[index].uuid,
            newName,
            params.caseId!,
          )
        : await duplicateCase(searchList[index].uuid, newName)
      setHandlingErrors(
        findHandlingErrorState(
          res,
          handlingErrors,
          props.isMyScenariosPage ? 'duplicateScenario' : 'duplicateCase',
        ),
      )
      if (!('errorCode' in res)) {
        const newItem: ListItem = props.createItem(res, user.role)

        let tempList = [...list, newItem]
        const newIndex = updateSortSearch(tempList, newItem.uuid)

        handleRenameItem(false, newIndex, newName)
        if (props.isMyScenariosPage) {
          //Mixpanel 9
          logActivity(false, 'Duplicated a scenario')
        } else {
          //Mixpanel 8
          logActivity(false, 'Duplicated a case')
        }
      }
      setRequestLoading(undefined)
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const handleRestoreItem = async (index: number) => {
    if (!online.networkOn) {
      setOnline({ ...online, shaking: true })
    }

    if (requestLoading !== undefined) {
      return
    }

    setRequestLoading(`restore-${index}`)

    await new Promise((resolve) => {
      setTimeout(() => resolve(true), 500)
    })

    const res = props.isMyScenariosPage
      ? await restoreScenario(params.caseId!, searchList[index].uuid)
      : await restoreCase(searchList[index].uuid)

    setHandlingErrors(
      findHandlingErrorState(
        res,
        handlingErrors,
        props.isMyScenariosPage ? 'restoreScenario' : 'restoreCase',
      ),
    )
    if (!('errorCode' in res)) {
      let tempList = [...list]
      tempList.splice(
        list.findIndex((item) => item.uuid === searchList[index].uuid),
        1,
      )
      updateSortSearch(tempList)

      if (props.isMyScenariosPage) {
        //Mixpanel 131
        logActivity(false, 'Restored a scenario')
      } else {
        //Mixpanel 132
        logActivity(false, 'Restored a case')
      }
    }

    setRequestLoading(undefined)
  }

  const handleCreateTutorials = async () => {
    if (!online.networkOn) {
      setOnline({ ...online, shaking: true })
      return
    }

    setRequestLoading(
      props.isMyScenariosPage ? 'scenario_tutorial' : 'case_tutorial',
    )
    setSearchKey('')

    const res = props.isMyScenariosPage
      ? await createTutorial(
          list.length === 0 ? props.tutorialId! : list[0].tutorialId!,
        )
      : await createAllTutorials()
    setHandlingErrors(
      findHandlingErrorState(res, handlingErrors, 'createTutorials'),
    )

    if (!('errorCode' in res)) {
      if (props.isMyScenariosPage) {
        navigate(`/mycases/${res.data.newGroupId}`)
      } else {
        setRerender((prevState) => prevState + 1)
      }
    }

    setRequestLoading(undefined)
  }

  const findDuplicationName = (name: string) => {
    let nameExists = true
    let newName = name

    while (nameExists) {
      let endingRegexCopy = /\w+ - Copy$/
      let endingRegexCopyWithNumber = /\w+ - Copy\(\d+\)$/
      let alreadyAcopy = endingRegexCopy.test(newName)
      let alreadyAcopyWithNumber = endingRegexCopyWithNumber.test(newName)

      // eslint-disable-next-line no-loop-func
      if (list.find((item) => item.title === newName)) {
        if (alreadyAcopy) {
          newName += '(2)'
        } else if (alreadyAcopyWithNumber) {
          let num = Number.parseInt(newName.split('(').at(-1)!.split(')')[0])
          let newNameArray = newName.split(' - ')
          newNameArray[newNameArray.length - 1] = newNameArray
            .at(-1)!
            .replace(`(${num})`, `(${num + 1})`)
          newName = newNameArray.join(' - ')
        } else {
          newName += ' - Copy'
        }
      } else {
        nameExists = false
      }
    }

    return newName
  }

  const saveItemName = async () => {
    const name = nameInput.trim()
    if (checkName(name)) {
      setRequestLoading('renameItemSaveButton')

      const res = props.isMyScenariosPage
        ? await changeScenarioName(
            params.caseId!,
            searchList[renameItemIndex].uuid,
            name,
          )
        : await changeCaseName(searchList[renameItemIndex].uuid, name)
      setHandlingErrors(
        findHandlingErrorState(
          res,
          handlingErrors,
          props.isMyScenariosPage ? 'changeScenarioName' : 'changeCaseName',
        ),
      )
      if (!('errorCode' in res)) {
        let tempList = [...list]
        const indexToList = list.findIndex(
          (item) => item.uuid === searchList[renameItemIndex].uuid,
        )
        tempList[indexToList].title = name
        updateSortSearch(tempList)
        setRequestLoading(undefined)

        return 'name changed'
      } else {
        setRequestLoading(undefined)
        return undefined
      }
    }
  }

  const checkName = (name: string) => {
    const realIndex =
      renameItemIndex > -1
        ? list.findIndex(
            (item) => item.uuid === searchList[renameItemIndex].uuid,
          )
        : -1

    if (name.length < 1) {
      // id = 'alertForRenameCaseCharacter'
      alert(
        `${
          props.itemName === 'Scenario'
            ? // text-attribute='alert-7'
              getText('alert-7', user.settings)
            : // text-attribute='alert-8'
              getText('alert-8', user.settings)
        } `,
      )
      return false
    } else if (
      list.filter(
        (item, index) =>
          item.title.toLowerCase() === name.toLowerCase() &&
          index !== realIndex,
      ).length > 0
    ) {
      //id = 'alertForRenameCaseExists'
      alert(
        `${
          props.itemName === 'Scenario'
            ? // text-attribute='alert-9'
              getText('alert-9', user.settings)
            : // text-attribute='alert-10'
              getText('alert-10', user.settings)
        } `,
      )
      return false
    }

    return true
  }

  const updateSortSearch = (
    tempList: ListItem[],
    uuid?: string,
    noSearchKey?: boolean,
  ) => {
    setList([...tempList])

    tempList = handleSearchList(noSearchKey ? '' : searchKey, tempList)
    tempList = sortBy(currentSortOption, false, tempList)

    setSearchList([...tempList])

    if (uuid) {
      return tempList.findIndex((item) => item.uuid === uuid)
    }
  }

  function openAccessComponentInCases(
    caseId: string,
    caseName: string,
    users: any,
  ) {
    const accessUsers: UserForAccess[] = users.map((userData: any) => {
      return {
        first_name: userData.first_name,
        last_name: userData.last_name,
        user_role:
          userData.user_role in UserRole
            ? UserRole[userData.user_role as UserRoleKeys]
            : userRoleFromString(userData.user_role),
        access_role: AccessRole[userData.access_role as AccessRoleKeys],
        username: userData.username,
        self: user.email === userData.username,
      }
    })

    setCaseIdForUsers({
      caseId: caseId,
      caseName: caseName,
      users: [...accessUsers],
    })
  }

  const onShareCaseButtonMyScenariosClick = () => {
    openAccessComponentInCases(
      params.caseId!,
      props.caseName!,
      list.length > 0 ? list[0].users : props.usersListWhenNoScenarios!,
    )
    setOpenAccessPopUp(true)
    //Mixpanel 18
    logActivity(false, 'Opened share case menu from My Scenarios')
  }

  const isActionDisabled = (
    item: ListItem | undefined,
    action:
      | 'add'
      | 'rename'
      | 'copy'
      | 'paste'
      | 'duplicate'
      | 'delete'
      | 'emptyBin',
  ) => {
    if (
      (action === 'rename' ||
        action === 'delete' ||
        action === 'copy' ||
        action === 'paste') &&
      item &&
      item.accessedByUsers.length > 0
    ) {
      return true
    }

    if (
      user.role === UserRole.EPEROTO_ADMIN ||
      user.role === UserRole.GLOBAL_ADMIN
    ) {
      return false
    }

    if (
      action === 'add' &&
      user.role !== UserRole.APP_VIEWER &&
      !props.isMyScenariosPage
    ) {
      return false
    }

    let usersToCheck: UserForAccess[] = []
    if (item) {
      usersToCheck = item.users ? [...item.users] : []
    } else if (list.length > 0) {
      usersToCheck = list[0].users ? [...list[0].users] : []
    } else {
      usersToCheck = props.usersListWhenNoScenarios
        ? [...props.usersListWhenNoScenarios]
        : []
    }

    let ownRole = AccessRole.NONE
    const filtered = usersToCheck.filter((userObj) => userObj.self)

    if (filtered && filtered.length === 0) {
      if (user.role === UserRole.APP_VIEWER) {
        ownRole = AccessRole.VIEWER
      } else {
        ownRole = AccessRole.ADMIN
      }
    } else if (filtered) {
      ownRole =
        AccessRole[filtered[0].access_role!.toString() as AccessRoleKeys]
    }

    if (ownRole === AccessRole.OWNER || ownRole === AccessRole.ADMIN) {
      return false
    }

    if (
      ownRole === AccessRole.EDITOR &&
      (action === 'add' || action === 'paste')
    ) {
      return false
    }

    if (item) {
      if (ownRole === AccessRole.EDITOR) {
        if (props.isMyScenariosPage) {
          if (action === 'duplicate' || action === 'copy') {
            return false
          }
          if (action === 'rename' || action === 'delete') {
            if (item.createdByUser && item.createdByUser.self) {
              return false
            }
          }
        } else {
          if (action === 'duplicate') {
            return false
          }
        }
      }
    }

    return true
  }

  const onItemClick = (item: ListItem) => {
    openAccessComponentInCases(item.uuid, item.title, item.users)
    setOpenAccessPopUp(true)
    //Mixpanel 17
    logActivity(false, 'Opened share case menu from My Cases')
  }

  const onDeleteItemClick = (item: ListItem, index: number) => {
    if (isActionDisabled(item, 'delete')) {
      handleDeleteWindowPosition(index, true)
      return
    }

    if (renameItemIndex === -1) {
      handleDeleteWindowPosition(index)
      setDeleteItemIndex(index)
    }
  }

  const navigateToDeleted = () => {
    setRenameItemIndex(-1)
    setDeleteItemIndex(-1)
    setAddNewItem(false)
    setSearchKey('')
    setNameInput('')
    if (props.isMyScenariosPage) {
      navigate('deleted')
    } else {
      navigate('/deleted')
    }
  }

  return (
    <>
      {openAccessPopUp && caseIdForUsers ? (
        <FullScreenPopMessage
          id="userAccessPopUp"
          titleTextAttribute={undefined}
          backGroundColor="#6f6f6f" //tuesdayGray
          blur
          zIndex={1900}
          cancelFunction={() => {
            setCaseIdForUsers(undefined)
            setOpenAccessPopUp(false)
          }}
          accessComponent
          caseId={caseIdForUsers.caseId}
          caseName={caseIdForUsers.caseName}
          users={caseIdForUsers.users}
          setUpdateUsersAccess={(param: number, users: any) => {
            openAccessComponentInCases(
              caseIdForUsers.caseId,
              caseIdForUsers.caseName,
              users,
            )
            setUpdateUsersAccess(param)
          }}
          updateUsersAccess={updateUsersAccess}
        />
      ) : null}
      {openEmptyBinPopUp && (
        <EmptyBinPopUp
          setOpenPopUp={setOpenEmptyBinPopUp}
          isMyScenariosPage={props.isMyScenariosPage}
        />
      )}
      {requestLoading ? <div className="transparent-Background"></div> : null}
      <div className="loginBackground">
        {loading ||
        requestLoading === 'duplicate' ||
        requestLoading === 'paste' ? (
          <LoadingCasesPlaceHolder />
        ) : (
          <div className="myCasesBox">
            <div className="myCasesBox-content-container">
              <TopPart
                itemName={props.itemName}
                setSearchList={setSearchList}
                setSearchKey={setSearchKey}
                sortBy={sortBy}
                usersList={list.length > 0 ? list[0].users : undefined}
                onShareCaseButtonMyScenariosClick={
                  onShareCaseButtonMyScenariosClick
                }
                tutorialId={
                  props.isMyScenariosPage && list.length === 0
                    ? props.tutorialId
                    : props.isMyScenariosPage
                    ? list[0].tutorialId
                    : undefined
                }
                handleSearchList={handleSearchList}
                currentSortOption={currentSortOption}
                showUsersShare={showUsersShare}
                isMyScenariosPage={props.isMyScenariosPage}
                showDeleted={props.showDeleted}
                setOpenEmptyBinPopUp={setOpenEmptyBinPopUp}
                isEmptyBinDisabled={isActionDisabled(undefined, 'emptyBin')}
              />
              {list.length > 0 ? (
                <>
                  <MyCasesTitles
                    widthOfCaseContainer={widthOfCaseContainer}
                    isMyScenariosPage={props.isMyScenariosPage}
                    setSearchList={setSearchList}
                    sortBy={sortBy}
                    sortOption={currentSortOption}
                    sortDirection={currentSortDirection}
                    showUsersShare={showUsersShare}
                    showDeleted={props.showDeleted}
                  />
                </>
              ) : (
                <EmptyMessage
                  usersListWhenNoScenarios={props.usersListWhenNoScenarios}
                  type={props.itemName}
                  showDeleted={props.showDeleted}
                />
              )}

              <div className="myCasesListContainer">
                {searchList.map((item, index) => (
                  <ItemPart
                    key={index}
                    index={index}
                    item={item}
                    isRenaming={renameItemIndex === index}
                    addNewCaseContainerRef={addNewCaseContainerRef}
                    caseTitleContainerRef={caseTitleContainerRef}
                    nameInput={nameInput}
                    setNameInput={setNameInput}
                    handleRenameItem={handleRenameItem}
                    handleCopyItem={handleCopyItem}
                    handleRestoreItem={handleRestoreItem}
                    getNavigateLink={props.getNavigateLink}
                    requestLoading={requestLoading}
                    setRequestLoading={setRequestLoading}
                    showUsersShare={showUsersShare}
                    isMyScenariosPage={props.isMyScenariosPage}
                    onItemClick={onItemClick}
                    statusDiv={props.statusDiv}
                    isDeleting={deleteItemIndex === index}
                    onDeleteItemClick={onDeleteItemClick}
                    handleDuplicate={handleDuplicate}
                    isActionDisabled={isActionDisabled}
                    handleDeleteItem={handleDeleteItem}
                    deleteWindowCoordinates={deleteWindowCoordinates}
                    setDeleteItemIndex={setDeleteItemIndex}
                    showDeleted={props.showDeleted}
                    disabledActionItemIndex={disabledActionItemIndex}
                    setDisabledActionItemIndex={setDisabledActionItemIndex}
                    handleDeleteWindowPosition={handleDeleteWindowPosition}
                    copyItemMessage={copyItemMessage}
                  />
                ))}
              </div>
              {!props.showDeleted && (
                <BottomPart
                  isAddDisabled={isActionDisabled(undefined, 'add')}
                  addNewItem={addNewItem}
                  setAddNewItem={setAddNewItem}
                  handleAddNewItem={handleAddNewItem}
                  nameInput={nameInput}
                  setNameInput={setNameInput}
                  itemName={props.itemName}
                  addNewCaseContainerRef={addNewCaseContainerRef}
                  requestLoading={requestLoading}
                  isMyScenariosPage={props.isMyScenariosPage}
                  navigateToDeleted={navigateToDeleted}
                  handleCreateTutorials={handleCreateTutorials}
                  widthOfCaseContainer={widthOfCaseContainer}
                  tutorialId={
                    props.isMyScenariosPage && list.length === 0
                      ? props.tutorialId
                      : props.isMyScenariosPage
                      ? list[0].tutorialId
                      : undefined
                  }
                  handlePasteItem={handlePasteItem}
                  nothingToPaste={nothingToPaste}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ListPageTemplate
