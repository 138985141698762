import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { AccessRole, AccessRoleKeys, SaveMenuEnum } from '../models/enums'
import {
  createIdentityFromDb,
  deepCloneObject,
  findHandlingErrorState,
  logActivity,
} from '../services/commonFunctions'
import {
  getUsersGroupRoles,
  saveReportDetail,
  saveScenarioSnapshot,
} from '../services/requests'

import Header from './header/Header'

import SideMenu from './sideMenu/SideMenu'
import { scenarioSnapshotState } from '../states/ScenarioSnapshotState'
import { savedState } from '../states/SavedState'
import { Params } from '../models/generalTypes'
import { scenarioIdentityState } from '../states/ScenarioIdentityState'
import { treeTablesState } from '../states/TreeTablesState'
import { pageDepthState } from '../states/PageDepthState'
import { reportState } from '../states/ReportState'
import { savedReportState } from '../states/SavedReportState'
import { handlingErrorsState } from '../states/HandlingErrorsState'
import { reportMenuState } from '../states/ReportMenuState'
import SavePopUp from './savePopUp/SavePopUp'
import { onlineState } from '../states/OnlineState'
import { getText } from '../services/textFunctions'
import { userState } from '../states/UserState'
import PdfReportMenu from '../pages/toolPage/tabs/7_results/resultsComponents/5_pdfReport/pdfReportComponents/pdfReportMenuComponents/PdfReportMenu'
import { recoveryModeState } from '../states/RecoveryModeState'
import { freemiumState } from '../states/FreemiumState'

type Props = {
  setFirstTimeLogin: (value: boolean) => void
}

export default function PageSaveWrapper(props: Props) {
  // eslint-disable-next-line
  const params = useParams<Params>()
  const user = useRecoilValue(userState)

  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const setScenarioIdentity = useSetRecoilState(scenarioIdentityState)
  const [pageDepth, setPageDepth] = useRecoilState(pageDepthState)
  const report = useRecoilValue(reportState)
  const setSavedReport = useSetRecoilState(savedReportState)
  const treeTables = useRecoilValue(treeTablesState)
  const [savedSnapshot, setSavedSnapshot] = useRecoilState(savedState)
  const [savedMessage, setSavedMessage] = useState<string | undefined>(
    undefined,
  ) // a state that defines the message after saving the case -- false or str
  const [saving, setSaving] = useState('') // a boolean that defines if the saving message is visible or not -- bool
  const [isSaveAsNew, setIsSaveAsNew] = useState(false) // a boolean that defines if the saveAs Menu is open or closed -- bool
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [saveMenuOn, setSaveMenuOn] = useState<undefined | string>(undefined) // a boolean that defines if the save Menu pop up is on -- bool
  const [reportMenu, setReportMenu] = useRecoilState(reportMenuState)
  const [online, setOnline] = useRecoilState(onlineState)
  const recoveryMode = useRecoilValue(recoveryModeState)
  const [ownRole, setOwnRole] = useState<AccessRole>(AccessRole.NONE)
  const freemium = useRecoilValue(freemiumState)

  useEffect(() => {
    if (treeTables.loadingMessage !== undefined) {
      setSaving(treeTables.loadingMessage)
    } else {
      setTimeout(() => setSaving(''), 800)
    }
  }, [treeTables])

  useEffect(() => {
    if (
      saveMenuOn === undefined &&
      reportMenu.downloadReportFixedHeaderOn === false
    ) {
      setReportMenu({ ...reportMenu, downloadReportFixedHeaderOn: true })
    }
    // eslint-disable-next-line
  }, [saveMenuOn])

  useEffect(() => {
    if (freemium.isFreemium) {
      setOwnRole(AccessRole.OWNER)
    } else {
      getUsersGroupRoles(params.caseId!).then((res) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'getUsersGroupRoles'),
        )

        if (!('errorCode' in res)) {
          const filtered = res.data.filter(
            (obj: any) => user.email === obj.username,
          )
          if (filtered.length > 0) {
            setOwnRole(AccessRole[filtered[0].access_role as AccessRoleKeys])
          } else {
            setOwnRole(AccessRole.ADMIN)
          }
        }
      })
    }

    // eslint-disable-next-line
  }, [params.caseId, params.scenarioId])

  async function handleSave(redirectBoolean: boolean) {
    if (online.networkOn) {
      //id='savingMessage'
      //data-textattribute='message-54'
      setSaving(getText('message-54', user.settings))
      setSaveMenuOn(undefined)
      let tempScenarioSnapshotState = { ...scenarioSnapshot }
      let tempSavedSnapshotState = { ...savedSnapshot }
      tempScenarioSnapshotState.savedSnapshot = deepCloneObject(
        tempScenarioSnapshotState.currentSnapshot,
      )

      tempSavedSnapshotState.saved = true

      const res = await saveScenarioSnapshot(
        params.caseId!,
        params.scenarioId!,
        {
          data: tempScenarioSnapshotState.currentSnapshot,
          keep_results: savedSnapshot.keepResults,
          log_entry: 'Save scenario',
        },
      )

      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'saveScenarioSnapshot'),
      )
      if (!('errorCode' in res)) {
        let tempScenarioIdentity = createIdentityFromDb(res.data)

        if (!redirectBoolean) {
          setScenarioIdentity(tempScenarioIdentity)

          setTimeout(() => {
            setScenarioSnapshot(tempScenarioSnapshotState)
            setSavedSnapshot(tempSavedSnapshotState)
            setSaving('')
          }, 2000)

          setTimeout(() => setSavedMessage('saved'), 300)
          setTimeout(() => setSavedMessage(undefined), 5000)
        }

        return 'saved'
      }
      setSaving('')
    } else {
      setOnline({ ...online, shaking: true })
    }
    return 'not saved'
  }

  async function handleSaveReport() {
    if (online.networkOn) {
      //id='savingMessage'
      //data-textattribute='message-55'
      setSaving(getText('message-55', user.settings))
      setSaveMenuOn(undefined)

      let tempReport = deepCloneObject(report)

      const res = await saveReportDetail(params.caseId!, params.scenarioId!, {
        reportData: tempReport.reportData,
      })

      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'saveReportDetail'),
      )

      setTimeout(() => {
        setSavedReport({ saved: true, savedReportState: tempReport })
        setSaving('')
      }, 2000)

      setTimeout(() => setSavedMessage('report saved'), 300)
      setTimeout(() => setSavedMessage(undefined), 5000)

      setReportMenu({ ...reportMenu, reportWithDifferentResults: false })
      //Mixpanel 158
      logActivity(false, 'Saved pdf report')
      return 'report saved'
    } else {
      setSaving('')
      setOnline({ ...online, shaking: true })
    }
    return 'report not saved'
  }

  function closePdfMenu() {
    setReportMenu({
      ...reportMenu,
      previewPdfOn: false,
      orderMenuOn: false,
      downloadReportFixedHeaderOn: false,
      reportSaveMenuOn: undefined,
      reportWithDifferentResults: false,
    })
    setPageDepth(3)
  }

  function saveAndClosePdfMenu() {
    setReportMenu({
      ...reportMenu,
      reportSaveMenuOn: undefined,
    })
    handleSaveReport().then((res) => {
      if (res === 'report saved') {
        closePdfMenu()
      }
    })
  }

  function handleReportSaveMenuOn(param: string | undefined) {
    setReportMenu({
      ...reportMenu,
      reportSaveMenuOn: param,
    })
  }
  return (
    <>
      {reportMenu.downloadReportFixedHeaderOn &&
      !reportMenu.downloadPdf &&
      pageDepth === 3.1 &&
      saving === '' ? (
        <PdfReportMenu handleSaveReport={handleSaveReport} />
      ) : null}

      {reportMenu.reportSaveMenuOn === SaveMenuEnum.report ? (
        <div className="fixedReportMenu">
          <div className="savePopUpContainer">
            <SavePopUp
              id={`savePopUp${reportMenu.reportSaveMenuOn} - Header`}
              style={{ right: '0px', top: '50px' }}
              typeOfMenu={SaveMenuEnum.report}
              setSaveMenuOn={handleReportSaveMenuOn}
              continueWithoutSaving={closePdfMenu}
              saveAndContinue={saveAndClosePdfMenu}
            />
          </div>
        </div>
      ) : null}
      <SideMenu
        saveMenuOn={saveMenuOn}
        setSaveMenuOn={setSaveMenuOn}
        handleSave={handleSave}
        handleSaveReport={handleSaveReport}
        saving={saving}
        setSavedMessage={setSavedMessage}
        setFirstTimeLogin={props.setFirstTimeLogin}
      />
      <Header
        ownRole={
          recoveryMode.recoveryPreview !== 'none' ? AccessRole.VIEWER : ownRole
        }
        pageDepth={pageDepth}
        saveMenuOn={saveMenuOn}
        setSaveMenuOn={setSaveMenuOn}
        handleSave={handleSave}
        handleSaveReport={handleSaveReport}
        saving={saving}
        savedMessage={savedMessage}
        isSaveAsNew={isSaveAsNew}
        setIsSaveAsNew={setIsSaveAsNew}
        setSavedMessage={setSavedMessage}
      />
    </>
  )
}
