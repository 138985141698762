import React, { useEffect, useState } from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { userState } from './states/UserState'

import Header from './pageWrappers/header/Header'

import MyCases from './pages/myCases_myScenarios/MyCases'
import MyScenarios from './pages/myCases_myScenarios/MyScenarios'
import ToolPage from './pages/toolPage/ToolPage'
import ResetPasswordPage from './pages/userLoginWorkflow/reset_password/ResetPasswordPage'

//import dangerImg from './resources/images/122-danger.svg'

import 'sanitize.css'
import 'sanitize.css/forms.css'
import 'sanitize.css/typography.css'
import './styles/App.css'
import SideMenu from './pageWrappers/sideMenu/SideMenu'
import PageSaveWrapper from './pageWrappers/PageSaveWrapper'
import Academy from './pages/academy/Academy'
import loadingGif from './resources/gifs/loading-gif.gif'
import { pageDepthState } from './states/PageDepthState'
import { scenarioSnapshotState } from './states/ScenarioSnapshotState'
import { scenarioIdentityState } from './states/ScenarioIdentityState'
import { reportMenuState } from './states/ReportMenuState'
import { savedState } from './states/SavedState'
import { activeTabsState } from './states/ActiveTabsState'
import { SnapshotStatus, ToolTabs, UndoRedoType } from './models/enums'
import { ScenarioSnapshot } from './models/scenarioSnapshot'
import { doValidationState } from './states/DoValidationState'
import Settings from './pages/settings/Settings'
import { viewerState } from './states/ViewerState'
import { recoveryModeState } from './states/RecoveryModeState'
import AppHOC from './AppHOC'
import useWindowSize from './customHooks/useWindowSize'
import { logActivity } from './services/commonFunctions'
import { freemiumState } from './states/FreemiumState'

function App() {
  // eslint-disable-next-line
  const user = useRecoilValue(userState)
  // eslint-disable-next-line
  const [headerTutorialTitle, setHeaderTutorialTitle] = useState('')
  const pageDepth = useRecoilValue(pageDepthState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const setRecoveryMode = useSetRecoilState(recoveryModeState)
  const setSnapshot = useSetRecoilState(scenarioSnapshotState)
  const [scenarioIdentity, setScenarioIdentity] = useRecoilState(
    scenarioIdentityState,
  )
  const setReportMenu = useSetRecoilState(reportMenuState)
  const [savedSnapshot, setSavedSnapshot] = useRecoilState(savedState)
  const setActiveTabs = useSetRecoilState(activeTabsState)
  const setDoValidation = useSetRecoilState(doValidationState)
  const [firstTimeLogin, setFirstTimeLogin] = useState(false)
  const [zoomMessage, setZoomMessage] = useState({
    showPopUp: false,
    closedByUser: false,
  })
  const { width, height } = useWindowSize()
  const freemium = useRecoilValue(freemiumState)

  useEffect(() => {
    if (window.devicePixelRatio > 2.2) {
      setZoomMessage({ ...zoomMessage, showPopUp: true })
      //Mixpanel 182 (All)
      logActivity(freemium.isFreemium, 'Showed zoom PopUp')
    } else {
      setZoomMessage({ ...zoomMessage, showPopUp: false })
    }

    // eslint-disable-next-line
  }, [width, height])

  useEffect(() => {
    setZoomMessage({ ...zoomMessage, closedByUser: false })

    if (pageDepth < 3) {
      setActiveTabs({ tab: ToolTabs.proceedings, claim: 0 })

      setSnapshot({
        currentSnapshot: ScenarioSnapshot.EmptySnapshot(
          user.settings.formats.partyFormat,
          user.settings.formats.opposingPartyFormat,
          user.settings.formats.currency,
        ),
        undoRedoIndex: 0,
        undoRedo: [
          {
            id: 'currencyDropdown',
            type: UndoRedoType.input,
            snapshot: ScenarioSnapshot.EmptySnapshot(
              user.settings.formats.partyFormat,
              user.settings.formats.opposingPartyFormat,
              user.settings.formats.currency,
            ),
            time: new Date(),
          },
        ],
        savedSnapshot: ScenarioSnapshot.EmptySnapshot(
          user.settings.formats.partyFormat,
          user.settings.formats.opposingPartyFormat,
          user.settings.formats.currency,
        ),
      })

      setScenarioIdentity({
        caseId: '',
        scenarioId: '',
        snapshotId: '',
        caseName: '',
        scenarioName: '',
        snapshotStatus: SnapshotStatus.None,
        snapshotProgress: 0,
        results: undefined,
        hasReport: false,
      })

      setReportMenu({
        downloadReportFixedHeaderOn: false,
        orderMenuOn: false,
        downloadPdf: false,
        reportSaveMenuOn: undefined,
        reportWithDifferentResults: false,
        previewPdfOn: false,
      })

      setSavedSnapshot({ ...savedSnapshot, saved: false })

      setDoValidation(false)

      setIsViewer({ ...isViewer, isViewer: false })
      setRecoveryMode({
        recoveryPreview: 'none',
        shaking: false,
        showLowScreenMessage: false,
        keepResults: false,
        old: undefined,
        new: undefined,
      })

      if (pageDepth > 0) {
        document
          .getElementsByTagName('body')[0]
          .setAttribute('style', 'overflow-y: visible')
      } else {
        document
          .getElementsByTagName('body')[0]
          .setAttribute('style', 'overflow-y: scroll')
      }
    } else if (pageDepth === 3.1) {
      document
        .getElementsByTagName('body')[0]
        .setAttribute('style', 'overflow-y: hidden')
    } else {
      document
        .getElementsByTagName('body')[0]
        .setAttribute('style', 'overflow-y: scroll')
    }
    // eslint-disable-next-line
  }, [pageDepth])

  useEffect(() => {
    if (scenarioIdentity.caseId !== '' && pageDepth < 3) {
      setScenarioIdentity({
        caseId: '',
        scenarioId: '',
        snapshotId: '',
        caseName: '',
        scenarioName: '',
        snapshotStatus: SnapshotStatus.None,
        snapshotProgress: 0,
        results: undefined,
        hasReport: false,
      })
    }

    // eslint-disable-next-line
  }, [scenarioIdentity])

  return (
    <AppHOC
      setFirstTimeLogin={setFirstTimeLogin}
      zoomMessage={zoomMessage}
      setZoomMessage={setZoomMessage}
    >
      <Router>
        <Routes>
          <Route path="reset_password/:token" element={<ResetPasswordPage />} />
          <Route
            path="mycases/:caseId/:scenarioId/tool"
            element={
              <>
                <ToolPage />
                <PageSaveWrapper setFirstTimeLogin={setFirstTimeLogin} />
              </>
            }
          />
          <Route
            path="mycases/:caseId/deleted"
            element={
              <>
                <MyScenarios showDeleted />
                <SideMenu setFirstTimeLogin={setFirstTimeLogin} />
                <Header pageDepth={2.1} saving="" />
              </>
            }
          />
          <Route
            path="mycases/:caseId"
            element={
              <>
                <MyScenarios />
                <SideMenu setFirstTimeLogin={setFirstTimeLogin} />
                <Header pageDepth={2} saving="" />
              </>
            }
          />
          <Route
            path="academy"
            element={
              <>
                <Academy
                  loadingGif={loadingGif}
                  setHeaderTutorialTitle={setHeaderTutorialTitle}
                />
                <SideMenu setFirstTimeLogin={setFirstTimeLogin} />
                <Header
                  pageDepth={pageDepth}
                  saving=""
                  headerTutorialTitle={headerTutorialTitle}
                />
              </>
            }
          />
          <Route
            path="settings"
            element={
              <>
                <Settings />
                <SideMenu setFirstTimeLogin={setFirstTimeLogin} />
                <Header pageDepth={pageDepth} saving="" />
              </>
            }
          />
          <Route
            path="deleted"
            element={
              <>
                <MyCases showDeleted />
                <SideMenu setFirstTimeLogin={setFirstTimeLogin} />
                <Header pageDepth={1.1} saving="" />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <MyCases />
                <SideMenu
                  firstTimeLogin={firstTimeLogin}
                  setFirstTimeLogin={setFirstTimeLogin}
                />
                <Header pageDepth={1} saving="" />
              </>
            }
          />
        </Routes>
      </Router>
    </AppHOC>
  )
}

export default App
