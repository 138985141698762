import { useRecoilValue } from 'recoil'
import { getText } from '../../../../../../services/textFunctions'
import ExecutiveSummaryParagraph from './ExecutiveSummaryParagraph'
import { userState } from '../../../../../../states/UserState'
import {
  ExecutiveSummaryVisibleElementsEnum,
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import { ResultsObject } from '../../../../../../models/generalTypes'
import {
  getLegalCosts,
  legalFeesZero,
} from '../../../../../../services/legalCostsFunctions'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import {
  ExecutiveSummaryVisibleElements,
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../services/reportFunctions'
import { stringAndRoundTo2Decimals } from '../../../../../../Modules/DisputeModules/AppFunctions'
import { removeUnderscore } from '../../../../../../services/commonFunctions'
import {
  LegalCostsParty,
  LegalCostsProceedings,
  TypeOfInstance,
} from '../../../../../../models/enums'

type Props = {
  resultsFromBackend: ResultsObject
  visibleElements?: ExecutiveSummaryVisibleElements
  reportWhitelabel?: ReportWhitelabel
  showIncludeOption: ExecutiveSummaryVisibleElementsEnum | undefined
  setShowIncludeOption: (
    value: ExecutiveSummaryVisibleElementsEnum | undefined,
  ) => void
  handleIncludeExcludeElement: (
    includeElementName: ExecutiveSummaryVisibleElementsEnum,
  ) => void
  legalCostsDescriptions?: ReportLegalCostsType
}

const ExecutiveSummaryLegalCosts = (props: Props) => {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const legalCostsObj = scenarioSnapshot.currentSnapshot.legalCosts
  const hasSecondTrial = scenarioSnapshot.currentSnapshot.probSecondTrial! > 0

  const firstProceedingsDefaultMessage = hasSecondTrial
    ? getText('title-129', user.settings)
    : getText('title-130', user.settings)

  const secondProceedingsDefaultMessage =
    scenarioSnapshot.currentSnapshot.court === TypeOfInstance.Public_Court
      ? //title-131a
        getText('title-131a', user.settings)
      : //title-131b
        getText('title-131b', user.settings)

  const firstProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.firstOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.firstOwnLegalCostsDescription
      : ''
  const secondProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.secondOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.secondOwnLegalCostsDescription
      : ''

  const fade = props.visibleElements && !props.visibleElements.legalCosts

  const getLegalCostsSingleValue = (value: number | string) => {
    if (typeof value === 'string') {
      return `${value} ${scenarioSnapshot.currentSnapshot.currency}`
    }

    return `${stringAndRoundTo2Decimals(value, user.settings)} ${
      scenarioSnapshot.currentSnapshot.currency
    }`
  }

  const getLegalCostsValues = (
    party: LegalCostsParty,
    proceedings: LegalCostsProceedings,
  ) => {
    return (
      <>
        <p
          className={`legalCostsText bold robotoNumbersBold ${
            proceedings === LegalCostsProceedings.additionalProceedings
              ? 'divider'
              : ''
          } ${fade ? 'fadeText' : ''} ${
            props.visibleElements ? 'forReport' : ''
          }`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'roboto',
            false,
            fade,
          )}
          id={`estimatedLegalCostsValue-${
            proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${party}`}
        >
          {getLegalCostsSingleValue(
            party === LegalCostsParty.clientLegalCosts
              ? proceedings === LegalCostsProceedings.firstProceedings
                ? legalCostsObj.clientLegalCosts.firstProceedings
                    .estimatedCosts!
                : legalCostsObj.clientLegalCosts.additionalProceedings!
                    .estimatedCosts!
              : proceedings === LegalCostsProceedings.firstProceedings
              ? legalCostsObj.opposingPartyLegalCosts.firstProceedings
                  .estimatedCosts!
              : legalCostsObj.opposingPartyLegalCosts.additionalProceedings!
                  .estimatedCosts!,
          ).replace(scenarioSnapshot.currentSnapshot.currency, '')}{' '}
          <span
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.bold,
              'noRoboto',
              false,
              fade,
            )}
          >
            {scenarioSnapshot.currentSnapshot.currency}
          </span>
        </p>
        <p
          className={`legalCostsText value robotoNumbers ${
            fade ? 'fadeText' : ''
          } ${props.visibleElements ? 'forReport' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'roboto',
            false,
            fade,
          )}
          id={`recoverableLegalCostsValue-${
            proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${party}`}
        >
          {getLegalCostsSingleValue(
            getLegalCosts(
              'recoverableCosts',
              party,
              proceedings,
              scenarioSnapshot.currentSnapshot,
              user.settings,
            ),
          ).replace(scenarioSnapshot.currentSnapshot.currency, '')}{' '}
          <span
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
              false,
              fade,
            )}
            className="noBold"
          >
            {scenarioSnapshot.currentSnapshot.currency}
          </span>
        </p>
        <p
          className={`legalCostsText value robotoNumbers ${
            fade ? 'fadeText' : ''
          } ${props.visibleElements ? 'forReport' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'roboto',
            false,
            fade,
          )}
          id={`incurredLegalCostsValue-${
            proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${party}`}
        >
          {getLegalCostsSingleValue(
            getLegalCosts(
              'incurredCosts',
              party,
              proceedings,
              scenarioSnapshot.currentSnapshot,
              user.settings,
            ),
          ).replace(scenarioSnapshot.currentSnapshot.currency, '')}{' '}
          <span
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
              false,
              fade,
            )}
            className="noBold"
          >
            {scenarioSnapshot.currentSnapshot.currency}
          </span>
        </p>
      </>
    )
  }

  const getLegalCostsTexts = (secondTrial?: boolean) => {
    return (
      <>
        {!secondTrial && (
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            className={`legalCostsText party invisible ${
              props.visibleElements ? 'forReport' : ''
            }`}
          >
            {'No Text'}
          </p>
        )}
        <div
          className={`smallColumn maxWidth ${
            !secondTrial && hasSecondTrial ? 'divider' : ''
          }`}
        >
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.medium,
              'noRoboto',
              false,
              fade,
            )}
            className={`legalCostsText semi-bold ${fade ? 'fadeText' : ''} ${
              props.visibleElements ? 'forReport' : ''
            }`}
            id={`costsOfProceedingsTitle-${secondTrial ? 'second' : 'first'}`}
          >
            {secondTrial
              ? secondProceedingsText.length > 0
                ? secondProceedingsText
                : secondProceedingsDefaultMessage
              : firstProceedingsText.length > 0
              ? firstProceedingsText
              : firstProceedingsDefaultMessage}
            :
          </p>
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
              false,
              fade,
            )}
            className={`legalCostsText description ${fade ? 'fadeText' : ''} ${
              props.visibleElements ? 'forReport' : ''
            }`}
            data-textattribute="label-89"
            id={`recoverableCostsTitle-${secondTrial ? 'second' : 'first'}`}
          >
            {getText('label-89', user.settings)}:
          </p>
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
              false,
              fade,
            )}
            className={`legalCostsText description ${fade ? 'fadeText' : ''} ${
              props.visibleElements ? 'forReport' : ''
            }`}
            data-textattribute="label-91"
            id={`incurredCostsTitle-${secondTrial ? 'second' : 'first'}`}
          >
            {getText('label-91', user.settings)}:
          </p>
        </div>
      </>
    )
  }

  const getLegalCostsParagraph = () => {
    return (
      <div
        className={`legalCostsContainer ${
          props.visibleElements ? 'forReport' : ''
        }`}
      >
        <div className="legalCostsMainColumn for-titles">
          {getLegalCostsTexts()}
          {hasSecondTrial && getLegalCostsTexts(hasSecondTrial)}
        </div>
        <div className="legalCostsMainColumn">
          <div
            className={`smallColumn maxWidth ${
              hasSecondTrial ? 'divider' : ''
            }`}
          >
            <p
              className={`legalCostsText party ${fade ? 'fadeText' : ''} ${
                props.visibleElements ? 'forReport' : ''
              }`}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
                false,
                fade,
              )}
              id="partyNameText-client"
            >
              {removeUnderscore(partiesFormat[0])}
            </p>
            <div className="smallColumn" id="legalCostsValue">
              {getLegalCostsValues(
                LegalCostsParty.clientLegalCosts,
                LegalCostsProceedings.firstProceedings,
              )}
              {hasSecondTrial &&
                getLegalCostsValues(
                  LegalCostsParty.clientLegalCosts,
                  LegalCostsProceedings.additionalProceedings,
                )}
            </div>
          </div>
        </div>
        <div className="legalCostsMainColumn">
          <div
            className={`smallColumn maxWidth ${
              hasSecondTrial ? 'divider' : ''
            }`}
          >
            <p
              className={`legalCostsText party ${fade ? 'fadeText' : ''} ${
                props.visibleElements ? 'forReport' : ''
              }`}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
                false,
                fade,
              )}
              id="partyNameText-oppposingParty"
            >
              {removeUnderscore(partiesFormat[1])}
            </p>
            <div className="smallColumn">
              {getLegalCostsValues(
                LegalCostsParty.opposingPartyLegalCosts,
                LegalCostsProceedings.firstProceedings,
              )}
              {hasSecondTrial &&
                getLegalCostsValues(
                  LegalCostsParty.opposingPartyLegalCosts,
                  LegalCostsProceedings.additionalProceedings,
                )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <ExecutiveSummaryParagraph
      //data-textattribute = "title-239"
      sideTitle={getText('title-239', user.settings)}
      id={`${ExecutiveSummaryVisibleElementsEnum.legalCosts}`}
      resultsFromBackend={props.resultsFromBackend}
      data-textaatribute={`${
        legalFeesZero(scenarioSnapshot.currentSnapshot)
          ? 'description-52'
          : hasSecondTrial
          ? 'description-53,description-54,description-56, description-57'
          : 'description-53,description-54'
      }`}
      paragraphText={
        <>
          {legalFeesZero(scenarioSnapshot.currentSnapshot)
            ? getText('description-52', user.settings)
            : getLegalCostsParagraph()}
        </>
      }
      executiveSummaryVisibleElement={
        ExecutiveSummaryVisibleElementsEnum.legalCosts
      }
      visibleElements={props.visibleElements}
      showIncludeOption={
        props.visibleElements ? props.showIncludeOption : undefined
      }
      setShowIncludeOption={
        props.visibleElements ? props.setShowIncludeOption : undefined
      }
      handleIncludeExcludeElement={
        props.visibleElements ? props.handleIncludeExcludeElement : undefined
      }
      reportWhitelabel={props.reportWhitelabel}
    />
  )
}

export default ExecutiveSummaryLegalCosts
