import { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import {
  VictoryChart,
  VictoryAxis,
  VictoryBrushContainer,
  VictoryTooltip,
  VictoryArea,
  VictoryLabel,
  VictoryContainer,
} from 'victory'
import { DomainTuple } from 'victory-core'
import Button from '../../../../../../../../Components/Buttons/Button/Button'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { stringAndRoundTo2Decimals } from '../../../../../../../../Modules/DisputeModules/AppFunctions'
import {
  floorNumberTo,
  logActivity,
  roundNumberTo,
} from '../../../../../../../../services/commonFunctions'
import { userState } from '../../../../../../../../states/UserState'
import SelectionGraphDescription from './SelectionGraphDescription'
import { getText } from '../../../../../../../../services/textFunctions'
import { ActionMessage } from '../../../../../../../../models/enums'
import { fullscreenActionMessageState } from '../../../../../../../../states/FullScreenActionMessageState'
import treeSelectionGraphImg from '../../../../../../../../resources/images/232-SelectGraph.svg'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { freemiumState } from '../../../../../../../../states/FreemiumState'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { findDisputedAmount } from '../../../../../../../../services/disputedAmountFunctions'

type Props = {
  treeSelectionGraph?: [number, number][]
  resultsFromBackend?: ResultsObject
  currentSnapshot: ScenarioSnapshot
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
}
export default function SelectionGraph(props: Props) {
  const fullscreenActionMessage = useRecoilValue(fullscreenActionMessageState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  // *** Declare the height of the table depending on the height of the div at the time of generation of the table
  const widthOfDiv = props.treeSelectionGraph ? 560 : 1000
  const barHeight = (widthOfDiv * 4) / 10

  let sampleData = props.treeSelectionGraph
    ? props.treeSelectionGraph
    : props.resultsFromBackend!.result.graph
  if (sampleData[sampleData.length - 1][1] < 1) {
    sampleData[sampleData.length - 1][1] = 1.00000001
  }

  const [tab, setTab] = useState(1)

  const [selectionArea, setSelectionArea] = useState<DomainTuple>([0.15, 0.35])
  //const [positionOfLabel, setPositionOfLabel] = useState(widthOfDiv / 2)
  const [updateGraph, setUpdateGraph] = useState(true)
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [text, setText] = useState(initializeText({ x: selectionArea }))

  useEffect(() => {
    setText(initializeText({ x: selectionArea }))
    // eslint-disable-next-line
  }, [props.treeSelectionGraph])

  useEffect(() => {
    setUpdateGraph(false)
    if (tab === 2) {
      handleBrushSelection({ x: findPositiveDomain() }, 2)
      setSelectionArea(findPositiveDomain())
    } else if (tab === 3) {
      handleBrushSelection({ x: findNegativeDomain() }, 3)
      setSelectionArea(findNegativeDomain())
    } else {
      handleBrushSelection({ x: selectionArea }, tab)
    }

    setTimeout(() => {
      setUpdateGraph(true)
    }, 100)

    // eslint-disable-next-line
  }, [props.resultsFromBackend])

  useEffect(() => {
    const probability =
      (selectionArea[1] as number) - (selectionArea[0] as number)
    var probArray

    switch (tab) {
      case 0:
        if (Math.abs(1 - probability) > 0.001) {
          setTab(-1)
        }
        break
      case 1:
        if (Math.abs(0.2 - probability) > 0.001) {
          setTab(-1)
        }
        break
      case 2:
        probArray = findPositiveDomain()
        if (Math.abs(probArray[1] - probArray[0] - probability) > 0.01) {
          setTab(-1)
        }
        break
      case 3:
        probArray = findNegativeDomain()
        if (Math.abs(probArray[1] - probArray[0] - probability) > 0.01) {
          setTab(-1)
        }
        break

      default:
        break
    }

    // eslint-disable-next-line
  }, [selectionArea])
  /* const selectionAreaElement = document.getElementById('selectionArea')
  useEffect(() => {
    if (selectionAreaElement) {
      var selectionElementLeft = parseFloat(
        selectionAreaElement.getAttribute('x'),
      )
      var width = selectionAreaElement.getAttribute('width')

      setPositionOfLabel(selectionElementLeft + 120 + width / 2)
    }
  }, [text, selectionAreaElement, selectionArea]) */

  const graphLimit = 601

  if (sampleData.length < graphLimit || props.treeSelectionGraph) {
    sampleData = [[sampleData[0][0], 0], ...sampleData]

    let sampleData2 = []
    for (let i = 1; i < sampleData.length - 1; i++) {
      sampleData2.push([sampleData[i + 1][0], sampleData[i][1]])
    }
    for (let i = 0; i < sampleData2.length; i++) {
      sampleData.splice(i + 2, 0, sampleData2[i])
    }
  }

  function Comparator(a: any, b: any) {
    if (a[1] === b[1]) {
      if (a[0] > b[0]) return -1
      if (a[0] < b[0]) return 1
    }
    if (a[1] < b[1]) return -1
    if (a[1] > b[1]) return 1
    return 0
  }

  function handleBrushSelection(
    domain: {
      x: DomainTuple
    },
    tabSelection: any,
  ) {
    let probability = (domain.x[1] as number) - (domain.x[0] as number)
    //disable tab buttons if probability is different
    let probArray

    switch (tabSelection) {
      case 0:
        if (Math.abs(1 - probability) > 0.001) {
          setTab(-1)
        }
        break
      case 1:
        if (Math.abs(0.2 - probability) > 0.001) {
          setTab(-1)
        }
        break
      case 2:
        probArray = findPositiveDomain()
        if (
          Math.abs(probArray[1] - probArray[0] - probability) > 0.01 ||
          domain.x[1] !== probArray[1]
        ) {
          setTab(-1)
        }
        break
      case 3:
        probArray = findNegativeDomain()
        if (
          Math.abs(probArray[1] - probArray[0] - probability) > 0.01 ||
          domain.x[1] !== probArray[1]
        ) {
          setTab(-1)
        }
        break

      default:
        break
    }

    var indexMin = sampleData.findIndex(function (item) {
      return (item[1] as number) >= (domain.x[1] as number)
    })
    if (indexMin === -1) {
      indexMin = sampleData.length - 1
    }

    var indexMax = sampleData.findIndex(function (item) {
      return (item[1] as number) > (domain.x[0] as number)
    })
    if (indexMax === -1) {
      indexMax = sampleData.length - 1
    }

    const minAmount =
      indexMin === sampleData.length - 1
        ? sampleData[indexMin][0]
        : indexMin === 0
        ? sampleData[0][0]
        : sampleData[indexMin - 1][0]
    const maxAmount =
      indexMax === sampleData.length - 1
        ? sampleData[indexMax][0]
        : indexMax === 0
        ? sampleData[0][0]
        : sampleData[indexMax - 1][0]

    if (probability > 0.001) {
      setText(
        <SelectionGraphDescription
          minAmount={minAmount}
          maxAmount={maxAmount}
          probability={probability}
          currency={props.currentSnapshot.currency}
          treeSelectionGraph={props.treeSelectionGraph !== undefined}
          resultsFromBackend={props.resultsFromBackend}
        />,
      )
    } else {
      setText('')
    }

    setSelectionArea(domain.x)
    return [minAmount, maxAmount]
  }

  function initializeText(domain: { x: DomainTuple }) {
    var probability = (domain.x[1] as number) - (domain.x[0] as number)
    var indexMin = sampleData.findIndex(function (item) {
      return (item[1] as number) > (domain.x[1] as number)
    })
    if (indexMin === -1) {
      indexMin = sampleData.length - 1
    }

    var indexMax = sampleData.findIndex(function (item) {
      return (item[1] as number) > (domain.x[0] as number)
    })
    if (indexMax === -1) {
      indexMax = sampleData.length - 1
    }

    const minAmount = sampleData[indexMin][0]
    const maxAmount = sampleData[indexMax][0]

    if (probability > 0.001) {
      return (
        <SelectionGraphDescription
          minAmount={minAmount}
          maxAmount={maxAmount}
          probability={probability}
          currency={props.currentSnapshot.currency}
          treeSelectionGraph={props.treeSelectionGraph !== undefined}
          resultsFromBackend={props.resultsFromBackend}
        />
      )
    } else {
      return ''
    }
  }

  sampleData.sort(Comparator)

  const getAxisDomain = () => {
    var start = 0
    var end = 0

    if (sampleData[0][0] <= 0) {
      start = 0
      end = sampleData[sampleData.length - 1][0]
    } else if (sampleData[sampleData.length - 1][0] > 0) {
      start = sampleData[0][0]
      end = 0
    } else {
      start = sampleData[0][0]
      end = sampleData[sampleData.length - 1][0]
    }

    let tempDomain: DomainTuple = [end, start]
    return tempDomain
  }

  const calculateTickValues = () => {
    const tickDistance =
      sampleData[0][0] < 0
        ? sampleData[sampleData.length - 1][0]
        : sampleData[sampleData.length - 1][0] > 0
        ? sampleData[0][0]
        : sampleData[0][0] - sampleData[sampleData.length - 1][0]

    let start =
      sampleData[sampleData.length - 1][0] > 0 || sampleData[0][0] < 0
        ? 0
        : sampleData[sampleData.length - 1][0]
    let tickArray = []
    if (start < roundNumberTo(start + 0.0001, 5)) {
      tickArray.push(roundNumberTo(start + 0.001, 5))
    } else {
      tickArray.push(roundNumberTo(start, 5))
    }
    let tickNumber = props.treeSelectionGraph ? 5 : 10
    for (let i = 0; i < tickNumber - 1; i++) {
      start += tickDistance / tickNumber
      tickArray.push(roundNumberTo(start, 3))
    }

    if (sampleData[0][0] >= 0) {
      if (sampleData[0][0] < roundNumberTo(sampleData[0][0], 5)) {
        tickArray.push(floorNumberTo(sampleData[0][0] - 0.001, 5))
      } else {
        tickArray.push(roundNumberTo(sampleData[0][0], 5))
      }
    } else {
      if (
        sampleData[sampleData.length - 1][0] <
        roundNumberTo(sampleData[0][0], 5)
      ) {
        tickArray.push(
          roundNumberTo(sampleData[sampleData.length - 1][0] + 0.001, 5),
        )
      } else {
        tickArray.push(roundNumberTo(sampleData[sampleData.length - 1][0], 5))
      }
    }

    return tickArray
  }

  function findPositiveDomain() {
    var indexMin = sampleData.findIndex(function (item) {
      return item[0] > 0
    })

    var sampleDataReversed = JSON.parse(JSON.stringify(sampleData))
    sampleDataReversed.reverse()

    var indexMax = sampleDataReversed.findIndex(function (item: number[]) {
      return item[0] > 0
    })

    var minProb
    var maxProb
    if (sampleData[indexMin] && sampleDataReversed[indexMax + 1]) {
      minProb = sampleData[indexMin][1]
      maxProb = sampleDataReversed[indexMax][1]
    } else {
      minProb = 0
      maxProb = 0
    }

    let tempDomain: DomainTuple = [minProb, maxProb]
    return tempDomain
  }
  function findNegativeDomain() {
    var indexMin = sampleData.findIndex(function (item) {
      return item[0] < 0
    })

    var sampleDataReversed = JSON.parse(JSON.stringify(sampleData))
    sampleDataReversed.reverse()
    var indexMax = sampleDataReversed.findIndex(function (item: number[]) {
      return item[0] < 0
    })

    var minProb
    var maxProb
    if (sampleData[indexMin] && sampleDataReversed[indexMax]) {
      minProb = sampleData[indexMin][1]
      maxProb = sampleDataReversed[indexMax][1]
    } else {
      minProb = 0
      maxProb = 0
    }
    let tempDomain: DomainTuple = [minProb, maxProb]
    return tempDomain
  }

  function factor() {
    let arrayOfValues = calculateTickValues()
    let maxLength = 0
    for (let i = 0; i < arrayOfValues.length; i++) {
      if (arrayOfValues[i].toString().length > maxLength) {
        maxLength = arrayOfValues[i].toString().length
      }
    }

    let factor = 70
    if (maxLength > 15) {
      factor = 120
    } else if (maxLength > 14) {
      factor = 110
    } else if (maxLength > 10) {
      factor = 100
    } else if (maxLength > 8) {
      factor = 90
    } else if (maxLength > 4) {
      factor = 80
    }
    return factor
  }
  function factorForTree() {
    let arrayOfValues = calculateTickValues()
    let maxLength = 0
    for (let i = 0; i < arrayOfValues.length; i++) {
      if (arrayOfValues[i].toString().length > maxLength) {
        maxLength = arrayOfValues[i].toString().length
      }
    }

    let factor = 50
    if (maxLength > 14) {
      factor = 100
    } else if (maxLength > 10) {
      factor = 85
    } else if (maxLength > 8) {
      factor = 70
    } else if (maxLength > 4) {
      factor = 60
    }

    return factor
  }

  return (
    <>
      {!props.pdfPreview && <div className="chart-selection-title">{text}</div>}
      <div
        className={props.treeSelectionGraph ? 'chart treeChart' : 'chart'}
        id="selectionGraphContainer"
        data-openreplay-obscured
      >
        <div className="sub-chart">
          {updateGraph ? (
            <VictoryChart
              domain={{
                y: getAxisDomain(),
              }}
              containerComponent={
                props.pdfPreview ? (
                  <VictoryContainer />
                ) : (
                  <VictoryBrushContainer
                    brushDimension="x"
                    brushDomain={{ x: selectionArea }}
                    brushStyle={{
                      stroke: 'transparent',
                      fill: '#FED77D',
                      fillOpacity: 0.2,
                    }}
                    defaultBrushArea="move"
                    brushComponent={<rect id="selectionArea"></rect>}
                    handleComponent={<rect></rect>}
                    handleStyle={{
                      stroke: '#FED77D',
                      fill: '#FED77D',
                      height: `${barHeight - 100}`,
                      width: '2',
                      //y: '60',
                      //rx: '1',
                      //ry: '1',
                      transform: 'translate(2px,0)',
                    }}
                    onBrushDomainChange={(domain: any) =>
                      handleBrushSelection(domain, tab)
                    }
                  />
                )
              }
              padding={{
                top: 60,
                left: props.treeSelectionGraph ? 80 : 120,
                bottom: 40,
                right: props.treeSelectionGraph ? 0 : 50,
              }}
              width={widthOfDiv}
              height={barHeight}
            >
              {sampleData.length > graphLimit ? (
                <VictoryArea
                  style={{
                    data: {
                      fill: ({ active }: any) =>
                        active ? '#d0d3e8' : ' #d0d3e8',
                      stroke: '#201a2d',
                      strokeWidth: 1,
                    },
                  }}
                  data={sampleData}
                  y={0}
                  x={1}
                  labels={sampleData.map(
                    (item) =>
                      `${stringAndRoundTo2Decimals(item[0], user.settings)} ${
                        props.currentSnapshot.currency
                      } , ${stringAndRoundTo2Decimals(
                        item[1] * 100,
                        user.settings,
                      )} %`,
                  )}
                  labelComponent={<VictoryTooltip active={false} />}
                  data-openreplay-hidden
                />
              ) : (
                <VictoryArea
                  style={{
                    data: {
                      fill: ({ active }: any) =>
                        active ? '#d0d3e8' : ' #d0d3e8',
                      stroke: '#201a2d',
                      strokeWidth: 1,
                    },
                  }}
                  data={sampleData}
                  y={0}
                  x={1}
                  labels={sampleData.map(
                    (item) =>
                      `${stringAndRoundTo2Decimals(item[0], user.settings)} ${
                        props.currentSnapshot.currency
                      } , ${stringAndRoundTo2Decimals(
                        item[1] * 100,
                        user.settings,
                      )} %`,
                  )}
                  labelComponent={
                    <VictoryTooltip
                      labelComponent={<VictoryTooltip active={false} />}
                      style={{
                        fill: '#d0d3e8',
                        fontSize: '22',
                      }}
                      flyoutStyle={{
                        stroke: ' #201a2d',
                        strokeWidth: 1,
                      }}
                    />
                  }
                  data-openreplay-hidden
                />
              )}

              <VictoryAxis
                tickCount={10}
                //tickFormat={(x) => `${x * 100} %`}
                tickFormat={(x: number) => `10%`}
                tickLabelComponent={
                  <VictoryLabel
                    dx={-widthOfDiv / 12 / 2}
                    dy={
                      sampleData[0][0] <= 0 ? widthOfDiv / 40 : -widthOfDiv / 30
                    }
                  />
                }
                style={{
                  axis: { stroke: '#756f6a' },
                  ticks: {
                    stroke: 'grey',
                    size: widthOfDiv / 40,
                    transform:
                      sampleData[0][0] <= 0
                        ? `translate(0, ${widthOfDiv / 40}px)`
                        : `translate(0, -${widthOfDiv / 80}px)`,
                  },
                  tickLabels: {
                    fontSize: widthOfDiv / 100,
                    fill:
                      getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                      ).color ?? 'grey',
                    fontFamily: 'roboto-local',
                  },
                  //grid: { stroke: 'grey', opacity: 0.3, height: '50px' },
                }}
              />
              <VictoryAxis
                tickValues={calculateTickValues()}
                style={{
                  axis: { stroke: '#756f6a' },
                  //axisLabel: { fontSize: 30, padding: 30 },
                  ticks: {
                    stroke: 'grey',
                    size: 5,
                  },
                  tickLabels: {
                    fontSize: props.treeSelectionGraph
                      ? widthOfDiv / factorForTree()
                      : widthOfDiv / factor(),
                    padding: 5,
                    fontFamily: 'roboto-local',
                    fill:
                      getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                      ).color ?? 'black',
                  },
                }}
                dependentAxis
                tickFormat={(y) =>
                  `${stringAndRoundBigNumbers(
                    y,
                    user.settings,
                    resultsSettings.settings.roundedResults &&
                      !props.treeSelectionGraph,
                    findDisputedAmount(props.currentSnapshot),
                  )}${
                    props.pdfPreview &&
                    y !== calculateTickValues()[0] &&
                    y !== calculateTickValues().reverse()[0] &&
                    props.resultsFromBackend &&
                    props.resultsFromBackend.model_used === 'statistical'
                      ? '*'
                      : ''
                  } ${props.currentSnapshot.currency}`
                }
              />
            </VictoryChart>
          ) : (
            <VictoryChart
              domain={{
                y: getAxisDomain(),
              }}
              padding={{
                top: 60,
                left: props.treeSelectionGraph ? 80 : 120,
                bottom: 40,
                right: props.treeSelectionGraph ? 0 : 50,
              }}
              width={widthOfDiv}
              height={(widthOfDiv * 4) / 10}
            >
              {sampleData.length > graphLimit ? (
                <VictoryArea
                  style={{
                    data: {
                      fill: ({ active }: any) =>
                        active ? '#d0d3e8' : ' #d0d3e8',
                      stroke: '#201a2d',
                      strokeWidth: 1,
                    },
                  }}
                  data={sampleData}
                  y={0}
                  x={1}
                  labels={sampleData.map(
                    (item) =>
                      `${stringAndRoundTo2Decimals(item[0], user.settings)} ${
                        props.currentSnapshot.currency
                      } , ${stringAndRoundTo2Decimals(
                        item[1] * 100,
                        user.settings,
                      )} %`,
                  )}
                  labelComponent={<VictoryTooltip active={false} />}
                  data-openreplay-hidden
                />
              ) : (
                <VictoryArea
                  style={{
                    data: {
                      fill: ({ active }: any) =>
                        active ? '#d0d3e8' : ' #d0d3e8',
                      stroke: '#201a2d',
                      strokeWidth: 1,
                    },
                  }}
                  data={sampleData}
                  y={0}
                  x={1}
                  labels={sampleData.map(
                    (item) =>
                      `${stringAndRoundTo2Decimals(item[0], user.settings)} ${
                        props.currentSnapshot.currency
                      } , ${stringAndRoundTo2Decimals(
                        item[1] * 100,
                        user.settings,
                      )} %`,
                  )}
                  labelComponent={
                    <VictoryTooltip
                      labelComponent={<VictoryTooltip active={false} />}
                      style={{
                        fill: '#2e394b',
                        fontSize: '22',
                      }}
                      flyoutStyle={{
                        stroke: ' #2e394b',
                        strokeWidth: 1,
                      }}
                    />
                  }
                  data-openreplay-hidden
                />
              )}

              <VictoryAxis
                tickCount={10}
                tickFormat={(x: number) => `10%`}
                tickLabelComponent={
                  <VictoryLabel
                    dx={-widthOfDiv / 12 / 2}
                    dy={
                      sampleData[0][0] <= 0 ? widthOfDiv / 40 : -widthOfDiv / 30
                    }
                  />
                }
                style={{
                  axis: { stroke: '#756f6a' },
                  ticks: {
                    stroke: 'grey',
                    size: widthOfDiv / 40,
                    transform:
                      sampleData[0][0] <= 0
                        ? `translate(0, ${widthOfDiv / 40}px)`
                        : `translate(0, -${widthOfDiv / 80}px)`,
                  },
                  tickLabels: {
                    fontSize: widthOfDiv / 100,
                    fill:
                      getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                      ).color ?? 'grey',
                    fontFamily: 'roboto-local',
                  },

                  //grid: { stroke: 'grey', opacity: 0.3, height: '50px' },
                }}
              />
              <VictoryAxis
                tickValues={calculateTickValues()}
                // tickCount="10"
                style={{
                  axis: { stroke: '#756f6a' },
                  //axisLabel: { fontSize: 30, padding: 30 },
                  ticks: { stroke: 'grey', size: 5 },
                  tickLabels: {
                    fontSize: props.treeSelectionGraph
                      ? widthOfDiv / factorForTree()
                      : widthOfDiv / factor(),
                    padding: 5,
                    fontFamily: 'roboto-local',
                    fill:
                      getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                      ).color ?? 'black',
                  },
                }}
                dependentAxis
                tickFormat={(y) =>
                  `${stringAndRoundBigNumbers(
                    y,
                    user.settings,
                    resultsSettings.settings.roundedResults &&
                      !props.treeSelectionGraph,
                    findDisputedAmount(props.currentSnapshot),
                  )}${
                    props.pdfPreview &&
                    y !== calculateTickValues()[0] &&
                    y !== calculateTickValues().reverse()[0] &&
                    props.resultsFromBackend &&
                    props.resultsFromBackend.model_used === 'statistical'
                      ? '*'
                      : ''
                  } ${props.currentSnapshot.currency}`
                }
              />
            </VictoryChart>
          )}
        </div>
      </div>
      {!props.pdfPreview &&
        !props.treeSelectionGraph &&
        fullscreenActionMessage !== ActionMessage.downloadingImage && (
          <div
            className="selection-tabs marginLeft1"
            style={
              props.treeSelectionGraph
                ? { zIndex: 1, position: 'relative' }
                : undefined
            }
          >
            <Button
              id={'selectionGraphPositiveButton'}
              buttonText={getText('button-35', user.settings)}
              buttonTextAttribute="button-35"
              buttonType={tab === 2 ? 'contained' : 'outlined'}
              type="button"
              onClick={() => {
                setUpdateGraph(false)

                setTab(2)
                handleBrushSelection({ x: findPositiveDomain() }, 2)
                setSelectionArea(findPositiveDomain())
                setTimeout(() => {
                  setUpdateGraph(true)
                }, 100)
                //Mixpanel 72 (All)
                logActivity(
                  freemium.isFreemium,
                  'Clicked positive selection graph button',
                )
              }}
              small
              side="left"
              NoUpperCase={true}
            />
            <Button
              id={'selectionGraphNegativeButton'}
              buttonText={getText('button-36', user.settings)}
              buttonTextAttribute="button-36"
              buttonType={tab === 3 ? 'contained' : 'outlined'}
              type="button"
              onClick={() => {
                setUpdateGraph(false)

                setTab(3)
                handleBrushSelection({ x: findNegativeDomain() }, 3)
                setSelectionArea(findNegativeDomain())
                setTimeout(() => {
                  setUpdateGraph(true)
                }, 100)
                //Mixpanel 72 (All)
                logActivity(
                  freemium.isFreemium,
                  'Clicked negative selection graph button',
                )
              }}
              small
              side="left"
              NoUpperCase={true}
            />
          </div>
        )}
      {props.treeSelectionGraph && (
        <div className="treeSelectionTabsContainer">
          <div className="treeSelectionTabs">
            <div
              className={
                tab === 2
                  ? 'treeSelectionGraphChip selected'
                  : 'treeSelectionGraphChip '
              }
              id="treeSelectionGraphPositiveButton"
              style={{ marginRight: 26 }}
              onClick={() => {
                setUpdateGraph(false)

                setTab(2)
                handleBrushSelection({ x: findPositiveDomain() }, 2)
                setSelectionArea(findPositiveDomain())
                setTimeout(() => {
                  setUpdateGraph(true)
                }, 100)
                //Mixpanel 72 (All)
                logActivity(
                  freemium.isFreemium,
                  'Clicked positive selection graph button',
                )
              }}
            >
              <img
                src={treeSelectionGraphImg}
                alt="treeSelectionGraphImg"
                className="treeSelectionGraphImg"
              />
              <p
                className="treeSelectionGraphText"
                data-textattribute="button-143"
              >
                {getText('button-143', user.settings)}
              </p>
            </div>
            <div
              className={
                tab === 3
                  ? 'treeSelectionGraphChip selected'
                  : 'treeSelectionGraphChip '
              }
              id="treeSelectionGraphPositiveButton"
              onClick={() => {
                setUpdateGraph(false)

                setTab(3)
                handleBrushSelection({ x: findNegativeDomain() }, 3)
                setSelectionArea(findNegativeDomain())
                setTimeout(() => {
                  setUpdateGraph(true)
                }, 100)
                //Mixpanel 72 (All)
                logActivity(
                  freemium.isFreemium,
                  'Clicked negative selection graph button',
                )
              }}
            >
              <img
                src={treeSelectionGraphImg}
                alt="treeSelectionGraphImg"
                className="treeSelectionGraphImg"
              />
              <p
                className="treeSelectionGraphText"
                data-textattribute="button-144"
              >
                {getText('button-144', user.settings)}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
