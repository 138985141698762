import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { Params } from '../../../models/generalTypes'
import { userState } from '../../../states/UserState'

import {
  checkResetToken,
  newPassword,
  newRegistrationLink,
} from '../../../services/requests'
import { User } from '../../../models/user'

import logo from '../../../resources/images/Eperoto-logo-blue.svg'
import { RequestError, UserRole } from '../../../models/enums'
import { getText } from '../../../services/textFunctions'
import ResetPasswordForm from './resetPasswordComponents/ResetPasswordForm'
import FullScreenPopMessage from '../../../Components/popUps/FullScreenPopMessage'
import WarningSubTextHandlingErrors from '../../../Components/popUps/popUpSmallComponents/WarningSubTextHandlingErrors'
import SuccessFailureLoadingComponent from './resetPasswordComponents/SuccessFailureLoadingComponent'
import { handlingErrorsState } from '../../../states/HandlingErrorsState'
import { findHandlingErrorState } from '../../../services/commonFunctions'
import { UserSettings } from '../../../models/userSettings'
import useWindowSize from '../../../customHooks/useWindowSize'
import {
  getScaleForPopUp,
  getTopLeftForPopUp,
} from '../../../services/popUpPositionFunctions'
import Button from '../../../Components/Buttons/Button/Button'
import bigCopy from '../../../resources/images/152-bigCopy.svg'
import { UserChecklist } from '../../../models/userChecklist'

const ResetPasswordPage = () => {
  const { width, height } = useWindowSize()

  const params = useParams<Params>()
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(userState)
  const [validationFailed, setValidationFailed] = useState(false)
  const [weakPasswordMessage, setWeakPasswordMessage] = useState<
    string | string[]
  >('')
  const [tokenError, setTokenError] = useState(false)
  const [expiredTokenEmail, setExpiredTokenEmail] = useState<
    string | undefined
  >(undefined)
  const [newLinkSent, setNewLinkSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [successfullChange, setSuccessfullChange] = useState(false)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)

  useEffect(() => {
    if (user.isLoggedIn()) {
      navigate('/', { replace: true })
    } else {
      checkResetToken(params.token!).then((res) => {
        if ('errorCode' in res) {
          if (res.message.username) {
            setExpiredTokenEmail(res.message.username)
          } else {
            setTokenError(true)
            setHandlingErrors(
              findHandlingErrorState(
                res,
                handlingErrors,
                'checkResetToken',
                true,
              ),
            )
          }
        }
      })
    }
    // eslint-disable-next-line
  }, [])

  const handleSubmit = (password: string) => {
    setLoading(true)
    newPassword({ token: params.token!, new_password: password }).then(
      (res) => {
        if ('errorCode' in res) {
          if (res.message.error) {
            setWeakPasswordMessage(res.message.error)
          } else if (res.errorCode === RequestError.TIMEOUT) {
            //id='weakPasswordErrorMessage'
            //data-textattribute='error-69'
            setWeakPasswordMessage(getText('error-69', user.settings))
          } else if (res.errorCode === RequestError.SERVER_ERROR) {
            //data-textattribute='error-70'
            setWeakPasswordMessage(getText('error-70', user.settings))
          } else {
            setWeakPasswordMessage('')
          }
          setValidationFailed(true)
          setLoading(false)
        } else {
          setValidationFailed(false)
          setLoading(false)
          setSuccessfullChange(true)
        }
      },
    )
  }

  function backToLogin() {
    setUser(
      new User(
        'Change',
        '',
        '',
        UserRole.SIMPLE_USER,
        '',
        undefined,
        undefined,
        undefined,
        '',
        '',
        '',
        '',
        '',
        UserSettings.FromJson(),
        UserChecklist.FromJson(),
      ),
    )
    navigate('/', { replace: true })
  }

  const onRequestNewLink = () => {
    newRegistrationLink({ username: expiredTokenEmail! })
    setNewLinkSent(true)
  }

  return (
    <div className="userPopUp" style={{ height: height }}>
      <div className="header-out">
        <div className="header">
          <a className="navbar-brand" href="https://eperoto.com/">
            <img className="logo" src={logo} alt="Eperoto" />
          </a>
        </div>
      </div>
      {successfullChange ? (
        <div
          className="loginBox"
          style={{
            transform: `scale(${getScaleForPopUp(width, height, 550, 620)})`,
            top: `${getTopLeftForPopUp(width, height, 550, 620)[1]}px`,
            left: `${getTopLeftForPopUp(width, height, 550, 620)[0]}px`,
          }}
        >
          <SuccessFailureLoadingComponent
            type={successfullChange ? 'success' : 'failure'}
            backToLogin={backToLogin}
          />
        </div>
      ) : tokenError ? (
        <FullScreenPopMessage
          id="expired"
          zIndex={2000}
          backGroundColor="white"
          titleTextAttribute="title-149"
          warningTitle={getText('title-149', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-expired"
                data-textattribute="description-97"
              >
                {getText('description-97', user.settings)}
              </p>
            </>
          }
          warningSubTextContainer={
            <WarningSubTextHandlingErrors id="notExist" />
          }
        />
      ) : expiredTokenEmail ? (
        newLinkSent ? (
          <FullScreenPopMessage
            id="expiredWithNewLinkSent"
            zIndex={2000}
            titleTextAttribute="title-200"
            backGroundColor="white"
            preTitleContainer={
              <img className="bigDoneImage" src={bigCopy} alt="copyImage" />
            }
            warningTitle={getText('title-200', user.settings)}
            warningSubTextContainer={
              <WarningSubTextHandlingErrors id="notExist" />
            }
          />
        ) : (
          <FullScreenPopMessage
            id="expiredWithNewLink"
            zIndex={2000}
            titleTextAttribute="title-201"
            warningTitle={getText('title-201', user.settings)}
            backGroundColor="white"
            warningTextContainer={
              <>
                <p
                  className="warningText"
                  id="popUpWarningText-expired"
                  data-textattribute="description-148"
                >
                  {getText('description-148', user.settings)}
                </p>
                <Button
                  id="requestNewRegistrationLinkButton"
                  buttonText={getText('button-133', user.settings)}
                  buttonType="contained"
                  type="button"
                  className="warningButton"
                  onClick={onRequestNewLink}
                  buttonTextAttribute="button-133"
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
              </>
            }
            warningSubTextContainer={
              <WarningSubTextHandlingErrors id="notExist" />
            }
          />
        )
      ) : (
        <div
          className="loginBox"
          style={{
            transform: `scale(${getScaleForPopUp(width, height, 550, 620)})`,
            top: `${getTopLeftForPopUp(width, height, 550, 620)[1]}px`,
            left: `${getTopLeftForPopUp(width, height, 550, 620)[0]}px`,
          }}
        >
          <ResetPasswordForm
            handleSubmit={handleSubmit}
            loading={loading}
            weakPasswordMessage={weakPasswordMessage}
            validationFailed={validationFailed}
            setValidationFailed={setValidationFailed}
          />
        </div>
      )}
    </div>
  )
}

export default ResetPasswordPage
