import { useRecoilState, useRecoilValue } from 'recoil'
import { ResultsObject } from '../../../../../../models/generalTypes'
import {
  ExecutiveSummaryVisibleElementsEnum,
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import {
  ExecutiveSummaryVisibleElements,
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../models/reportModels/reportGeneralTypes'
import { stringAndRoundTo2Decimals } from '../../../../../../Modules/DisputeModules/AppFunctions'
import valueOfCaseImg from '../../../../../../resources/images/260-valueOfCase.svg'
import settlementImg from '../../../../../../resources/images/261-settlement.svg'

import {
  calculateZOPA,
  deepCloneObject,
  logActivity,
  removeUnderscore,
} from '../../../../../../services/commonFunctions'
import { convertDateTimeToLocal } from '../../../../../../services/dateFunctions'
import {
  findDisputedAmount,
  findTotalClaimedAmount,
  findTotalCounterClaimedAmount,
} from '../../../../../../services/disputedAmountFunctions'
import { getText } from '../../../../../../services/textFunctions'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../../../states/UserState'
import ExecutiveSummaryParagraph from './ExecutiveSummaryParagraph'
import {
  getStyleFromWhitelabel,
  snapshotHasEnforcementRisk,
  snapshotHasIncurredCosts,
  snapshotHasLegalCosts,
} from '../../../../../../services/reportFunctions'
import { activeTabsState } from '../../../../../../states/ActiveTabsState'
import { ToolTabs } from '../../../../../../models/enums'
import { reportState } from '../../../../../../states/ReportState'
import { useEffect, useState } from 'react'
import { freemiumState } from '../../../../../../states/FreemiumState'
import infoCircleImg from '../../../../../../resources/images/062-infoCircleGray.svg'
import Button from '../../../../../../Components/Buttons/Button/Button'
import ZopaGraph from './ZopaGraph'
import ExecutiveSummaryLegalCosts from './ExecutiveSummaryLegalCosts'
import { reverseCase } from '../../../../../../services/reverseCaseFunctions'
import { resultsSettingsState } from '../../../../../../states/ResultsSettingsState'
import { stringAndRoundBigNumbers } from '../../../../../../Modules/DisputeModules/AppFunctionsNew'

type Props = {
  resultsFromBackend: ResultsObject
  visibleElements?: ExecutiveSummaryVisibleElements
  sectionIndex?: number
  setShowAWADetails?: (showAWADetails: boolean) => void
  setShowSettlementDetails?: (showSettlementDetails: boolean) => void
  reportWhitelabel?: ReportWhitelabel
  legalCostsDescriptions?: ReportLegalCostsType
  resultsViewParty: 'client' | 'opposing'
}

export default function TextForExecutiveSummaryNew(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const reversedSnapshot = reverseCase(scenarioSnapshot.currentSnapshot)
  const [activeTabs, setActiveTabs] = useRecoilState(activeTabsState)
  const freemium = useRecoilValue(freemiumState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  const partiesFormatForZopa = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const user = useRecoilValue(userState)

  const backendResults =
    props.resultsViewParty === 'client'
      ? props.resultsFromBackend
      : props.resultsFromBackend.reversed!

  const partiesFormat = [
    props.resultsViewParty === 'client'
      ? scenarioSnapshot.currentSnapshot.partyFormatOwn
      : reversedSnapshot.partyFormatOwn,
    props.resultsViewParty === 'client'
      ? scenarioSnapshot.currentSnapshot.partyFormatOther
      : reversedSnapshot.partyFormatOther,
  ]

  const resultsSnapshot =
    props.resultsViewParty === 'client'
      ? scenarioSnapshot.currentSnapshot
      : reversedSnapshot

  const courtName =
    scenarioSnapshot.currentSnapshot.court !== 'Other'
      ? removeUnderscore(scenarioSnapshot.currentSnapshot.court!)
      : //data-textattribute="description-193"
        getText('description-193', user.settings)

  const [showIncludeOption, setShowIncludeOption] = useState<
    ExecutiveSummaryVisibleElementsEnum | undefined
  >(undefined)
  const [report, setReport] = useRecoilState(reportState)
  // const [zopaHighAmount, setZopaHighAmount] = useState(0)
  // const [zopaLowAmount, setZopaLowAmount] = useState(0)
  const [zopaExists, setZopaExists] = useState(true)

  const clientSettlementAmount =
    backendResults.settlement_amount ?? backendResults.value_of_claim

  const clientSettlementAmountForZopa =
    props.resultsFromBackend.settlement_amount ??
    props.resultsFromBackend.value_of_claim

  const oneOutcome =
    props.resultsFromBackend.minmax.max.financial_outcome ===
    props.resultsFromBackend.minmax.min.financial_outcome

  const oneOutcomeForOpposingParty = props.resultsFromBackend.reversed
    ? props.resultsFromBackend.reversed.minmax.max.financial_outcome ===
      props.resultsFromBackend.reversed.minmax.min.financial_outcome
    : false

  useEffect(() => {
    if (props.resultsFromBackend.reversed) {
      const calculationZOPA = calculateZOPA(props.resultsFromBackend)
      if (calculationZOPA === 'noZOPA') {
        setZopaExists(false)
      } else {
        // let tempZopaLowAmount = calculationZOPA[0]
        // let tempZopaHighAmount = calculationZOPA[1]
        // setZopaLowAmount(tempZopaLowAmount)
        // setZopaHighAmount(tempZopaHighAmount)
      }
    }
    // eslint-disable-next-line
  }, [])

  function handleIncludeExcludeElement(
    includeElementName: ExecutiveSummaryVisibleElementsEnum,
  ) {
    let tempReportData = deepCloneObject(report.reportData)

    tempReportData.sections[
      props.sectionIndex!
    ].contents.executiveSummary.visibleElements[includeElementName] =
      !tempReportData.sections[props.sectionIndex!].contents.executiveSummary
        .visibleElements[includeElementName]
    setReport({ ...report, reportData: tempReportData })
  }

  return (
    <div className="textForExecutiveSummaryContainer">
      <ExecutiveSummaryParagraph
        //data-textaatribute="title-235"
        sideTitle={getText('title-235', user.settings)}
        id={ExecutiveSummaryVisibleElementsEnum.valueOfCase}
        resultsFromBackend={backendResults}
        // data-textaatribute="description-192a, description-192b, description-192c, description-192d, description-192e"
        paragraphText={
          <>
            {getText('description-192a', user.settings)}{' '}
            <span
              className={
                props.visibleElements &&
                props.visibleElements['valueOfCase'] === false
                  ? 'fadeText'
                  : undefined
              }
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.valueOfCase
                  ] === false
                  ? true
                  : false,
              )}
            >
              {getText('description-192b', user.settings)}{' '}
              {stringAndRoundBigNumbers(
                backendResults.value_of_claim,
                user.settings,
                resultsSettings.settings.roundedResults,
                findDisputedAmount(scenarioSnapshot.currentSnapshot),
              )}
              {backendResults && backendResults.model_used === 'statistical'
                ? '*'
                : ''}{' '}
              {scenarioSnapshot.currentSnapshot.currency}{' '}
              {getText('description-192c', user.settings, partiesFormat)}
            </span>
            {getText('description-192d', user.settings)} {courtName}
            {getText('description-192e', user.settings)}
          </>
        }
        executiveSummaryVisibleElement={
          ExecutiveSummaryVisibleElementsEnum.valueOfCase
        }
        paragraphImg={valueOfCaseImg}
        valueOfCaseParagraph
        visibleElements={props.visibleElements}
        showIncludeOption={
          props.visibleElements ? showIncludeOption : undefined
        }
        setShowIncludeOption={
          props.visibleElements ? setShowIncludeOption : undefined
        }
        handleIncludeExcludeElement={
          props.visibleElements ? handleIncludeExcludeElement : undefined
        }
        reportWhitelabel={props.reportWhitelabel}
      />

      <ExecutiveSummaryParagraph
        //data-textattribute="title-241"
        sideTitle={getText('title-241', user.settings)}
        id={`${ExecutiveSummaryVisibleElementsEnum.settlement}`}
        resultsFromBackend={backendResults}
        paragraphText={
          snapshotHasLegalCosts(resultsSnapshot) ? (
            snapshotHasIncurredCosts(resultsSnapshot) ? (
              <>
                <span
                  className={
                    props.visibleElements &&
                    props.visibleElements['settlement'] === false
                      ? 'fadeText'
                      : undefined
                  }
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.bold,
                    'noRoboto',
                    false,
                    props.visibleElements &&
                      props.visibleElements[
                        ExecutiveSummaryVisibleElementsEnum.settlement
                      ] === false
                      ? true
                      : false,
                  )}
                  data-textattribute="description-194a,description-194b,description-194c, description-194d"
                >
                  {getText('description-194a', user.settings, partiesFormat)}{' '}
                  {stringAndRoundBigNumbers(
                    clientSettlementAmount,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    findDisputedAmount(scenarioSnapshot.currentSnapshot),
                  )}
                  {backendResults && backendResults.model_used === 'statistical'
                    ? '*'
                    : ''}{' '}
                  {resultsSnapshot.currency}
                </span>
                .{' '}
                {props.visibleElements === undefined && (
                  <span>
                    <img
                      src={infoCircleImg}
                      alt="infoCircleImg"
                      className="infoCircle"
                      id="infoCircle"
                      onClick={() => props.setShowSettlementDetails!(true)}
                    />{' '}
                  </span>
                )}
                {getText('description-194b', user.settings)}
                {resultsSnapshot.hasInsurance
                  ? ` ${getText('description-194c', user.settings)}`
                  : ''}{' '}
                {getText('description-194d', user.settings)} {courtName}.
                {/* <p style={{ marginTop: 10 }}></p> */}
                {/* {props.visibleElements ? (
                  <></>
                ) : (
                  <>
                    {getText('description-195a', user.settings, partiesFormat)}{' '}
                    <span
                      className="underline link"
                      onClick={() => {
                        if (freemium.isFreemium) {
                          setFreemium({
                            ...freemium,
                            showMessage: FreemiumMessageType.General,
                          })
                        } else {
                          //Mixpanel 173
                          logActivity(
                            false,
                            `Opens pop up for creating a scenario from the other party point of view from the Executive Summary`,
                          )
                          setGlobalPopUps(true)
                        }
                      }}
                    >
                      {getText(
                        'description-195b',
                        user.settings,
                        partiesFormat,
                      )}
                    </span>
                    .
                  </>
                )} */}
              </>
            ) : (
              <>
                {props.visibleElements ? (
                  <>
                    {/* data-textattribute="description-196" */}
                    {getText('description-196', user.settings, partiesFormat)}
                  </>
                ) : (
                  <>
                    {/* data-textattribute="description-196,description-197a, description-197b" */}
                    {getText('description-196', user.settings, partiesFormat)}{' '}
                    {getText('description-197a', user.settings)}{' '}
                    <span
                      className="underline link"
                      onClick={() => {
                        setActiveTabs({
                          tab: ToolTabs.legalCosts,
                          claim: activeTabs.claim,
                        })
                        //Mixpanel 174 (All)
                        logActivity(
                          freemium.isFreemium,
                          `Navigates to Legal Costs Tab from Executive Summary link`,
                        )
                      }}
                    >
                      {getText('description-197b', user.settings)}
                    </span>
                    .
                  </>
                )}
              </>
            )
          ) : (
            <>
              {props.visibleElements ? (
                <>
                  {/* data-textattribute="description-198" */}
                  {getText('description-198', user.settings, partiesFormat)}
                </>
              ) : (
                <>
                  {getText('description-198', user.settings, partiesFormat)}{' '}
                  <span
                    className="underline link"
                    onClick={() => {
                      setActiveTabs({
                        tab: ToolTabs.legalCosts,
                        claim: activeTabs.claim,
                      })
                      //Mixpanel 174 (All)
                      logActivity(
                        freemium.isFreemium,
                        `Navigates to Legal Costs Tab from Executive Summary link`,
                      )
                    }}
                  >
                    {/* data-textattribute="description-199" */}
                    {getText('description-199', user.settings)}
                  </span>
                  .
                </>
              )}
            </>
          )
        }
        executiveSummaryVisibleElement={
          ExecutiveSummaryVisibleElementsEnum.settlement
        }
        paragraphImg={settlementImg}
        valueOfCaseParagraph
        visibleElements={props.visibleElements}
        showIncludeOption={
          props.visibleElements ? showIncludeOption : undefined
        }
        setShowIncludeOption={
          props.visibleElements ? setShowIncludeOption : undefined
        }
        handleIncludeExcludeElement={
          props.visibleElements ? handleIncludeExcludeElement : undefined
        }
        reportWhitelabel={props.reportWhitelabel}
      />
      {/* ///////////////////////ZOPA//////////////////////////////// */}
      {(props.visibleElements &&
        props.resultsFromBackend.reversed === undefined) ||
      oneOutcome ||
      oneOutcomeForOpposingParty ? null : (
        <ExecutiveSummaryParagraph
          //data-textattribute="title-251"
          sideTitle={getText('title-251', user.settings)}
          id={`${ExecutiveSummaryVisibleElementsEnum.zopa}`}
          resultsFromBackend={props.resultsFromBackend}
          paragraphText={
            <>
              {
                <>
                  {
                    <p
                      className={
                        props.visibleElements &&
                        props.visibleElements[
                          ExecutiveSummaryVisibleElementsEnum.zopa
                        ] === false
                          ? 'fadeText zopaParagraph'
                          : 'zopaParagraph'
                      }
                      id="zopaParagraph1"
                      style={getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                        false,
                        props.visibleElements &&
                          props.visibleElements[
                            ExecutiveSummaryVisibleElementsEnum.zopa
                          ] === false
                          ? true
                          : false,
                      )}
                      data-textattribute="description-202"
                    >
                      {getText(
                        'description-202',
                        user.settings,
                        partiesFormatForZopa,
                      )}
                    </p>
                  }
                  {props.resultsFromBackend.reversed ? (
                    <>
                      <p
                        className={
                          props.visibleElements &&
                          props.visibleElements[
                            ExecutiveSummaryVisibleElementsEnum.zopa
                          ] === false
                            ? 'fadeText zopaParagraph'
                            : 'zopaParagraph'
                        }
                        id="zopaParagraph2"
                        style={getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'noRoboto',
                          false,
                          props.visibleElements &&
                            props.visibleElements[
                              ExecutiveSummaryVisibleElementsEnum.zopa
                            ] === false
                            ? true
                            : false,
                        )}
                        data-textattribute="description-203c"
                      >
                        {getText(
                          'description-203c',
                          user.settings,
                          partiesFormatForZopa,
                        )}{' '}
                        {clientSettlementAmountForZopa >= 0
                          ? getText('description-204a', user.settings)
                          : getText('description-204b', user.settings)}{' '}
                        {stringAndRoundBigNumbers(
                          clientSettlementAmountForZopa,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                          findDisputedAmount(scenarioSnapshot.currentSnapshot),
                          true,
                        )}
                        {props.resultsFromBackend &&
                        props.resultsFromBackend.model_used === 'statistical'
                          ? '*'
                          : ''}{' '}
                        {scenarioSnapshot.currentSnapshot.currency}{' '}
                        {clientSettlementAmountForZopa >= 0
                          ? getText('description-206a', user.settings)
                          : getText('description-206b', user.settings)}{' '}
                        {getText(
                          'description-207a',
                          user.settings,
                          partiesFormatForZopa,
                        )}{' '}
                        {getText('description-208', user.settings)}
                      </p>
                      <p
                        className={
                          props.visibleElements &&
                          props.visibleElements[
                            ExecutiveSummaryVisibleElementsEnum.zopa
                          ] === false
                            ? 'fadeText zopaParagraph'
                            : 'zopaParagraph'
                        }
                        id="zopaParagraph3"
                        style={getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'noRoboto',
                          false,
                          props.visibleElements &&
                            props.visibleElements[
                              ExecutiveSummaryVisibleElementsEnum.zopa
                            ] === false
                            ? true
                            : false,
                        )}
                        data-textattribute="description-00"
                      >
                        {getText(
                          'description-209',
                          user.settings,
                          partiesFormatForZopa,
                        )}{' '}
                        {props.resultsFromBackend.reversed!.settlement_amount >=
                        0
                          ? getText('description-204a', user.settings)
                          : getText('description-204b', user.settings)}{' '}
                        {stringAndRoundBigNumbers(
                          props.resultsFromBackend.reversed!.settlement_amount,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                          findDisputedAmount(scenarioSnapshot.currentSnapshot),
                          true,
                        )}
                        {props.resultsFromBackend &&
                        props.resultsFromBackend.model_used === 'statistical'
                          ? '*'
                          : ''}{' '}
                        {scenarioSnapshot.currentSnapshot.currency}{' '}
                        {props.resultsFromBackend.reversed!.settlement_amount >=
                        0
                          ? getText('description-206a', user.settings)
                          : getText('description-206b', user.settings)}{' '}
                        {getText(
                          'description-207b',
                          user.settings,
                          partiesFormatForZopa,
                        )}{' '}
                        {getText('description-208', user.settings)}
                      </p>

                      {!zopaExists && (
                        <p
                          className={
                            props.visibleElements &&
                            props.visibleElements[
                              ExecutiveSummaryVisibleElementsEnum.zopa
                            ] === false
                              ? 'fadeText zopaParagraph'
                              : 'zopaParagraph'
                          }
                          id="zopaParagraph4"
                          style={getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'noRoboto',
                            false,
                            props.visibleElements &&
                              props.visibleElements[
                                ExecutiveSummaryVisibleElementsEnum.zopa
                              ] === false
                              ? true
                              : false,
                          )}
                          data-textattribute="description-210"
                        >
                          {getText('description-210', user.settings)}
                        </p>
                      )}
                      <p
                        className={
                          props.visibleElements &&
                          props.visibleElements[
                            ExecutiveSummaryVisibleElementsEnum.zopa
                          ] === false
                            ? 'fadeText zopaParagraph'
                            : 'zopaParagraph'
                        }
                        id="zopaParagraph5"
                        style={getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'noRoboto',
                          false,
                          props.visibleElements &&
                            props.visibleElements[
                              ExecutiveSummaryVisibleElementsEnum.zopa
                            ] === false
                            ? true
                            : false,
                        )}
                        data-textattribute="description-209b"
                      >
                        {getText(
                          'description-209b',
                          user.settings,
                          partiesFormatForZopa,
                        )}
                      </p>

                      <div
                        className="zopaComponent"
                        id="zopaComponent"
                        style={{ zIndex: 12 }}
                      >
                        <ZopaGraph
                          resultsFromBackend={props.resultsFromBackend}
                          reportWhitelabel={props.reportWhitelabel}
                          fadeGraph={
                            (props.visibleElements &&
                              props.visibleElements[
                                ExecutiveSummaryVisibleElementsEnum.zopa
                              ] === false) === true
                          }
                          zopaExists={zopaExists}
                          visibleElements={props.visibleElements}
                          oneOutcomeForOpposingParty={
                            oneOutcomeForOpposingParty
                          }
                          resultsViewParty={props.resultsViewParty}
                        />
                      </div>
                    </>
                  ) : (
                    !props.visibleElements && (
                      <Button
                        id={'calculateZopa'}
                        buttonText={'CALCULATE ZOPA'}
                        buttonTextAttribute={'button-0'}
                        type={'submit'}
                        className="calculateZopaButton"
                        buttonType="outlined"
                        side="left"
                        NoUpperCase={true}
                        small={false}
                      />
                    )
                  )}
                </>
              }
            </>
          }
          executiveSummaryVisibleElement={
            ExecutiveSummaryVisibleElementsEnum.zopa
          }
          valueOfCaseParagraph
          visibleElements={props.visibleElements}
          showIncludeOption={
            props.visibleElements ? showIncludeOption : undefined
          }
          setShowIncludeOption={
            props.visibleElements ? setShowIncludeOption : undefined
          }
          handleIncludeExcludeElement={
            props.visibleElements ? handleIncludeExcludeElement : undefined
          }
          reportWhitelabel={props.reportWhitelabel}
        />
      )}
      {/* ///////////////////////END OF ZOPA//////////////////////////////// */}
      <ExecutiveSummaryParagraph
        //data-textattribute = "title-236"
        sideTitle={getText('title-236', user.settings)}
        id={`${ExecutiveSummaryVisibleElementsEnum.bestWorstScenario}`}
        resultsFromBackend={backendResults}
        data-textaatribute="description-59a, description-59b, description-59c,description-59f, description-59g, description-60a, description-60b, description-60c,description-60f, description-60g"
        paragraphText={
          <>
            {getText('description-59a', user.settings)}{' '}
            <span
              className={` ${
                props.visibleElements
                  ? props.visibleElements!.bestWorstScenario
                    ? ''
                    : 'fadeText'
                  : ''
              }       
          `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.bestWorstScenario
                  ] === false
                  ? true
                  : false,
              )}
            >
              {getText('description-59b', user.settings)}
            </span>{' '}
            {getText('description-59c', user.settings)}{' '}
            {getText('description-59f', user.settings)}{' '}
            <span
              className={` ${
                props.visibleElements &&
                !props.visibleElements!.bestWorstScenario
                  ? 'fadeText robotoNumbersBold'
                  : 'robotoNumbersBold'
              }       
          `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'roboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.bestWorstScenario
                  ] === false
                  ? true
                  : false,
              )}
              data-openreplay-obscured
            >
              {stringAndRoundBigNumbers(
                backendResults.minmax.max.financial_outcome,
                user.settings,
                resultsSettings.settings.roundedResults,
                findDisputedAmount(scenarioSnapshot.currentSnapshot),
              )}
            </span>{' '}
            <span
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.bestWorstScenario
                  ] === false
                  ? true
                  : false,
              )}
            >
              {resultsSnapshot.currency}
            </span>{' '}
            {getText('description-59g', user.settings, partiesFormat)}
            <br />
            {getText('description-60a', user.settings)}{' '}
            <span
              className={` ${
                props.visibleElements
                  ? props.visibleElements!.bestWorstScenario
                    ? ''
                    : 'fadeText'
                  : ''
              }       
           `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.bestWorstScenario
                  ] === false
                  ? true
                  : false,
              )}
            >
              {getText('description-60b', user.settings)}
            </span>{' '}
            {getText('description-60c', user.settings)}{' '}
            {getText('description-60f', user.settings)}{' '}
            <span
              className={` ${
                props.visibleElements &&
                !props.visibleElements!.bestWorstScenario
                  ? 'fadeText robotoNumbersBold'
                  : 'robotoNumbersBold'
              }       
          `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'roboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.bestWorstScenario
                  ] === false
                  ? true
                  : false,
              )}
              data-openreplay-obscured
            >
              {stringAndRoundBigNumbers(
                backendResults.minmax.min.financial_outcome,
                user.settings,
                resultsSettings.settings.roundedResults,
                findDisputedAmount(scenarioSnapshot.currentSnapshot),
              )}
            </span>
            <span
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.bestWorstScenario
                  ] === false
                  ? true
                  : false,
              )}
            >
              {' '}
              {resultsSnapshot.currency}
            </span>{' '}
            {getText('description-60g', user.settings, partiesFormat)}
          </>
        }
        executiveSummaryVisibleElement={
          ExecutiveSummaryVisibleElementsEnum.bestWorstScenario
        }
        visibleElements={props.visibleElements}
        showIncludeOption={
          props.visibleElements ? showIncludeOption : undefined
        }
        setShowIncludeOption={
          props.visibleElements ? setShowIncludeOption : undefined
        }
        handleIncludeExcludeElement={
          props.visibleElements ? handleIncludeExcludeElement : undefined
        }
        reportWhitelabel={props.reportWhitelabel}
      />
      <ExecutiveSummaryParagraph
        //data-textattribute = "title-237"
        sideTitle={getText('title-237', user.settings)}
        id={`${ExecutiveSummaryVisibleElementsEnum.requestedAmounts}`}
        resultsFromBackend={props.resultsFromBackend}
        data-textaatribute="description-47, description-48, description-49"
        paragraphText={
          <>
            {getText('description-47', user.settings, partiesFormatForZopa)}{' '}
            <span
              className={` ${
                props.visibleElements
                  ? props.visibleElements!.requestedAmounts
                    ? 'robotoNumbersBold'
                    : 'fadeText robotoNumbersBold'
                  : 'robotoNumbersBold'
              }       
      `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'roboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.requestedAmounts
                  ] === false
                  ? true
                  : false,
              )}
              data-openreplay-obscured
            >
              {stringAndRoundTo2Decimals(
                Math.abs(
                  findTotalClaimedAmount(scenarioSnapshot.currentSnapshot),
                ),
                user.settings,
              )}{' '}
            </span>
            <span
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.requestedAmounts
                  ] === false
                  ? true
                  : false,
              )}
            >
              {scenarioSnapshot.currentSnapshot.currency}
            </span>
            .
            <sup
              className={` ${
                props.visibleElements
                  ? props.visibleElements!.requestedAmounts
                    ? ''
                    : 'fadeText'
                  : ''
              } `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.requestedAmounts
                  ] === false
                  ? true
                  : false,
              )}
            >
              1
            </sup>
            <br />
            {getText(
              'description-48',
              user.settings,
              partiesFormatForZopa,
            )}{' '}
            <span
              className={` ${
                props.visibleElements
                  ? props.visibleElements!.requestedAmounts
                    ? 'robotoNumbersBold'
                    : 'fadeText robotoNumbersBold'
                  : 'robotoNumbersBold'
              }       
          `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'roboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.requestedAmounts
                  ] === false
                  ? true
                  : false,
              )}
              data-openreplay-obscured
            >
              {stringAndRoundTo2Decimals(
                Math.abs(
                  findTotalCounterClaimedAmount(
                    scenarioSnapshot.currentSnapshot,
                  ),
                ),
                user.settings,
              )}{' '}
            </span>
            <span
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.requestedAmounts
                  ] === false
                  ? true
                  : false,
              )}
            >
              {scenarioSnapshot.currentSnapshot.currency}
            </span>
            .
            <sup
              className={` ${
                props.visibleElements
                  ? props.visibleElements!.requestedAmounts
                    ? ''
                    : 'fadeText'
                  : ''
              } `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.requestedAmounts
                  ] === false
                  ? true
                  : false,
              )}
            >
              2
            </sup>
            <br />
            {getText('description-49', user.settings)}{' '}
            <span
              className={` ${
                props.visibleElements
                  ? props.visibleElements!.requestedAmounts
                    ? 'robotoNumbersBold'
                    : 'fadeText robotoNumbersBold'
                  : 'robotoNumbersBold'
              }       
          `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'roboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.requestedAmounts
                  ] === false
                  ? true
                  : false,
              )}
              data-openreplay-obscured
            >
              {stringAndRoundTo2Decimals(
                findDisputedAmount(scenarioSnapshot.currentSnapshot),
                user.settings,
              )}{' '}
            </span>
            <span
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.requestedAmounts
                  ] === false
                  ? true
                  : false,
              )}
            >
              {scenarioSnapshot.currentSnapshot.currency}
            </span>
            .
            <sup
              className={` ${
                props.visibleElements
                  ? props.visibleElements!.requestedAmounts
                    ? ''
                    : 'fadeText'
                  : ''
              } `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.requestedAmounts
                  ] === false
                  ? true
                  : false,
              )}
            >
              (1+2)
            </sup>
          </>
        }
        executiveSummaryVisibleElement={
          ExecutiveSummaryVisibleElementsEnum.requestedAmounts
        }
        visibleElements={props.visibleElements}
        showIncludeOption={
          props.visibleElements ? showIncludeOption : undefined
        }
        setShowIncludeOption={
          props.visibleElements ? setShowIncludeOption : undefined
        }
        handleIncludeExcludeElement={
          props.visibleElements ? handleIncludeExcludeElement : undefined
        }
        reportWhitelabel={props.reportWhitelabel}
      />
      <ExecutiveSummaryParagraph
        //data-textattribute = "title-238"
        sideTitle={getText('title-238', user.settings)}
        id={`${ExecutiveSummaryVisibleElementsEnum.proceedings}`}
        resultsFromBackend={backendResults}
        data-textaatribute={`${
          resultsSnapshot.probSecondTrial! > 0
            ? 'description-50'
            : 'description-51'
        }${
          resultsSnapshot.probSecondTrial! > 0
        },description-55a, description-55b`}
        paragraphText={
          <>
            {resultsSnapshot.probSecondTrial! > 0
              ? getText('description-50', user.settings)
              : getText('description-51', user.settings)}{' '}
            <span
              className={` ${
                props.visibleElements
                  ? props.visibleElements!.proceedings
                    ? ''
                    : 'fadeText'
                  : ''
              }       
        `}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.bold,
                'noRoboto',
                false,
                props.visibleElements &&
                  props.visibleElements[
                    ExecutiveSummaryVisibleElementsEnum.proceedings
                  ] === false
                  ? true
                  : false,
              )}
              data-openreplay-obscured
            >
              {convertDateTimeToLocal(
                resultsSnapshot.firstTrialDate!,
                user.settings,
                true,
              )}
            </span>
            .
            <br />
            {resultsSnapshot.probSecondTrial! > 0 ? (
              <>
                {getText('description-55a', user.settings)}{' '}
                {stringAndRoundTo2Decimals(
                  resultsSnapshot.probSecondTrial,
                  user.settings,
                )}
                {getText('description-55b', user.settings)}{' '}
                <span
                  className={` ${
                    props.visibleElements
                      ? props.visibleElements!.proceedings
                        ? ''
                        : 'fadeText'
                      : ''
                  }       
            `}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.bold,
                    'noRoboto',
                    false,
                    props.visibleElements &&
                      props.visibleElements[
                        ExecutiveSummaryVisibleElementsEnum.proceedings
                      ] === false
                      ? true
                      : false,
                  )}
                >
                  {convertDateTimeToLocal(
                    resultsSnapshot.secondTrialDate!,
                    user.settings,
                    true,
                  )}
                </span>
                .
              </>
            ) : null}
          </>
        }
        executiveSummaryVisibleElement={
          ExecutiveSummaryVisibleElementsEnum.proceedings
        }
        visibleElements={props.visibleElements}
        showIncludeOption={
          props.visibleElements ? showIncludeOption : undefined
        }
        setShowIncludeOption={
          props.visibleElements ? setShowIncludeOption : undefined
        }
        handleIncludeExcludeElement={
          props.visibleElements ? handleIncludeExcludeElement : undefined
        }
        reportWhitelabel={props.reportWhitelabel}
      />
      <ExecutiveSummaryLegalCosts
        resultsFromBackend={props.resultsFromBackend}
        visibleElements={props.visibleElements}
        reportWhitelabel={props.reportWhitelabel}
        showIncludeOption={showIncludeOption}
        setShowIncludeOption={setShowIncludeOption}
        handleIncludeExcludeElement={handleIncludeExcludeElement}
        legalCostsDescriptions={props.legalCostsDescriptions}
      />
      {snapshotHasEnforcementRisk(resultsSnapshot) ? (
        <ExecutiveSummaryParagraph
          //data-textattribute = "title-240a, title240b"
          sideTitle={`${getText('title-240a', user.settings)} ${getText(
            'title-240b',
            user.settings,
          )}`}
          id={`${ExecutiveSummaryVisibleElementsEnum.enforcement}`}
          resultsFromBackend={backendResults}
          data-textaatribute={`description-58a,description-58b,description-58c,description-58d`}
          paragraphText={
            <>
              {getText('description-58a', user.settings, partiesFormatForZopa)}{' '}
              <span
                className={` ${
                  props.visibleElements
                    ? props.visibleElements!.enforcement
                      ? ''
                      : 'fadeText'
                    : ''
                }       
        `}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.bold,
                  'noRoboto',
                  false,
                  props.visibleElements &&
                    props.visibleElements[
                      ExecutiveSummaryVisibleElementsEnum.enforcement
                    ] === false
                    ? true
                    : false,
                )}
                data-openreplay-obscured
              >
                {resultsSnapshot.bankruptcyPercentage}
                {getText('description-58b', user.settings)}{' '}
              </span>
              {getText('description-58c', user.settings, partiesFormatForZopa)}{' '}
              <span
                className={`${
                  props.visibleElements && !props.visibleElements!.enforcement
                    ? 'fadeText'
                    : ''
                }       
        `}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                  false,
                  props.visibleElements &&
                    props.visibleElements[
                      ExecutiveSummaryVisibleElementsEnum.enforcement
                    ] === false
                    ? true
                    : false,
                )}
              >
                {getText('description-58d', user.settings)}
              </span>
              .
            </>
          }
          executiveSummaryVisibleElement={
            ExecutiveSummaryVisibleElementsEnum.enforcement
          }
          visibleElements={props.visibleElements}
          showIncludeOption={
            props.visibleElements ? showIncludeOption : undefined
          }
          setShowIncludeOption={
            props.visibleElements ? setShowIncludeOption : undefined
          }
          handleIncludeExcludeElement={
            props.visibleElements ? handleIncludeExcludeElement : undefined
          }
          reportWhitelabel={props.reportWhitelabel}
        />
      ) : null}
      {backendResults && backendResults.model_used === 'statistical' && (
        <p
          className={`asteriskMessageContainer ${
            props.visibleElements
              ? props.visibleElements!.valueOfCase
                ? 'previewPDF'
                : 'fadeText previewPDF'
              : ''
          }`}
          data-textattribute={'description-176'}
          id={`asteriskMessageContainer-executiveSummary-${
            props.visibleElements
              ? props.visibleElements!.valueOfCase
                ? 'previewPDF'
                : 'fadeText previewPDF'
              : ''
          }`}
          style={
            props.visibleElements
              ? getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )
              : {}
          }
          data-openreplay-obscured
        >
          {getText('description-176', user.settings)}{' '}
          {stringAndRoundTo2Decimals(
            backendResults.difference_from_value,
            user.settings,
          )}{' '}
          {resultsSnapshot.currency}.{' '}
          {props.visibleElements === undefined ? (
            <span
              className="asteriskMessageContainer-learnMoreSpan"
              id="asteriskMessageContainer-learnMoreSpan-executiveSummary"
              onClick={() => props.setShowAWADetails!(true)}
              data-textattribute={'description-177'}
            >
              {getText('description-177', user.settings)}
            </span>
          ) : null}
        </p>
      )}
    </div>
  )
}
