import { useRecoilValue } from 'recoil'
import { Currency } from '../../../../../../../../models/enums'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { findDisputedAmount } from '../../../../../../../../services/disputedAmountFunctions'

type Props = {
  probability: number
  currency: Currency
  minAmount: number
  maxAmount: number
  treeSelectionGraph: boolean
  resultsFromBackend: ResultsObject | undefined
}
export default function SelectionGraphDescription(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  return (
    <p
      className={
        props.treeSelectionGraph
          ? 'chart-selection-title-paragraph treeSelectionGraph'
          : 'chart-selection-title-paragraph'
      }
      id="selectionGraph-description"
      data-textattribute="description-61a,description-61b,description-61c,description-61d"
      data-openreplay-obscured
    >
      {getText('description-61a', user.settings)}{' '}
      <span>
        {stringAndRoundBigNumbers(
          props.probability * 100,
          user.settings,
          resultsSettings.settings.roundedResults && !props.treeSelectionGraph,
          findDisputedAmount(scenarioSnapshot.currentSnapshot),
          false,
          true,
        )}
        {getText('description-61b', user.settings)}
      </span>{' '}
      {getText('description-61c', user.settings)}{' '}
      <span>
        {stringAndRoundBigNumbers(
          props.minAmount,
          user.settings,
          resultsSettings.settings.roundedResults && !props.treeSelectionGraph,
          findDisputedAmount(scenarioSnapshot.currentSnapshot),
        )}
        {props.resultsFromBackend &&
        props.resultsFromBackend.model_used === 'statistical'
          ? '*'
          : ''}{' '}
        {props.currency}
      </span>{' '}
      {getText('description-61d', user.settings)}{' '}
      <span>
        {stringAndRoundBigNumbers(
          props.maxAmount,
          user.settings,
          resultsSettings.settings.roundedResults && !props.treeSelectionGraph,
          findDisputedAmount(scenarioSnapshot.currentSnapshot),
        )}
        {props.resultsFromBackend &&
        props.resultsFromBackend.model_used === 'statistical'
          ? '*'
          : ''}{' '}
        {props.currency}
      </span>
    </p>
  )
}
