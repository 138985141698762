import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import ListPageTemplate from '../../Components/pageTemplates/ListPageTemplate'
import { SnapshotStatus, UserRole, UserRoleKeys } from '../../models/enums'
import { pageDepthState } from '../../states/PageDepthState'
import { userState } from '../../states/UserState'
import { freemiumState } from '../../states/FreemiumState'
import FreemiumListPageTemplate from '../../Components/pageTemplates/FreemiumListPageTemplate'
import { getCaseFromStorage } from '../../freemiumServices/freemiumServices'

type Props = {
  showDeleted?: boolean
}

const MyCases = (props: Props) => {
  const navigate = useNavigate()
  const setPageDepth = useSetRecoilState(pageDepthState)
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  useEffect(() => {
    if (props.showDeleted) {
      setPageDepth(1.1)
    } else {
      setPageDepth(1)
    }
    // eslint-disable-next-line
  }, [props.showDeleted])

  const navigateToNextPage = (uuid: string) => {
    navigate(`mycases/${uuid}`)
  }

  const getNavigateLink = (uuid: string) => {
    return `mycases/${uuid}`
  }

  const createCasesList = (res: any, role: UserRole) => {
    return res.data.map((item: any) => createCaseFromDb(item, role))
  }

  const createCase = (res: any, role: UserRole) => {
    return createCaseFromDb(res.data, role)
  }

  const createCaseFromDb = (item: any, role: UserRole) => {
    return {
      uuid: item.groupid,
      title: item.group_name,
      dateCreated: item.time_group_created,
      lastEdited: item.time_group_last_edited ?? item.time_group_created,
      createdBy: item['group_created_by_str'],
      createdByUser: item.group_created_by
        ? {
            first_name: item.group_created_by.first_name,
            last_name: item.group_created_by.last_name,
            user_role:
              UserRole[item.group_created_by.user_role as UserRoleKeys],
            username: item.group_created_by.username,
            self: user.email === item.group_created_by.username,
          }
        : undefined,
      status: SnapshotStatus.None,
      version: 0,
      users: item.users
        ? item.users.map((userObj: any) => ({
            first_name: userObj.first_name,
            last_name: userObj.last_name,
            user_role: UserRole[userObj.user_role as UserRoleKeys],
            username: userObj.username,
            access_role: userObj.access_role,
            self: user.email === userObj.username,
          }))
        : undefined,
      lastEditedBy: item['group_last_edited_by_str'],
      lastEditedByUser: item.group_last_edited_by
        ? {
            first_name: item.group_last_edited_by.first_name,
            last_name: item.group_last_edited_by.last_name,
            user_role:
              UserRole[item.group_last_edited_by.user_role as UserRoleKeys],
            username: item.group_last_edited_by.username,
            self: user.email === item.group_last_edited_by.username,
          }
        : undefined,
      deletedBy: item.group_deleted_by_str ?? undefined,
      dateDeleted: item.time_group_deleted ?? undefined,
      deletedByUser: item.group_deleted_by
        ? {
            first_name: item.group_deleted_by.first_name,
            last_name: item.group_deleted_by.last_name,
            user_role:
              UserRole[item.group_deleted_by.user_role as UserRoleKeys],
            username: item.group_deleted_by.username,
            self: user.email === item.group_deleted_by.username,
          }
        : undefined,
      tutorialId: item.tutorial_id,
      accessedByUsers: item.accessed_by_users
        .map((userObj: any) => ({
          first_name: userObj.first_name,
          last_name: userObj.last_name,
          user_role: UserRole[userObj.user_role as UserRoleKeys],
          username: userObj.username,
          self: user.email === userObj.username,
        }))
        .filter(
          (access_user: any) =>
            (role !== UserRole.EPEROTO_ADMIN &&
              access_user.user_role !== UserRole.EPEROTO_ADMIN) ||
            role === UserRole.EPEROTO_ADMIN,
        ),
    }
  }

  const createFreemiumCasesList = (res: any) => {
    const storageCase = getCaseFromStorage()
    if (!storageCase) {
      return res.data.map((item: any) => createFreemiumCaseFromDb(item))
    }

    return [
      ...res.data.map((item: any) => createFreemiumCaseFromDb(item)),
      {
        uuid: storageCase.caseId,
        title: storageCase.caseName,
        dateCreated: storageCase.caseDateCreated,
        status: SnapshotStatus.None,
        version: 0,
      },
    ]
  }

  const createFreemiumCase = (res: any) => {
    return createFreemiumCaseFromDb(res.data)
  }

  const createFreemiumCaseFromDb = (item: any) => {
    return {
      uuid: item.groupid,
      title: item.group_name,
      dateCreated: item.time_group_created,
      status: SnapshotStatus.None,
      version: 0,
      tutorialId: item.tutorial_id,
    }
  }

  return freemium.isFreemium ? (
    <FreemiumListPageTemplate
      createItemList={createFreemiumCasesList}
      createItem={createFreemiumCase}
      itemName="Case"
      navigateToNextPage={navigateToNextPage}
      getNavigateLink={getNavigateLink}
      isMyScenariosPage={false}
    />
  ) : (
    <ListPageTemplate
      itemName="Case"
      navigateToNextPage={navigateToNextPage}
      createItem={createCase}
      createItemList={createCasesList}
      getNavigateLink={getNavigateLink}
      showDeleted={props.showDeleted}
    />
  )
}

export default MyCases
