import React, { useEffect, useState } from 'react'

import SelectionGraph from './graphComponents/selectionGraph/SelectionGraph'
import DistributionGraph from './graphComponents/distributionGraph/DistributionGraph'
import Button from '../../../../../../Components/Buttons/Button/Button'
import { ResultsObject } from '../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../models/scenarioSnapshot'
import { getText } from '../../../../../../services/textFunctions'
import { ActionMessage, VideoPostName } from '../../../../../../models/enums'
import VideoInfoButton from '../../../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { fullscreenActionMessageState } from '../../../../../../states/FullScreenActionMessageState'
import DownloadImage from '../../../../../../Components/downloadImages/DownloadImage'
import { scenarioIdentityState } from '../../../../../../states/ScenarioIdentityState'
import { stringAndRoundTo2Decimals } from '../../../../../../Modules/DisputeModules/AppFunctions'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { logActivity } from '../../../../../../services/commonFunctions'
import { freemiumState } from '../../../../../../states/FreemiumState'
import GetPayGraph from './graphComponents/getPayGraph/GetPayGraph'

type Props = {
  resultsFromBackend: ResultsObject
  currentSnapshot: ScenarioSnapshot
  widthOfDiv: number
  heightOfDiv: number
  setVideoPostName: (param: VideoPostName | undefined) => void
  setShowAWADetails: (showAWADetails: boolean) => void
  resultsViewParty: 'client' | 'opposing'
}

export default function Graphs(props: Props) {
  //const { getValues } = useFormContext()
  /* 
  
  tabs:
  0 - DISTRIBUTION GRAPH
  1 - SELECTION GRAPH
  2 - GET PAY GRAPH
  
  */
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const fullscreenActionMessage = useRecoilValue(fullscreenActionMessageState)
  const [tab, setTab] = useState(1)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const [saveGraphImageName, setSaveGraphImageName] = useState('')

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  useEffect(() => {
    let tempSaveGraphImageName = `${scenarioIdentity.caseName} - ${
      scenarioIdentity.scenarioName
    } - ${
      tab === 1
        ? getText('button-33', user.settings)
            .toLowerCase()
            .split(' ')
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        : tab === 0
        ? getText('button-34', user.settings)
            .toLowerCase()
            .split(' ')
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        : getText('button-160', user.settings)
    } - ${
      props.resultsViewParty === 'client'
        ? getText('title-260', user.settings, partiesFormat)
        : getText('title-261', user.settings, partiesFormat)
    }`
    setSaveGraphImageName(tempSaveGraphImageName)
    // eslint-disable-next-line
  }, [scenarioIdentity, tab])

  function handleChangeTab(num: number) {
    setTab(num)
  }

  return (
    <div
      className="tool-component results-tool-component"
      id="graphsComp"
      style={
        fullscreenActionMessage === ActionMessage.downloadingImage
          ? { marginTop: 0, minHeight: 760 }
          : { minHeight: 760 }
      }
    >
      <div
        className="downloadImageComponentContainer"
        style={
          fullscreenActionMessage === ActionMessage.downloadingImage
            ? { visibility: 'hidden' }
            : undefined
        }
      >
        <DownloadImage
          id={
            tab === 1
              ? 'selectionGraph'
              : tab === 0
              ? 'distributionGraph'
              : 'getPayGraph'
          }
          tooltipText={
            tab === 1
              ? getText('tooltip-32', user.settings)
              : tab === 1
              ? getText('tooltip-33', user.settings)
              : getText('tooltip-67', user.settings)
          }
          tooltipTextAttribute={
            tab === 1 ? 'tooltip-32' : tab === 0 ? 'tooltip-33' : 'tooltip-00'
          }
          imageElementId={`graphsComp`}
          saveName={saveGraphImageName}
          downloadImageType="results"
        />
      </div>
      <div
        className="tool-component-title"
        id="graphs-title"
        data-textattribute="title-39"
      >
        {fullscreenActionMessage !== ActionMessage.downloadingImage ? (
          <>
            {getText('title-39', user.settings)}
            <div className="videoInfoButtonComponentContainer">
              <VideoInfoButton
                textAttribute="tooltip-19"
                postName={VideoPostName.resultsGraphs}
                setVideoPostName={props.setVideoPostName}
              />
            </div>
          </>
        ) : tab === 1 ? (
          getText('button-33', user.settings)
            .toLowerCase()
            .split(' ')
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        ) : tab === 0 ? (
          getText('button-34', user.settings)
            .toLowerCase()
            .split(' ')
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        ) : (
          getText('button-160', user.settings)
        )}
      </div>
      <div className="resultsContent" id="resultsTable">
        <div
          className="selection-tabs marginLeft1"
          style={
            fullscreenActionMessage === ActionMessage.downloadingImage
              ? { display: 'none' }
              : undefined
          }
        >
          <Button
            id={'selectionGraphTabButton'}
            buttonText={getText('button-33', user.settings)}
            buttonTextAttribute="button-33"
            buttonType={tab === 1 ? 'contained' : 'outlined'}
            type="button"
            onClick={() => {
              handleChangeTab(1)
              //Mixpanel 70 (All)
              logActivity(freemium.isFreemium, 'Watched selection graph')
            }}
            side="left"
            NoUpperCase={true}
            small={false}
          />
          <Button
            id={'distributionGraphTabButton'}
            buttonText={getText('button-34', user.settings)}
            buttonTextAttribute="button-34"
            buttonType={tab === 0 ? 'contained' : 'outlined'}
            type="button"
            onClick={() => {
              handleChangeTab(0)
              //Mixpanel 71 (All)
              logActivity(freemium.isFreemium, 'Watched distribution graph')
            }}
            side="left"
            NoUpperCase={true}
            small={false}
          />
          <Button
            id={'getPayGraphTabButton'}
            buttonText={getText('button-159', user.settings)}
            buttonTextAttribute="button-159"
            buttonType={tab === 2 ? 'contained' : 'outlined'}
            type="button"
            onClick={() => {
              handleChangeTab(2)
              //Mixpanel 188 (All)
              logActivity(freemium.isFreemium, 'Watched getPay graph')
            }}
            side="left"
            NoUpperCase={true}
            small={false}
          />
        </div>
        <div className="graphContainer">
          <div
            className="tab-content"
            id="graphContainer"
            data-openreplay-obscured
          >
            {tab === 0 ? (
              <DistributionGraph
                resultsFromBackend={props.resultsFromBackend}
                currentSnapshot={props.currentSnapshot}
              />
            ) : tab === 1 ? (
              <SelectionGraph
                resultsFromBackend={props.resultsFromBackend}
                currentSnapshot={props.currentSnapshot}
              />
            ) : tab === 2 ? (
              <GetPayGraph
                resultsFromBackend={props.resultsFromBackend}
                currentSnapshot={props.currentSnapshot}
              />
            ) : null}
          </div>
        </div>
      </div>
      {props.resultsFromBackend &&
        props.resultsFromBackend.model_used === 'statistical' && (
          <p
            className={
              tab === 0
                ? 'asteriskMessageContainer distribution'
                : 'asteriskMessageContainer'
            }
            id="graph-asteriskMessageContainer"
            data-textattribute={'description-176'}
            data-openreplay-obscured
          >
            {getText('description-176', user.settings)}{' '}
            {stringAndRoundTo2Decimals(
              props.resultsFromBackend.difference_from_value,
              user.settings,
            )}{' '}
            {scenarioSnapshot.currentSnapshot.currency}.{' '}
            <span
              className="asteriskMessageContainer-learnMoreSpan"
              id="asteriskMessageContainer-learnMoreSpan-graph"
              onClick={() => props.setShowAWADetails(true)}
              data-textattribute={'description-177'}
            >
              {getText('description-177', user.settings)}
            </span>
          </p>
        )}
    </div>
  )
}
