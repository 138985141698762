import { useRecoilValue } from 'recoil'
import { AmountSignum, Currency } from '../../../../../../models/enums'
import { formattedNumToString } from '../../../../../../services/formatNum'
import { userState } from '../../../../../../states/UserState'
import { ReportWhitelabel } from '../../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../../../services/reportFunctions'
import { krogerusColorsState } from '../../../../../../states/KrogerusColorsState'

type Props = {
  quantumAmount: number
  quantumProbability: number
  externalConsequenceSignum: AmountSignum
  externalConsequenceAmount: number
  isExternalConsequenceOn: boolean
  quantumIndex: number
  claimIndex: number
  currency: Currency
  reportWhitelabel?: ReportWhitelabel
}

export default function QuantumComponentForClaimTile(props: Props) {
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  return (
    <>
      <div className={`quantumRowContainer`}>
        <div
          className="quantumAmountAndProbabilityContainer"
          id={`quantumAmountAndProbabilityContainer-${props.claimIndex}_${props.quantumIndex}`}
        >
          <p
            className={`quantumAmountText robotoNumbers ${
              krogerusColors ? 'krogerus' : ''
            } ${props.quantumAmount < 0 ? 'negative' : 'positive'}`}
            id={`quantumAmountText-${props.claimIndex}_${props.quantumIndex}`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'roboto',
              false,
              true,
            )}
          >
            {formattedNumToString(props.quantumAmount, user.settings, true)}{' '}
            <span
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
                false,
                true,
              )}
              className={`${props.quantumAmount < 0 ? 'negative' : 'positive'}`}
            >
              {props.currency}
            </span>
          </p>
          <p
            className="quantumProbability robotoNumbers"
            id={`quantumProbability-${props.claimIndex}_${props.quantumIndex}`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {props.quantumProbability}%
          </p>
        </div>
        {props.isExternalConsequenceOn && (
          <div
            className="externalConsequenceContainer"
            id={`externalConsequenceContainer-${props.claimIndex}_${props.quantumIndex}`}
          >
            {props.externalConsequenceAmount !== 0 ? (
              <p
                className={`${
                  krogerusColors ? 'krogerus' : ''
                } externalConsequence robotoNumbers positive`}
                id={`externalConsequenceValue-${props.claimIndex}_${props.quantumIndex}`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'roboto',
                  false,
                  true,
                )}
              >
                {formattedNumToString(
                  props.externalConsequenceAmount,
                  user.settings,
                )}{' '}
                <span
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                    false,
                    true,
                  )}
                  className="positive"
                >
                  {props.currency}
                </span>
              </p>
            ) : (
              <p
                className={`externalConsequence`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'noRoboto',
                )}
              >
                -
              </p>
            )}
          </div>
        )}
      </div>
    </>
  )
}
