import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioIdentityState } from '../../../../../../../states/ScenarioIdentityState'
import { reportState } from '../../../../../../../states/ReportState'
import { ResultsObject } from '../../../../../../../models/generalTypes'
import { userState } from '../../../../../../../states/UserState'
import {
  FontLabels,
  ReportWhitelabelKeys,
  SectionTitleKeys,
} from '../../../../../../../models/reportModels/reportEnums'
import { getText } from '../../../../../../../services/textFunctions'
import {
  deepCloneObject,
  getClientDomain,
  isDevEnv,
} from '../../../../../../../services/commonFunctions'
import ReportSection from './previewSectionsComponents/ReportSection'
import ReportAddSection from './previewSectionsComponents/ReportAddSection'
import eperotoLogo from '../../../../../../../resources/images/100-eperoto_Headlines.svg'
import { reportMenuState } from '../../../../../../../states/ReportMenuState'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import * as htmlToImage from 'html-to-image'
import { handlingErrorsState } from '../../../../../../../states/HandlingErrorsState'
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer'
import PdfDocument from './pdfDocument/PdfDocument'
import { findTodaysDate } from '../../../../../../../Modules/DisputeModules/AppFunctions'
import { debuggingState } from '../../../../../../../states/DebuggingState'
import { ReportSectionClass } from '../../../../../../../models/reportModels/reportSection'
import ReportCurrentDate from './smallComponents/ReportCurrentDate'
import { SectionId } from '../../../../../../../models/treeModels/treeTypes'
import {
  ANIMATION_DOTS_SPEED,
  MAX_REPORT_SECTIONS,
} from '../../../../../../../services/constants'
import { updateUserChecklist } from '../../../../../../../services/requests'
import { UserChecklist } from '../../../../../../../models/userChecklist'
import { User } from '../../../../../../../models/user'
import { AnimationDots } from '../../../../../../../Components/Animations/AnimationDots'
import { ReportWhitelabel } from '../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../services/reportFunctions'
import { krogerusColorsState } from '../../../../../../../states/KrogerusColorsState'
import { resultsSettingsState } from '../../../../../../../states/ResultsSettingsState'

type Props = {
  resultsFromBackend: ResultsObject
  reportWhitelabel: ReportWhitelabel | undefined
}

export default function PreviewPdfReport(props: Props) {
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const debugging = useRecoilValue(debuggingState)
  const report = useRecoilValue(reportState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const [user, setUser] = useRecoilState(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)
  const currentSnapshot = useRecoilValue(scenarioSnapshotState).currentSnapshot
  useRecoilState(handlingErrorsState)

  const [reportMenu, setReportMenu] = useRecoilState(reportMenuState)
  const [loadingImages, setLoadingImages] = useState(false)
  const [sortedSections, setSortedSections] = useState<ReportSectionClass[]>(
    deepCloneObject(report.reportData.sections),
  )
  const [claimsImagesArray, setClaimsImagesArray] = useState<string[]>([])
  const [selectionGraphImg, setSelectionGraphImg] = useState<string>('')
  const [distributionGraphImg, setDistributionGraphImg] = useState<string>('')
  const [getPayGraphImg, setGetPayGraphImg] = useState<string>('')
  const [bestAndWorsScenarioImg, setBestAndWorsScenarioImg] =
    useState<string>('')
  const [zopaGraphImg, setZopaGraphImg] = useState<string>('')

  const [animationPair, setAnimationPair] = useState<
    { sectionId: SectionId; sectionHeight: number }[]
  >([])

  const oneOutcome =
    props.resultsFromBackend.minmax.max.financial_outcome ===
    props.resultsFromBackend.minmax.min.financial_outcome

  useEffect(() => {
    let tempSortedSections = deepCloneObject(report.reportData.sections).sort(
      (sectionA: any, sectionB: any) =>
        sectionA.sectionOrder > sectionB.sectionOrder ? 1 : -1,
    )
    setSortedSections(tempSortedSections)

    // eslint-disable-next-line
  }, [report.reportData.sections])

  useEffect(() => {
    if (reportMenu.downloadPdf) {
      saveClaimImages()
    }
    // eslint-disable-next-line
  }, [reportMenu.downloadPdf])

  async function saveClaimImages() {
    if (!debugging) {
      setLoadingImages(true)
    }
    let data: any = []
    if (
      report.reportData.sections[
        report.reportData.sections.findIndex(
          (reportSection) => reportSection.title === SectionTitleKeys.Claims,
        )
      ].show
    ) {
      data = await Promise.all(
        currentSnapshot.claims.map(async (claim, index) => {
          try {
            let element = document.getElementById(
              `treePreviewContainer${index}`,
            )
            if (element) {
            } else {
              element = document.getElementById(`claimPreview${index}`)
            }
            let image = await htmlToImage.toPng(element!, { pixelRatio: 5 })
            return image
          } catch (err) {
            throw err
          }
        }),
      )
    }

    const distributionElement = document.getElementById(
      'legalCostsDistributionPreviewAppContainer',
    )

    if (
      distributionElement &&
      report.reportData.sections[
        report.reportData.sections.findIndex(
          (reportSection) =>
            reportSection.title === SectionTitleKeys.LegalCostsDistribution,
        )
      ].show
    ) {
      const legalCostsDistributionGraph = await htmlToImage.toPng(
        distributionElement!,
        { pixelRatio: 3 },
      )

      data.push(legalCostsDistributionGraph)
    }
    setClaimsImagesArray(data)
    const selectionGraphElement = document.getElementById(
      'selectionGraphPreviewContainer',
    )
    const distributionGraphElement =
      document.getElementById('distributionGraph')
    const getPayGraphElement = document.getElementById('getPayPreviewContainer')
    const bestAndWorstScenarioElement = document.getElementById(
      'bestAndWorstScenarioPreviewContainer',
    )
    const zopaGraphElement = document.getElementById('zopaComponent')

    if (
      selectionGraphElement &&
      report.reportData.sections[
        report.reportData.sections.findIndex(
          (reportSection) =>
            reportSection.title === SectionTitleKeys.SelectionGraph,
        )
      ].show
    ) {
      const selectionGraph = await htmlToImage.toPng(selectionGraphElement!, {
        pixelRatio: 3,
      })
      setSelectionGraphImg(selectionGraph)
    }
    if (
      distributionGraphElement &&
      report.reportData.sections[
        report.reportData.sections.findIndex(
          (reportSection) =>
            reportSection.title ===
            SectionTitleKeys.ProbabilityDistributionGraph,
        )
      ].show
    ) {
      const distributionGraph = await htmlToImage.toPng(
        distributionGraphElement!,
        { pixelRatio: 3 },
      )
      setDistributionGraphImg(distributionGraph)
    }
    if (
      getPayGraphElement &&
      report.reportData.sections[
        report.reportData.sections.findIndex(
          (reportSection) =>
            reportSection.title === SectionTitleKeys.GetPayGraph,
        )
      ].show
    ) {
      const getPayGraph = await htmlToImage.toPng(getPayGraphElement!, {
        pixelRatio: 3,
      })
      setGetPayGraphImg(getPayGraph)
    }

    if (
      bestAndWorstScenarioElement &&
      report.reportData.sections[
        report.reportData.sections.findIndex(
          (reportSection) =>
            reportSection.title === SectionTitleKeys.BestWorstScenario,
        )
      ].show
    ) {
      const bestAndWorstScenario = await htmlToImage.toPng(
        bestAndWorstScenarioElement!,
        { pixelRatio: 3 },
      )
      setBestAndWorsScenarioImg(bestAndWorstScenario)
    }
    if (
      zopaGraphElement &&
      report.reportData.sections[
        report.reportData.sections.findIndex(
          (reportSection) =>
            reportSection.title === SectionTitleKeys.ExecutiveSummary,
        )
      ].contents.executiveSummary?.visibleElements.zopa &&
      props.resultsFromBackend.reversed &&
      !oneOutcome
    ) {
      const originalMarginLeft = zopaGraphElement.style.marginLeft

      zopaGraphElement.style.transform = 'scale(0.9)'
      zopaGraphElement.style.marginLeft = '-60px'

      const zopaGraph = await htmlToImage.toPng(zopaGraphElement!, {
        pixelRatio: 3,
      })
      zopaGraphElement.style.transform = 'scale(1)'
      zopaGraphElement.style.marginLeft = originalMarginLeft
      setZopaGraphImg(zopaGraph)
    }

    if (!user.checklist.downloadReport) {
      updateUserChecklist(
        new UserChecklist(
          user.checklist.introVideo,
          user.checklist.accessTutorial,
          user.checklist.createCase,
          user.checklist.analyzeScenario,
          true,
        ),
      ).then((res) => {
        if (!('errorCode' in res)) {
          let newUser = deepCloneObject(user) as User
          newUser.checklist = UserChecklist.FromJson(res.data)
          setUser(newUser)
        }
      })
    }

    function completeDownload() {
      setTimeout(() => {
        let link = document.getElementsByTagName('a')[0]

        if (link.text === 'Loading document...') {
          completeDownload()
          console.log('downloading PDF')
        } else {
          if (link.href === '') {
            console.log('link href is empty. Re-downloading')
            completeDownload()
          } else {
            console.log('download complete')
            console.log(link)
            console.log(link.href)

            link.click()
            setLoadingImages(false)
            setReportMenu({ ...reportMenu, downloadPdf: false })
          }
        }
      }, 2000)
    }
    if (!debugging) {
      completeDownload()
    } else {
      setLoadingImages(false)
    }
  }

  return (
    <>
      {loadingImages ? (
        <div
          className="savingCaseMessageBackground generatingReportMessage"
          id="generatingReportMessage"
        >
          <div className="savingCaseMessageContainer">
            <p
              className="savingCaseMessageText"
              id="downloadingReportMessage"
              data-textattribute="message-63, message-117"
            >
              {getText('message-63', user.settings)}
              <AnimationDots speed={ANIMATION_DOTS_SPEED} />
              <br />
              {getText('message-117', user.settings)}
            </p>
          </div>
        </div>
      ) : null}
      {reportMenu.downloadPdf ? (
        <>
          {debugging ? (
            <>
              <PDFViewer width={1200} height={800} style={{ minHeight: 500 }}>
                <PdfDocument
                  reportDetails={report}
                  resultsFromBackend={props.resultsFromBackend}
                  claimsImagesArray={claimsImagesArray}
                  selectionGraphImg={selectionGraphImg}
                  distributionGraphImg={distributionGraphImg}
                  bestAndWorsScenarioImg={bestAndWorsScenarioImg}
                  getPayGraphImg={getPayGraphImg}
                  zopaGraphImg={zopaGraphImg}
                  currentSnapshot={currentSnapshot}
                  scenarioIdentity={scenarioIdentity}
                  companyName={user.companyName}
                  user={user}
                  reportWhitelabel={props.reportWhitelabel}
                  krogerusColors={krogerusColors}
                  roundedResults={resultsSettings.settings.roundedResults}
                />
              </PDFViewer>
              <div
                className="closePDFViewer"
                onClick={() =>
                  setReportMenu({ ...reportMenu, downloadPdf: false })
                }
              >
                CLOSE PDF VIEWER
              </div>
            </>
          ) : (
            <PDFDownloadLink
              document={
                <PdfDocument
                  reportDetails={report}
                  resultsFromBackend={props.resultsFromBackend}
                  claimsImagesArray={claimsImagesArray}
                  selectionGraphImg={selectionGraphImg}
                  distributionGraphImg={distributionGraphImg}
                  getPayGraphImg={getPayGraphImg}
                  bestAndWorsScenarioImg={bestAndWorsScenarioImg}
                  zopaGraphImg={zopaGraphImg}
                  currentSnapshot={currentSnapshot}
                  scenarioIdentity={scenarioIdentity}
                  companyName={user.companyName}
                  user={user}
                  reportWhitelabel={props.reportWhitelabel}
                  krogerusColors={krogerusColors}
                  roundedResults={resultsSettings.settings.roundedResults}
                />
              }
              fileName={`${scenarioIdentity.caseName}-${
                scenarioIdentity.scenarioName
              }-${findTodaysDate()}.pdf`}
            >
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : 'Download now!'
              }
            </PDFDownloadLink>
          )}
        </>
      ) : null}
      <div className="previewPdfContainer" data-openreplay-obscured>
        <div className="caseDetailsContainer" data-openreplay-obscured>
          <p
            className="caseNameTitle"
            id="caseNameTitle"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.reportTitle,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {scenarioIdentity.caseName}
          </p>
          <p
            className="scenarioNameTitle"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.reportSubtitle,
              FontLabels.regular,
              'noRoboto',
            )}
            id="scenarioNameTitle"
            data-textattribute="description-94"
            data-openreplay-obscured
          >
            {getText('description-94', user.settings)}{' '}
            {scenarioIdentity.scenarioName}
          </p>
          <p
            className="caseNameSubtitle"
            id="caseNameSubtitle"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.reportSubtitle,
              FontLabels.regular,
              'noRoboto',
            )}
            data-textattribute="description-93"
          >
            {getText('description-93', user.settings)}
          </p>
          <div className="eperotoLogoAndDateContainer">
            <img
              src={
                user.companyLogo && props.reportWhitelabel
                  ? isDevEnv()
                    ? `http://localhost:8000${user.companyLogo}`
                    : `${'https://'}${getClientDomain()}${user.companyLogo}`
                  : eperotoLogo
              }
              alt="eperotoLogo"
              className="eperotoLogo"
              id="eperotoLogoReportImage"
            />
            <ReportCurrentDate date={report.reportData.date} />
          </div>
        </div>
        {sortedSections.map((section, index) => (
          <ReportSection
            key={`reportSection${section.sectionId}`}
            sectionIndex={report.reportData.sections.findIndex(
              (reportSection) =>
                section.sectionOrder === reportSection.sectionOrder,
            )}
            titleKey={section.title as SectionTitleKeys}
            customTitle={section.customTitle}
            contents={section.contents}
            visible={section.show}
            resultsFromBackend={props.resultsFromBackend}
            sectionId={section.sectionId}
            sectionOrder={section.sectionOrder}
            numberOfSections={sortedSections.length}
            animationPair={animationPair}
            setAnimationPair={setAnimationPair}
            reportWhitelabel={props.reportWhitelabel}
          />
        ))}
        {sortedSections.length < MAX_REPORT_SECTIONS && (
          <ReportAddSection sectionOrder={sortedSections.length} />
        )}
        <div className="disclaimerContainer">
          <p
            className="containerTitle"
            id="pdfDisclaimerTitle"
            data-textattribute="title-144"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionTitle,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {getText('title-144', user.settings)}
          </p>
          <p
            className="containerParagraph"
            id="pdfDisclaimerText1"
            data-textattribute="description-95a, description-95b"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.disclaimer,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {props.reportWhitelabel &&
            props.reportWhitelabel.disclaimer.text !== 'default' ? (
              props.reportWhitelabel.disclaimer.text.split('\n').map((par) => (
                <span
                  key={par}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.disclaimer,
                    FontLabels.regular,
                    'noRoboto',
                  )}
                >
                  {par}
                  <br />
                </span>
              ))
            ) : (
              <>
                {getText('description-95a', user.settings)} {user.companyName}{' '}
                {getText('description-95b', user.settings)}
                <br />
                <br
                  id="pdfDisclaimerText2"
                  data-textattribute="description-96"
                />
                {getText('description-96', user.settings)} {user.companyName}.
              </>
            )}
          </p>
          {resultsSettings.settings.roundedResults && (
            <p
              className="containerParagraph"
              id="pdfDisclaimerText3"
              data-textattribute="description-254"
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.disclaimer,
                FontLabels.regular,
                'noRoboto',
              )}
            >
              {getText('description-254', user.settings)}
            </p>
          )}
        </div>
      </div>
    </>
  )
}
