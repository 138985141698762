import { Autocomplete, Box, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'
import { viewerState } from '../../../states/ViewerState'
import { getText } from '../../../services/textFunctions'
import DisabledWindow from '../../disabledWindow/DisabledWindow'
import { recoveryModeState } from '../../../states/RecoveryModeState'

type Props = {
  id: string
  label: string
  name: string
  options: string[]
  getDescriptions?: (value: string) => string
  value: string
  width: string | number
  onChange: (e: any) => void
  onOpen?: () => void
  labelTextAttribute: `label-${number}` | undefined
  error?: boolean
  errorMessage?: string
  errorTextAttribute?: `error-${number}`
  helperText?: string
  helperTextAttribute?: `description-${number}` | undefined
  disabled?: boolean
  disabledMessage?: string
  disabledMessageTextAttribute?: `message-${number}`
  tabIndex?: number
  noHelperText?: boolean
  alwaysShrink?: boolean
}

const AutocompleteDropDown = (props: Props) => {
  const user = useRecoilValue(userState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)

  const [disabledMessageCoordinates, setDisabledMessageCoordinates] = useState<
    [number, number] | undefined
  >(undefined)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [inputValue, setInputValue] = useState('')

  const disabledMessage =
    //data-textattribute = 'message-67'
    props.disabledMessage ?? getText('message-67', user.settings)

  const onClickDisabled = () => {
    if (props.disabled) {
      const element = document.getElementById(`container-${props.id}`)
      const viewportOffset = element!.getBoundingClientRect()

      let top = viewportOffset.top - 15
      const left = viewportOffset.left + 50
      const h = window.innerHeight
      if (h - top < 200) {
        top -= 130
      }

      if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        setIsViewer({ ...isViewer, shaking: true })
        setDisabledMessageCoordinates([top, left])
      }
    }
  }

  function handleChange(event: any, newValue: string) {
    let e: { target: { id: string; value: string } } = {
      target: {
        id: props.id,
        value: newValue,
      },
    }
    props.onChange(e)
  }

  return (
    <>
      <div
        className={`dropdown dropdownAutocomplete
    ${props.error ? 'dropdown-error' : ''}
    `}
        id={`container-${props.id}`}
        onClick={onClickDisabled}
        style={props.disabled ? { cursor: 'not-allowed' } : {}}
      >
        <Autocomplete
          id={props.id}
          options={props.options}
          value={props.value}
          inputValue={inputValue}
          tabIndex={props.tabIndex}
          disabled={props.disabled}
          onChange={handleChange}
          disableClearable
          autoHighlight
          blurOnSelect
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          isOptionEqualToValue={(option, value) =>
            option === value || !props.options.includes(value)
          }
          onOpen={props.onOpen}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={props.label}
                sx={{
                  width: props.width,
                  backgroundColor: 'white',
                  margin: 0,
                }}
                error={props.error}
                data-helpertextattribute={
                  props.error
                    ? props.errorTextAttribute
                    : props.helperText
                    ? props.helperTextAttribute
                    : ' '
                }
                helperText={
                  props.noHelperText
                    ? undefined
                    : props.error
                    ? props.errorMessage
                    : props.helperText
                    ? props.helperText
                    : ' '
                }
                data-textattribute={props.labelTextAttribute}
                InputLabelProps={{ shrink: props.alwaysShrink }}
              />
            )
          }}
          renderOption={
            props.getDescriptions
              ? (props2, option) => (
                  <Box component="li" {...props2} data-openreplay-obscured>
                    {option} {props.getDescriptions!(option)}
                  </Box>
                )
              : undefined
          }
          filterOptions={
            props.getDescriptions
              ? (options, state) =>
                  options.filter((opt) =>
                    `${opt.toLowerCase()} ${props.getDescriptions!(
                      opt,
                    ).toLowerCase()}`.includes(state.inputValue.toLowerCase()),
                  )
              : undefined
          }
          data-openreplay-obscured
        />
        {props.disabled && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
            }}
            onClick={onClickDisabled}
          ></div>
        )}
      </div>
      {disabledMessageCoordinates && (
        <DisabledWindow
          id={props.id}
          message={disabledMessage}
          disabledMessageTextAttribute={
            props.disabledMessageTextAttribute ?? 'message-67'
          }
          cancelFunction={() => setDisabledMessageCoordinates(undefined)}
          position={disabledMessageCoordinates}
        />
      )}
    </>
  )
}

export default AutocompleteDropDown
