export const APP_CALCULATION_LIMIT = 1000000
export const AWA_CALCULATION_LIMIT = 1000000
export const TREE_CALCULATION_LIMIT = 45000
export const TREE_CALCULATION_TIMER = 600
export const MAX_REPORT_SECTIONS = 25
export const ANIMATION_DOTS_SPEED = 700
// COLORS
export const CLIENT_COLOR = '#5a45c9'
export const OPPOSING_PARTY_COLOR = '#ff703e'
export const INFANTRY_WEST = '#47743d'
export const GREEK_EASTER = '#ac2828'

// Hard coding the colors for Krogerus
export const KROGERUS_CLIENT_COLOR = '#000000'
export const KROGERUS_OPPOSING_PARTY_COLOR = '#ffcb2b'

const LOGIN_URL = 'users/api/login/'
const CONFIRM_OTP_URL = 'users/api/confirm_otp/'
const LOGOUT_URL = 'users/api/logout/'
const RESET_PASSWORD_URL = 'users/api/reset_password/'
const NEW_PASSWORD_URL = 'users/api/new_password/'
const SET_PASSWORD_URL = 'users/api/set_password/'
const ME_URL = 'users/api/me/'
const NEW_TOKEN_URL = 'users/api/new_token/'
const SET_UP_TOTP_DEVICE_URL = 'users/api/set_up_totp_device/'
const SET_UP_SMS_DEVICE_URL = 'users/api/set_up_sms_device/'
const SEND_SMS_URL = 'users/api/send_sms/'
const GET_BACKUP_CODES_URL = 'users/api/get_backup_codes/'
const HAS_SAVED_BACKUP_CODES_URL = 'users/api/has_saved_backup_codes/'
const SET_SAVED_BACKUP_CODES_URL = 'users/api/set_saved_backup_codes/'
const CHECK_BACKUP_CODES_URL = 'users/api/check_backup_codes/'
const CASES_URL = 'dispute/api/groups/'
const DELETED_CASES_URL = 'dispute/api/groups/deleted/'
const SINGLE_CASE_URL = 'dispute/api/groups/group_id/'
const SCENARIOS_URL = 'dispute/api/groups/group_id/scenarios/'
const DELETED_SCENARIOS_URL = 'dispute/api/groups/group_id/scenarios/deleted/'
const SINGLE_SCENARIO_URL = 'dispute/api/groups/group_id/scenarios/scenario_id/'
const DUPLICATE_CASE_URL = 'dispute/api/groups/group_id/duplicate/'
const RESTORE_CASE_URL = 'dispute/api/groups/group_id/restore/'
const DUPLICATE_SCENARIO_URL =
  'dispute/api/groups/group_id/scenarios/scenario_id/duplicate/'
const RESTORE_SCENARIO_URL =
  'dispute/api/groups/group_id/scenarios/scenario_id/restore/'
const RECOVERY_SCENARIO_URL =
  'dispute/api/groups/group_id/scenarios/scenario_id/recovery/'
const REPORT_DETAIL_URL =
  'dispute/api/groups/group_id/scenarios/scenario_id/reportdata/'
const CANCEL_JOB_URL =
  'dispute/api/groups/group_id/scenarios/scenario_id/results/snapshot_id/cancel/'
const RESULTS_URL =
  'dispute/api/groups/group_id/scenarios/scenario_id/results/snapshot_id/'
const CALCULATE_TREE_URL = 'dispute/api/tree-direct/'
const ALL_USERS_URL = 'users/api/all_users/'
const GROUP_ROLES_URL = 'dispute/api/groups/group_id/roles/'
const FEATURES_URL = 'users/api/features/'
const WORDPRESS_URL = 'dispute/api/wordpress/post_type/slug/'
const COMPANY_URL = 'users/api/company/'
const LOGS_URL = 'dispute/api/log/'
const USER_SETTINGS_URL = 'users/api/user_settings/'
const CHECK_RESET_TOKEN_URL = 'users/api/check_reset_token/'
const ADD_USER_URL = 'users/api/add_user/'
const GET_TFA_DEVICE_TYPE_URL = 'users/api/get_tfa_device_type/'
const SHOW_BACKUP_CODES_URL = 'users/api/show_backup_codes/'
const LOGGED_IN_SEND_SMS_URL = 'users/api/change_tfa_send_sms/mode/'
const CHANGE_TFA_DEVICE_URL = 'users/api/change_tfa_confirm/'
const LOGGED_IN_SET_UP_TOTP_DEVICE_URL =
  'users/api/change_tfa_set_up_totp_device/'
const LOGGED_IN_SET_UP_SMS_DEVICE_URL =
  'users/api/change_tfa_set_up_sms_device/'
const DELETE_ACCOUNT_URL = 'users/api/delete_account/'
const DETAILED_USERS_URL = 'users/api/detailed_users/'
const UPDATE_USER_URL = 'users/api/update_user/'
const REMOVE_USER_URL = 'users/api/remove_user/'
const SEND_WELCOME_EMAIL_URL = 'users/api/send_welcome_email/'
const REMOVE_PASSWORD_URL = 'users/api/remove_password/'
const REMOVE_TFA_URL = 'users/api/remove_tfa_device/'
const TRANSFER_CASES_URL = 'dispute/api/transfer_cases/'
const NEW_REGISTRATION_LINK_URL = 'users/api/new_registration_link/'
const CREATE_ALL_TUTORIALS_URL = 'dispute/api/create_tutorials/'
const CREATE_TUTORIAL_URL = 'dispute/api/create_tutorials/tutorial_id/'
const GET_TUTORIAL_URL = 'dispute/api/get_tutorials/tutorial_id/'
const CHANGE_PASSWORD_CHECK_URL = 'users/api/change_password_check/'
const UPDATE_CHECKLIST_URL = 'users/api/user_checklist/'
const WHITELABEL_PROPERTIES_URL = 'users/api/whitelabel_properties/'
const EXPORT_RESULTS_URL = 'dispute/api/export_results/snapshot_id/party_id/'
const RESULTS_SETTINGS_URL =
  'dispute/api/groups/group_id/scenarios/scenario_id/resultssettings/'

export type UrlOption =
  | 'LOGIN_URL'
  | 'CONFIRM_OTP_URL'
  | 'LOGOUT_URL'
  | 'RESET_PASSWORD_URL'
  | 'NEW_PASSWORD_URL'
  | 'SET_PASSWORD_URL'
  | 'ME_URL'
  | 'NEW_TOKEN_URL'
  | 'CASES_URL'
  | 'DELETED_CASES_URL'
  | 'SCENARIOS_URL'
  | 'DELETED_SCENARIOS_URL'
  | 'SINGLE_CASE_URL'
  | 'SINGLE_SCENARIO_URL'
  | 'DUPLICATE_CASE_URL'
  | 'RESTORE_CASE_URL'
  | 'DUPLICATE_SCENARIO_URL'
  | 'RESTORE_SCENARIO_URL'
  | 'RECOVERY_SCENARIO_URL'
  | 'REPORT_DETAIL_URL'
  | 'CANCEL_JOB_URL'
  | 'SET_UP_TOTP_DEVICE_URL'
  | 'SET_UP_SMS_DEVICE_URL'
  | 'SEND_SMS_URL'
  | 'RESULTS_URL'
  | 'GET_BACKUP_CODES_URL'
  | 'HAS_SAVED_BACKUP_CODES_URL'
  | 'SET_SAVED_BACKUP_CODES_URL'
  | 'CHECK_BACKUP_CODES_URL'
  | 'CALCULATE_TREE_URL'
  | 'ALL_USERS_URL'
  | 'GROUP_ROLES_URL'
  | 'FEATURES_URL'
  | 'WORDPRESS_URL'
  | 'COMPANY_URL'
  | 'LOGS_URL'
  | 'USER_SETTINGS_URL'
  | 'CHECK_RESET_TOKEN_URL'
  | 'ADD_USER_URL'
  | 'GET_TFA_DEVICE_TYPE_URL'
  | 'SHOW_BACKUP_CODES_URL'
  | 'LOGGED_IN_SEND_SMS_URL'
  | 'CHANGE_TFA_DEVICE_URL'
  | 'DELETE_ACCOUNT_URL'
  | 'DETAILED_USERS_URL'
  | 'UPDATE_USER_URL'
  | 'REMOVE_USER_URL'
  | 'SEND_WELCOME_EMAIL_URL'
  | 'REMOVE_PASSWORD_URL'
  | 'REMOVE_TFA_URL'
  | 'TRANSFER_CASES_URL'
  | 'LOGGED_IN_SET_UP_TOTP_DEVICE_URL'
  | 'LOGGED_IN_SET_UP_SMS_DEVICE_URL'
  | 'NEW_REGISTRATION_LINK_URL'
  | 'CREATE_ALL_TUTORIALS_URL'
  | 'CREATE_TUTORIAL_URL'
  | 'GET_TUTORIAL_URL'
  | 'CHANGE_PASSWORD_CHECK_URL'
  | 'UPDATE_CHECKLIST_URL'
  | 'WHITELABEL_PROPERTIES_URL'
  | 'EXPORT_RESULTS_URL'
  | 'RESULTS_SETTINGS_URL'

export const getUrl = (option: UrlOption, ids?: string[]) => {
  let resUrl = ''

  switch (option) {
    case 'LOGIN_URL':
      resUrl += LOGIN_URL
      break
    case 'CONFIRM_OTP_URL':
      resUrl += CONFIRM_OTP_URL
      break
    case 'LOGOUT_URL':
      resUrl += LOGOUT_URL
      break
    case 'RESET_PASSWORD_URL':
      resUrl += RESET_PASSWORD_URL
      break
    case 'NEW_PASSWORD_URL':
      resUrl += NEW_PASSWORD_URL
      break
    case 'SET_PASSWORD_URL':
      resUrl += SET_PASSWORD_URL
      break
    case 'ME_URL':
      resUrl += ME_URL
      break
    case 'NEW_TOKEN_URL':
      resUrl += NEW_TOKEN_URL
      break
    case 'GET_BACKUP_CODES_URL':
      resUrl += GET_BACKUP_CODES_URL
      break
    case 'HAS_SAVED_BACKUP_CODES_URL':
      resUrl += HAS_SAVED_BACKUP_CODES_URL
      break
    case 'SET_SAVED_BACKUP_CODES_URL':
      resUrl += SET_SAVED_BACKUP_CODES_URL
      break
    case 'CHECK_BACKUP_CODES_URL':
      resUrl += CHECK_BACKUP_CODES_URL
      break
    case 'CASES_URL':
      resUrl += CASES_URL
      break
    case 'DELETED_CASES_URL':
      resUrl += DELETED_CASES_URL
      break
    case 'SCENARIOS_URL':
      resUrl += SCENARIOS_URL.replace('group_id', ids![0])
      break
    case 'DELETED_SCENARIOS_URL':
      resUrl += DELETED_SCENARIOS_URL.replace('group_id', ids![0])
      break
    case 'SINGLE_CASE_URL':
      resUrl += SINGLE_CASE_URL.replace('group_id', ids![0])
      break
    case 'SINGLE_SCENARIO_URL':
      resUrl += SINGLE_SCENARIO_URL.replace('group_id', ids![0]).replace(
        'scenario_id',
        ids![1],
      )
      break
    case 'DUPLICATE_CASE_URL':
      resUrl += DUPLICATE_CASE_URL.replace('group_id', ids![0])
      break
    case 'RESTORE_CASE_URL':
      resUrl += RESTORE_CASE_URL.replace('group_id', ids![0])
      break
    case 'DUPLICATE_SCENARIO_URL':
      resUrl += DUPLICATE_SCENARIO_URL.replace('group_id', ids![0]).replace(
        'scenario_id',
        ids![1],
      )
      break
    case 'RESTORE_SCENARIO_URL':
      resUrl += RESTORE_SCENARIO_URL.replace('group_id', ids![0]).replace(
        'scenario_id',
        ids![1],
      )
      break
    case 'RECOVERY_SCENARIO_URL':
      resUrl += RECOVERY_SCENARIO_URL.replace('group_id', ids![0]).replace(
        'scenario_id',
        ids![1],
      )
      break
    case 'REPORT_DETAIL_URL':
      resUrl += REPORT_DETAIL_URL.replace('group_id', ids![0]).replace(
        'scenario_id',
        ids![1],
      )
      break
    case 'CANCEL_JOB_URL':
      resUrl += CANCEL_JOB_URL.replace('group_id', ids![0])
        .replace('scenario_id', ids![1])
        .replace('snapshot_id', ids![2])
      break
    case 'RESULTS_URL':
      resUrl += RESULTS_URL.replace('group_id', ids![0])
        .replace('scenario_id', ids![1])
        .replace('snapshot_id', ids![2])
      break
    case 'SET_UP_TOTP_DEVICE_URL':
      resUrl += SET_UP_TOTP_DEVICE_URL
      break
    case 'SET_UP_SMS_DEVICE_URL':
      resUrl += SET_UP_SMS_DEVICE_URL
      break
    case 'SEND_SMS_URL':
      resUrl += SEND_SMS_URL
      break
    case 'CALCULATE_TREE_URL':
      resUrl += CALCULATE_TREE_URL
      break
    case 'ALL_USERS_URL':
      resUrl += ALL_USERS_URL
      break
    case 'GROUP_ROLES_URL':
      resUrl += GROUP_ROLES_URL.replace('group_id', ids![0])
      break
    case 'FEATURES_URL':
      resUrl += FEATURES_URL
      break
    case 'WORDPRESS_URL':
      resUrl += WORDPRESS_URL.replace('post_type', ids![0]).replace(
        'slug',
        ids![1],
      )
      break
    case 'COMPANY_URL':
      resUrl += COMPANY_URL
      break
    case 'LOGS_URL':
      resUrl += LOGS_URL
      break
    case 'USER_SETTINGS_URL':
      resUrl += USER_SETTINGS_URL
      break
    case 'CHECK_RESET_TOKEN_URL':
      resUrl += CHECK_RESET_TOKEN_URL
      break
    case 'ADD_USER_URL':
      resUrl += ADD_USER_URL
      break
    case 'GET_TFA_DEVICE_TYPE_URL':
      resUrl += GET_TFA_DEVICE_TYPE_URL
      break
    case 'SHOW_BACKUP_CODES_URL':
      resUrl += SHOW_BACKUP_CODES_URL
      break
    case 'LOGGED_IN_SEND_SMS_URL':
      resUrl += LOGGED_IN_SEND_SMS_URL.replace('mode', ids![0])
      break
    case 'CHANGE_TFA_DEVICE_URL':
      resUrl += CHANGE_TFA_DEVICE_URL
      break
    case 'LOGGED_IN_SET_UP_TOTP_DEVICE_URL':
      resUrl += LOGGED_IN_SET_UP_TOTP_DEVICE_URL
      break
    case 'LOGGED_IN_SET_UP_SMS_DEVICE_URL':
      resUrl += LOGGED_IN_SET_UP_SMS_DEVICE_URL
      break
    case 'DELETE_ACCOUNT_URL':
      resUrl += DELETE_ACCOUNT_URL
      break
    case 'DETAILED_USERS_URL':
      resUrl += DETAILED_USERS_URL
      break
    case 'UPDATE_USER_URL':
      resUrl += UPDATE_USER_URL
      break
    case 'REMOVE_USER_URL':
      resUrl += REMOVE_USER_URL
      break
    case 'SEND_WELCOME_EMAIL_URL':
      resUrl += SEND_WELCOME_EMAIL_URL
      break
    case 'REMOVE_PASSWORD_URL':
      resUrl += REMOVE_PASSWORD_URL
      break
    case 'REMOVE_TFA_URL':
      resUrl += REMOVE_TFA_URL
      break
    case 'TRANSFER_CASES_URL':
      resUrl += TRANSFER_CASES_URL
      break
    case 'NEW_REGISTRATION_LINK_URL':
      resUrl += NEW_REGISTRATION_LINK_URL
      break
    case 'CREATE_ALL_TUTORIALS_URL':
      resUrl += CREATE_ALL_TUTORIALS_URL
      break
    case 'CREATE_TUTORIAL_URL':
      resUrl += CREATE_TUTORIAL_URL.replace('tutorial_id', ids![0])
      break
    case 'GET_TUTORIAL_URL':
      resUrl += GET_TUTORIAL_URL.replace('tutorial_id', ids![0])
      break
    case 'CHANGE_PASSWORD_CHECK_URL':
      resUrl += CHANGE_PASSWORD_CHECK_URL
      break
    case 'UPDATE_CHECKLIST_URL':
      resUrl += UPDATE_CHECKLIST_URL
      break
    case 'WHITELABEL_PROPERTIES_URL':
      resUrl += WHITELABEL_PROPERTIES_URL
      break
    case 'EXPORT_RESULTS_URL':
      resUrl += EXPORT_RESULTS_URL.replace('snapshot_id', ids![0]).replace(
        'party_id',
        ids![1],
      )
      break
    case 'RESULTS_SETTINGS_URL':
      resUrl += RESULTS_SETTINGS_URL.replace('group_id', ids![0]).replace(
        'scenario_id',
        ids![1],
      )
      break
  }

  return process.env.REACT_APP_API_BASIC_URL + resUrl
}
