import React from 'react'
import { SubscriptionPlan } from '../../../../../models/generalTypes'
import { User } from '../../../../../models/user'
import DeleteUser from './DeleteUser'
import Security from './Security'
import UserDetails from './UserDetails'

type Props = {
  user: User
  loading: boolean
  setLoading: (value: boolean) => void
  changeUser: (newUserData?: any) => void
  hoverElement: string
  setHoverElement: (value: string) => void
  onClose: () => void
  onDeleteAccount: () => void
  onUpdateUser: (body: {
    username: string
    first_name?: string
    last_name?: string
    role?: string
  }) => void
  success: boolean
  showSaved: boolean
  showUpdateButton: boolean
  setShowUpdateButton: (value: boolean) => void
  subscriptionPlan: SubscriptionPlan
}

const UserDetailsPopUp = (props: Props) => {
  return (
    <>
      <UserDetails
        detailedUser={props.user}
        hoverElement={props.hoverElement}
        setHoverElement={props.setHoverElement}
        loading={props.loading}
        setLoading={props.setLoading}
        showSaved={props.showSaved}
        showUpdateButton={props.showUpdateButton}
        setShowUpdateButton={props.setShowUpdateButton}
        onUpdateUser={props.onUpdateUser}
        subscriptionPlan={props.subscriptionPlan}
      />
      <Security user={props.user} changeUser={props.changeUser} />
      <DeleteUser
        user={props.user}
        onClose={props.onClose}
        onDeleteAccount={props.onDeleteAccount}
        loading={props.loading}
        success={props.success}
      />
    </>
  )
}

export default UserDetailsPopUp
