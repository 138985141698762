import { keysIn } from 'lodash'
import {
  getReadableValueFromPartyFormatForReport,
  PartyFormat,
} from '../models/enums'
import { UserSettings } from '../models/userSettings'
import englishTexts from '../resources/texts/englishTexts.json'
import { getHelperText } from './dateFunctions'

function replacePartiesInText(
  text: string,
  partiesFormat: (keyof typeof PartyFormat | String)[],
) {
  let tempText = text
  if (
    tempText.toLowerCase().includes('the client') &&
    partiesFormat[0] !== '' &&
    !keysIn(PartyFormat).includes(partiesFormat[0] as string)
  ) {
    tempText = tempText.replaceAll('The client', 'tempReplacement0')
    tempText = tempText.replaceAll('the client', 'tempReplacement0')
  } else if (
    tempText.toLowerCase().includes('client') &&
    partiesFormat[0] !== ''
  ) {
    tempText = tempText.replaceAll('client', 'tempReplacement0-LowerCase')
    tempText = tempText.replaceAll('Client', 'tempReplacement0')
    tempText = tempText.replaceAll('CLIENT', 'tempReplacement0-UpperCase')
  }
  if (
    tempText.toLowerCase().includes('the opposing party') &&
    partiesFormat[1] !== '' &&
    !keysIn(PartyFormat).includes(partiesFormat[1] as string) &&
    partiesFormat[1] !== 'Opposing Party'
  ) {
    tempText = tempText.replaceAll('The opposing party', 'tempReplacement1')
    tempText = tempText.replaceAll('the opposing party', 'tempReplacement1')
  } else if (
    tempText.toLowerCase().includes('opposing party') &&
    partiesFormat[1] !== ''
  ) {
    tempText = tempText.replaceAll(
      'opposing party',
      'tempReplacement1-LowerCase',
    )
    tempText = tempText.replaceAll('Opposing Party', 'tempReplacement1')
    tempText = tempText.replaceAll(
      'OPPOSING PARTY',
      'tempReplacement1-UpperCase',
    )
  }

  let ownPartyFormat = partiesFormat[0] as string
  let otherPartyFormat = partiesFormat[1] as string
  if (ownPartyFormat === '') {
    ownPartyFormat = 'Client'
  } else {
    ownPartyFormat = getReadableValueFromPartyFormatForReport(ownPartyFormat)
  }
  if (otherPartyFormat === '') {
    otherPartyFormat = 'Opposing Party'
  } else {
    otherPartyFormat =
      getReadableValueFromPartyFormatForReport(otherPartyFormat)
  }

  tempText = tempText.replaceAll(
    'tempReplacement0-UpperCase',
    ownPartyFormat.toUpperCase(),
  )
  tempText = tempText.replaceAll(
    'tempReplacement0-LowerCase',
    ownPartyFormat.toLowerCase(),
  )
  tempText = tempText.replaceAll('tempReplacement0', ownPartyFormat as string)
  tempText = tempText.replaceAll(
    'tempReplacement1-UpperCase',
    otherPartyFormat.toUpperCase(),
  )
  tempText = tempText.replaceAll(
    'tempReplacement1-LowerCase',
    otherPartyFormat.toLowerCase(),
  )
  tempText = tempText.replaceAll('tempReplacement1', otherPartyFormat as string)

  return tempText
}

function replaceDatesInText(text: string, settings: UserSettings) {
  let tempText = text

  tempText = tempText.replaceAll('yyyy/mm/dd', getHelperText(settings))

  return tempText
}

export function getText(
  key: keyof typeof englishTexts,
  settings: UserSettings,
  partiesFormat?: (keyof typeof PartyFormat | String)[],
  language?: 'en',
) {
  let res = englishTexts[key]
  if (partiesFormat) {
    res = replacePartiesInText(englishTexts[key], partiesFormat)
  }
  res = replaceDatesInText(res, settings)

  return res
}

export function translateClaimTypeId(
  claimtypeId: string,
  settings: UserSettings,
  partiesFormat: (keyof typeof PartyFormat | String)[],
) {
  if (claimtypeId.includes('Decision Tree')) {
    //id='claimTypeIdTitle'
    // data-attribute="title-34"
    return claimtypeId.replace(
      'Decision Tree',
      getText('title-34', settings, partiesFormat),
    )
  }
  if (claimtypeId.includes('Client Claim')) {
    //id='claimTypeIdTitle'
    // data-attribute="title-32"
    return getSplitText(
      claimtypeId.replace(
        'Client Claim',
        getText('title-32', settings, partiesFormat),
      ),
      ' Claim',
      36,
      32,
    )
  }
  if (claimtypeId.includes('Opposing Party Claim')) {
    //id='claimTypeIdTitle'
    // data-attribute="title-33"
    return getSplitText(
      claimtypeId.replace(
        'Opposing Party Claim',
        getText('title-33', settings, partiesFormat),
      ),
      ' Claim',
      36,
      32,
    )
  }
}

export function saveMenuMessages(settings: UserSettings) {
  return {
    // data-textattribute = 'message-29'
    academy: getText('message-29', settings),
    // data-textattribute = 'message-94'
    settings: getText('message-94', settings),
    // data-textattribute = 'message-30'
    mycases: getText('message-30', settings),
    // data-textattribute = 'message-31'
    myscenarios: getText('message-31', settings),
    // data-textattribute = 'message-32'
    logOut: getText('message-32', settings),
    // data-textattribute = 'message-33'
    anotherScenario: getText('message-33', settings),
    // data-textattribute = 'message-34'
    closeReport: getText('message-34', settings),
  }
}

export function errorMessages(settings: UserSettings) {
  return {
    //data-textattribute = 'error-47, error-74'
    // eslint-disable-next-line no-useless-concat
    WRONG_CREDENTIALS: `${getText('error-47', settings)}\\n${getText(
      'error-74',
      settings,
    )}`,
    //data-textattribute = 'error-48'
    LOCKED_ACCOUNT: getText('error-48', settings),
    //data-textattribute = 'error-49'
    WRONG_CODE: getText('error-49', settings),
    //data-textattribute = 'error-50'
    WRONG_CODE_SMS: getText('error-50', settings),
    //data-textattribute = 'error-51'
    OTP_REQUEST_FAILED: getText('error-51', settings),
    //data-textattribute = 'error-52'
    NOT_ENOUGH_DIGITS: getText('error-52', settings),
    //data-textattribute = 'error-53'
    WRONG_BACKUP: getText('error-53', settings),
    //data-textattribute = 'error-54'
    WRONG_NUMBER: getText('error-54', settings),
    //data-textattribute = 'error-55'
    RESET_PASSWORD_FAILED: getText('error-55', settings),
    //data-textattribute = 'error-56'
    SETUP_TWO_FACTOR_ERROR: getText('error-56', settings),
    //data-textattribute = 'error-57'
    SET_SAVED_BACK_UP_CODES_FAILED: getText('error-57', settings),
    //data-textattribute = 'error-58'
    HAS_SAVED_BACK_UP_CODES_FAILED: getText('error-58', settings),
    //data-textattribute = 'error-59'
    GET_BACK_UP_CODES_FAILED: getText('error-59', settings),
    //data-textattribute = 'error-60'
    SETUP_SMS_DEVICE_FAILED: getText('error-60', settings),
    //data-textattribute = 'error-61'
    SEND_SMS_FAILED: getText('error-61', settings),
    //data-textattribute = 'error-62'
    LOGIN_FAILED: getText('error-62', settings),
    //data-textattribute = 'error-63'
    FORBIDDEN: getText('error-63', settings),
    //data-textattribute = 'error-64'
    CREATE_NEW_CASE_FAILED: getText('error-64', settings),
    //data-textattribute = 'error-65'
    SERVER_ERROR: getText('error-65', settings),
  }
}

//id='reportSectionType'
export function reportSectionType(settings: UserSettings) {
  return {
    //data-textattribute='title-132'
    ExecutiveSummary: getText('title-132', settings),
    //data-textattribute='title-133'
    CaseDescription: getText('title-133', settings),
    //data-textattribute='title-134'
    Proceedings: getText('title-134', settings),
    //data-textattribute='title-232'
    ClaimsOverview: getText('title-232', settings),
    //data-textattribute='title-135'
    Claims: getText('title-135', settings),
    //data-textattribute='title-136'
    LegalCosts: getText('title-136', settings),
    //data-textattribute='title-137'
    LegalCostsDistribution: getText('title-137', settings),
    //data-textattribute='title-138'
    EnforcementRisk: getText('title-138', settings),
    //data-textattribute='title-139'
    SelectionGraph: getText('title-139', settings),
    //data-textattribute='title-140'
    ProbabilityDistributionGraph: getText('title-140', settings),
    //data-textattribute='title-263'
    GetPayGraph: getText('title-263', settings),
    //data-textattribute='title-141'
    BestWorstScenario: getText('title-141', settings),
    //data-textattribute='title-142'
    SettlementAnalysis: getText('title-142', settings),
    //data-textattribute='title-138b'
    ResultsReport: getText('title-138b', settings),
    //data-textattribute='title-208'
    CustomSection: getText('title-208', settings),
  }
}

//id='reportSectionPlaceholder'
export function reportSectionPlaceholders(settings: UserSettings) {
  return {
    //data-textattribute='placeholder-4'
    ExecutiveSummary: getText('placeholder-4', settings),
    //data-textattribute='placeholder-5'
    CaseDescription: getText('placeholder-5', settings),
    //data-textattribute='placeholder-6'
    Proceedings: getText('placeholder-6', settings),
    //data-textattribute='placeholder-18'
    ClaimsOverview: getText('placeholder-18', settings),
    //data-textattribute='placeholder-7'
    Claims: getText('placeholder-7', settings),
    //data-textattribute='placeholder-8'
    LegalCosts: getText('placeholder-8', settings),
    //data-textattribute='placeholder-9'
    LegalCostsDistribution: getText('placeholder-9', settings),
    //data-textattribute='placeholder-10'
    EnforcementRisk: getText('placeholder-10', settings),
    //data-textattribute='placeholder-11'
    SelectionGraph: getText('placeholder-11', settings),
    //data-textattribute='placeholder-19'
    GetPayGraph: getText('placeholder-19', settings),
    //data-textattribute='placeholder-12'
    ProbabilityDistributionGraph: getText('placeholder-12', settings),
    //data-textattribute='placeholder-13'
    BestWorstScenario: getText('placeholder-13', settings),
    //data-textattribute='placeholder-14'
    SettlementAnalysis: getText('placeholder-14', settings),
    //data-textattribute='placeholder-4'
    ResultsReport: getText('placeholder-4', settings),
    //data-textattribute='placeholder-17'
    CustomSection: getText('placeholder-17', settings),
  }
}

export function getActionMessage(settings: UserSettings) {
  return {
    //data-textattribute='message-95'
    'Downloading Image': getText('message-95', settings),
    //data-textattribute='message-129'
    'Downloading Results': getText('message-129', settings),
    //data-textattribute='message-96'
    'Download Failed': getText('message-96', settings),
  }
}

export function getSplitText(
  string: string,
  splitter: string,
  stringLength: number,
  substringLength: number,
) {
  if (string.length > stringLength) {
    let properTextArray = string.split(splitter)
    properTextArray[0] =
      properTextArray[0].substring(0, substringLength).trim() + '...' + splitter
    string = properTextArray.join('')
  }
  return string
}
