import React from 'react'
import { useRecoilValue } from 'recoil'
import {
  AmountSignum,
  PartySignum,
} from '../../../../../../../../../models/enums'
import { DefaultSnapshotState } from '../../../../../../../../../models/generalTypes'
import { EventDetails } from '../../../../../../../../../models/treeModels/eventDetails'
import {
  EventId,
  NodeId,
} from '../../../../../../../../../models/treeModels/treeTypes'
import {
  roundNumberTo,
  treeAmountFontSize,
} from '../../../../../../../../../services/commonFunctions'
import { convertDateTimeToLocal } from '../../../../../../../../../services/dateFunctions'
import { formattedNumToString } from '../../../../../../../../../services/formatNum'
import { userState } from '../../../../../../../../../states/UserState'
import { ReportWhitelabel } from '../../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../../models/reportModels/reportEnums'
import Symbol from '../../../../../../../../../Components/symbol/Symbol'
import { krogerusColorsState } from '../../../../../../../../../states/KrogerusColorsState'

type Props = {
  eventDetails: EventDetails
  nodeId: NodeId
  eventId: EventId
  eventIndex: number
  treeIndex: number
  nodeHasAtLeastOneAwardedAmount: boolean
  nodeHasAtLeastOneOutOfCourtAmount: boolean
  nodeHasAtLeastOneInterestMenuOn: boolean
  nodeHasAtLeastOneReducedAmount: boolean
  nodeHasAtLeastOneEventTitle: boolean
  scenarioSnapshot: DefaultSnapshotState
  reportWhitelabel?: ReportWhitelabel
}

export default function TreeEventMinimisedForReport(props: Props) {
  const currency = props.scenarioSnapshot.currentSnapshot.currency
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  return (
    <div
      className="treeEventBackgroundMinimised"
      id={`treeEventBackgroundMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
    >
      <div
        className={`treeEventTitleContainerMinimised ${
          !props.nodeHasAtLeastOneEventTitle ? 'noEventTitle' : ''
        } ${krogerusColors ? 'krogerus' : ''}`}
        id={`treeEventTitleContainerMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
      >
        <p
          className="treeEventTitleMinimised"
          id={`treeEventTitleMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
          )}
        >
          {props.eventDetails.eventTitle}
        </p>
      </div>
      <div
        className="treeEventDetailsContainerMinimised"
        id={`treeEventDetailsContainerMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
      >
        {props.nodeHasAtLeastOneAwardedAmount ? (
          <>
            {props.eventDetails.awardedAmountSignum !== AmountSignum.off && (
              <Symbol
                symbolType={
                  props.eventDetails.awardedAmountSignum === AmountSignum.get
                    ? 'client'
                    : 'opposingParty'
                }
                targetId={`treeEventAwardedAmountTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                targetType={'treeAwardedText'}
              />
            )}
            <p
              className={`treeEventAwardedAmountTextMinimised robotoNumbers ${
                props.eventDetails.awardedAmountSignum === AmountSignum.get
                  ? 'positive'
                  : props.eventDetails.awardedAmountSignum === AmountSignum.pay
                  ? 'negative'
                  : ''
              } ${krogerusColors ? 'krogerus' : ''}`}
              style={{
                ...treeAmountFontSize(
                  `${formattedNumToString(
                    Math.abs(props.eventDetails.awardedAmount),
                    user.settings,
                  )} ${currency}`,
                ),
                ...getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'roboto',
                  false,
                  true,
                ),
              }}
              id={`treeEventAwardedAmountTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            >
              {props.eventDetails.awardedAmountSignum !== AmountSignum.off
                ? `${formattedNumToString(
                    Math.abs(props.eventDetails.awardedAmount),
                    user.settings,
                  )} ${currency}`
                : '-'}
            </p>

            {props.nodeHasAtLeastOneInterestMenuOn ? (
              props.eventDetails.hasInterest &&
              props.eventDetails.interestRate &&
              props.eventDetails.interestDate ? (
                <div
                  className="treeEventInterestTextMinimised"
                  id={`treeEventInterestTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                >
                  <p
                    className="treeEventInterestRateTextMinimised robotoNumbers"
                    id={`treeEventInterestRateTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                    style={
                      `${formattedNumToString(
                        roundNumberTo(props.eventDetails.interestRate * 100, 1),
                        user.settings,
                      )}%`.length > 3
                        ? {
                            marginRight: 3,
                            marginLeft: -7,
                            fontSize: '14px',
                            ...getStyleFromWhitelabel(
                              props.reportWhitelabel,
                              ReportWhitelabelKeys.sectionDescription,
                              FontLabels.regular,
                              'roboto',
                            ),
                          }
                        : getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'roboto',
                          )
                    }
                  >
                    {`${formattedNumToString(
                      roundNumberTo(props.eventDetails.interestRate * 100, 1),
                      user.settings,
                    )}%`}{' '}
                  </p>
                  <p
                    className="treeEventInterestDateTextMinimised robotoNumbers"
                    id={`treeEventInterestDateTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'roboto',
                    )}
                  >
                    {convertDateTimeToLocal(
                      props.eventDetails.interestDate,
                      user.settings,
                      true,
                    )}
                  </p>
                </div>
              ) : props.nodeHasAtLeastOneAwardedAmount ? (
                <p
                  className="treeEventNoInterestTextMinimised"
                  id={`treeEventInterestTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                  )}
                >
                  -
                </p>
              ) : null
            ) : null}
          </>
        ) : null}
        {(props.nodeHasAtLeastOneOutOfCourtAmount ||
          props.nodeHasAtLeastOneReducedAmount) && (
          <div className="gapBetweenAwards" style={{ height: 12 }}></div>
        )}
        {props.nodeHasAtLeastOneReducedAmount ? (
          <>
            {props.eventDetails.reducedAwardedAmountParty !==
              PartySignum.off && (
              <Symbol
                symbolType={
                  props.eventDetails.reducedAwardedAmountParty ===
                  PartySignum.client
                    ? 'reducedClientNoParenthesis'
                    : 'reducedOpposingPartyNoParenthesis'
                }
                targetId={`treeEventReducedAmountTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                targetType={'treeReducedText'}
              />
            )}
            <p
              className={`treeEventReducedAmountTextMinimised robotoNumbers ${
                props.eventDetails.reducedAwardedAmountParty ===
                PartySignum.client
                  ? 'positive'
                  : props.eventDetails.reducedAwardedAmountParty ===
                    PartySignum.opposingParty
                  ? 'negative'
                  : ''
              } ${
                !props.nodeHasAtLeastOneAwardedAmount &&
                !props.nodeHasAtLeastOneOutOfCourtAmount
                  ? 'onlyReduced'
                  : ''
              } ${krogerusColors ? 'krogerus' : ''}`}
              style={treeAmountFontSize(
                `(${formattedNumToString(
                  props.eventDetails.reducedAwardedAmount ?? 0,
                  user.settings,
                )} ${currency})`,
              )}
              id={`treeEventReducedAmountTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            >
              {props.eventDetails.reducedAwardedAmountParty !== PartySignum.off
                ? `(${formattedNumToString(
                    props.eventDetails.reducedAwardedAmount ?? 0,
                    user.settings,
                  )} ${currency})`
                : '-'}
            </p>
          </>
        ) : null}
        {props.nodeHasAtLeastOneOutOfCourtAmount &&
          props.nodeHasAtLeastOneReducedAmount && (
            <div className="gapBetweenAwards" style={{ height: 4 }}></div>
          )}
        {props.nodeHasAtLeastOneOutOfCourtAmount ? (
          <>
            {props.eventDetails.outOfCourtAmountSignum !== AmountSignum.off && (
              <Symbol
                symbolType={'positiveExternalClient'}
                targetId={`treeEventOutOfCourtAmountTextMinimisedContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                targetType={'treeExternalText'}
              />
            )}
            <div
              className="treeEventOutOfCourtAmountTextMinimisedContainer"
              id={`treeEventOutOfCourtAmountTextMinimisedContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            >
              <p
                className={`treeEventOutOfCourtAmountTextMinimised robotoNumbers ${
                  props.eventDetails.outOfCourtAmountSignum !== AmountSignum.off
                    ? `border positive ${krogerusColors ? 'krogerus' : ''}`
                    : undefined
                }`}
                style={{
                  ...treeAmountFontSize(
                    `${formattedNumToString(
                      props.eventDetails.outOfCourtAmount,
                      user.settings,
                    )} ${currency}`,
                  ),
                  ...getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'roboto',
                    false,
                    true,
                  ),
                }}
                id={`treeEventOutOfCourtAmountTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
              >
                {props.eventDetails.outOfCourtAmountSignum !== AmountSignum.off
                  ? `${formattedNumToString(
                      props.eventDetails.outOfCourtAmount,
                      user.settings,
                    )} ${currency}`
                  : '-'}
              </p>
            </div>
          </>
        ) : null}
        <div
          className="treeEventProbabilityBottomSpaceMinimised"
          id={`treeEventProbabilityBottomSpaceMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
          style={
            !props.nodeHasAtLeastOneAwardedAmount &&
            !props.nodeHasAtLeastOneOutOfCourtAmount
              ? { height: 10 }
              : { height: 22 }
          }
        ></div>
      </div>
      <div
        className="treeEventProbabilityContainerMinimised"
        id={`treeEventProbabilityContainerMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
      >
        <div
          className="treeEventProbabilityCircleMinimised"
          id={`treeEventProbabilityCircleMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
        >
          <p
            className="treeEventProbabilityMinimised robotoNumbersBold"
            id={`treeEventProbabilityMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'roboto',
            )}
          >
            {Math.round(props.eventDetails.probability * 100)}%
          </p>
        </div>
      </div>
    </div>
  )
}
