import React, { useState, useEffect, useRef } from 'react'

import ResultsList from './resultsList/ResultsList'
import BestWorstScenario from './resultsList/BestWorstScenario'
import Button from '../../../../../../Components/Buttons/Button/Button'
import Chip from '../../../../../../Components/Buttons/Chip/Chip'
import HoverComponent from '../../../../../../Components/InfoHover/HoverComponent/HoverComponent'

import firstTrialImg from '../../../../../../resources/images/137-first.svg'
import secondTrialImg from '../../../../../../resources/images/140-second.svg'
import bankruptcyFirstTrialImg from '../../../../../../resources/images/138-firstCourtBunkruptcy.svg'
import bankruptcySecondTrialImg from '../../../../../../resources/images/139-secondCourtBunkruptcy.svg'
import { ScenarioSnapshot } from '../../../../../../models/scenarioSnapshot'
import { ResultsObject } from '../../../../../../models/generalTypes'
import {
  ActionMessage,
  TypeOfInstance,
  VideoPostName,
} from '../../../../../../models/enums'
import { getText } from '../../../../../../services/textFunctions'
import VideoInfoButton from '../../../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { fullscreenActionMessageState } from '../../../../../../states/FullScreenActionMessageState'
import DownloadImage from '../../../../../../Components/downloadImages/DownloadImage'
import { scenarioIdentityState } from '../../../../../../states/ScenarioIdentityState'
import { freemiumState } from '../../../../../../states/FreemiumState'
import { logActivity } from '../../../../../../services/commonFunctions'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'

type Props = {
  resultsFromBackend: ResultsObject | undefined
  currentSnapshot: ScenarioSnapshot
  widthOfDiv: number
  heightOfDiv: number
  setVideoPostName: (param: VideoPostName | undefined) => void
  resultsViewParty: 'client' | 'opposing'
}

export default function DetailedResults(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const fullscreenActionMessage = useRecoilValue(fullscreenActionMessageState)

  //const { getValues } = useFormContext()
  /* 
  tabs:
  0 - RESULTS TABLE
  1 - BEST & WORST SCENARIO
  */

  const [tab, setTab] = useState(
    props.resultsFromBackend!.no_of_possible_outcomes > 1000 ? 1 : 0,
  )
  const [chipState, setChipState] = useState({
    IsBankruptcy: false,
    IsSecondTrial: false,
  })
  const [chipFilter, setChipFilter] = useState({
    chipFirstTrial: 'hide',
    chipSecondTrial: 'hide',
    chipBankruptcyFirstTrial: 'hide',
    chipBankruptcySecondTrial: 'hide',
  })

  const [hoverElement, setHoverElement] = useState('')
  const secondaryHover = useRef('open')
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const [saveBestAndWorstImageName, setSaveBestAndWorstImageName] = useState('')
  const [excelResultsName, setExcelResultsName] = useState('')

  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const currentSnapshot = scenarioSnapshot.currentSnapshot
  const partiesFormat = [
    currentSnapshot.partyFormatOwn,
    currentSnapshot.partyFormatOther,
  ]

  useEffect(() => {
    let tempSaveTreeImageName = `${scenarioIdentity.caseName} - ${
      scenarioIdentity.scenarioName
    } - ${getText('button-38', user.settings)
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1)
      })
      .join(' ')} - ${
      props.resultsViewParty === 'client'
        ? getText('title-260', user.settings, partiesFormat)
        : getText('title-261', user.settings, partiesFormat)
    }`
    setSaveBestAndWorstImageName(tempSaveTreeImageName)

    const forbiddenChars = /[\\/:*?"<>|]/g

    const cleanFileNameFunc = (name: string) => {
      return name.replace(forbiddenChars, '')
    }

    setExcelResultsName(
      scenarioIdentity.caseName +
        ' - ' +
        scenarioIdentity.scenarioName +
        ' - ' +
        (props.resultsViewParty === 'client'
          ? cleanFileNameFunc(
              getText('title-223', user.settings, partiesFormat),
            )
          : cleanFileNameFunc(
              getText('title-224', user.settings, partiesFormat),
            )) +
        "'s " +
        'Results.xlsx',
    )
    // eslint-disable-next-line
  }, [scenarioIdentity, props.resultsViewParty])

  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  // set chipState from myCalculation bankruptcy_flag and second_trial_flag

  useEffect(() => {
    const tempChipFilter = {
      IsBankruptcy: false,
      IsSecondTrial: false,
    }

    if (props.resultsFromBackend!.has_bankruptcy) {
      tempChipFilter.IsBankruptcy = true
    } else {
      setChipFilter({
        ...chipFilter,
        chipBankruptcyFirstTrial: 'hide',
        chipBankruptcySecondTrial: 'hide',
      })
    }

    if (props.resultsFromBackend!.max_trial_count === 2) {
      tempChipFilter.IsSecondTrial = true
    }

    setChipState(tempChipFilter)

    // eslint-disable-next-line
  }, [props.resultsFromBackend])

  function handleChangeTab(num: number) {
    setTab(num)
  }

  function handleChip(id: string, state: 'on' | 'off') {
    var tempChipFilter = JSON.parse(JSON.stringify(chipFilter))
    if (state === 'on') {
      tempChipFilter[id] = 'show'
    }
    if (state === 'off') {
      tempChipFilter[id] = 'hide'
    }
    setChipFilter(tempChipFilter)
  }

  const canDownloadDetailedResults =
    props.resultsFromBackend !== undefined &&
    props.resultsFromBackend.no_of_possible_outcomes <= 1000

  const showingMessage =
    fullscreenActionMessage === ActionMessage.downloadingImage ||
    fullscreenActionMessage === ActionMessage.downloadingExcel

  return (
    <div
      className="tool-component results-tool-component"
      id="detailedResultsComp"
      style={showingMessage ? { marginTop: 0 } : undefined}
    >
      {tab === 1 ? (
        <div
          className="downloadImageComponentContainer"
          style={showingMessage ? { visibility: 'hidden' } : undefined}
        >
          <DownloadImage
            id={'bestWorstCaseScenario'}
            tooltipText={getText('tooltip-34', user.settings)}
            tooltipTextAttribute={'tooltip-34'}
            imageElementId={`detailedResultsComp`}
            saveName={saveBestAndWorstImageName}
            downloadImageType="results"
          />
          <DownloadImage
            id={'detailedResults'}
            tooltipText={
              canDownloadDetailedResults
                ? getText('tooltip-65', user.settings)
                : getText('tooltip-66', user.settings)
            }
            tooltipTextAttribute={tab === 1 ? 'tooltip-000' : 'tooltip-000'}
            imageElementId={``}
            saveName={excelResultsName}
            downloadImageType={
              canDownloadDetailedResults ? 'excel' : 'excel-inactive'
            }
            snapshotId={scenarioIdentity.snapshotId}
            resultsViewParty={props.resultsViewParty}
          />
        </div>
      ) : (
        <div
          className="downloadImageComponentContainer"
          style={showingMessage ? { visibility: 'hidden' } : undefined}
        >
          <DownloadImage
            id={'detailedResults'}
            tooltipText={
              canDownloadDetailedResults
                ? getText('tooltip-65', user.settings)
                : getText('tooltip-66', user.settings)
            }
            tooltipTextAttribute={tab === 1 ? 'tooltip-000' : 'tooltip-000'}
            imageElementId={``}
            saveName={excelResultsName}
            downloadImageType={
              canDownloadDetailedResults ? 'excel' : 'excel-inactive'
            }
            snapshotId={scenarioIdentity.snapshotId}
            resultsViewParty={props.resultsViewParty}
          />
        </div>
      )}

      <div
        className="tool-component-title"
        id="detailedResults-title"
        data-textattribute="title-40"
      >
        {!showingMessage ? (
          <>
            {getText('title-40', user.settings)}{' '}
            <div className="videoInfoButtonComponentContainer">
              <VideoInfoButton
                textAttribute="tooltip-19"
                postName={VideoPostName.resultsDetailedResults}
                setVideoPostName={props.setVideoPostName}
              />
            </div>
          </>
        ) : tab === 1 ? (
          getText('button-38', user.settings)
            .toLowerCase()
            .split(' ')
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        ) : (
          getText('button-38', user.settings)
            .toLowerCase()
            .split(' ')
            .map((word) => {
              return word[0].toUpperCase() + word.substring(1)
            })
            .join(' ')
        )}
      </div>
      {props.resultsFromBackend && props.currentSnapshot ? (
        <div className="resultsContent" id="resultsTable">
          <div
            className="selection-tabs marginLeft1"
            style={showingMessage ? { display: 'none' } : undefined}
          >
            <Button
              id={'resultsListTabButton'}
              buttonText={getText('button-37', user.settings)}
              buttonTextAttribute="button-37"
              buttonType={tab === 0 ? 'contained' : 'outlined'}
              type="button"
              onClick={() => {
                handleChangeTab(0)
                //Mixpanel 74 (All)
                logActivity(freemium.isFreemium, 'Watched results list')
              }}
              side="left"
              NoUpperCase={true}
              small={false}
            />

            <Button
              id={'bestWorstListTabButton'}
              buttonText={getText('button-38', user.settings)}
              buttonTextAttribute="button-38"
              buttonType={tab === 1 ? 'contained' : 'outlined'}
              type="button"
              onClick={() => {
                handleChangeTab(1)
                //Mixpanel 75 (All)
                logActivity(
                  freemium.isFreemium,
                  'Watched best & worst scenario',
                )
              }}
              side="left"
              NoUpperCase={true}
              small={false}
            />
            {/* <Button
              id={2}
              label="20% TABLE"
              buttonType={tab === 2 ? 'contained' : 'outlined'}
              type="button"
              onClick={() => handleChangeTab(2)}
              side="left"
                  NoUpperCase={true}
                  small={false}
            /> */}
          </div>
          {props.resultsFromBackend.no_of_possible_outcomes > 1000 ? null : (
            <div
              className="selection-chips marginLeft1"
              style={
                showingMessage
                  ? { display: 'none' }
                  : tab === 0
                  ? undefined
                  : { visibility: 'hidden' }
              }
              data-openreplay-obscured
            >
              <div
                className="chipPlaceContainer"
                onMouseEnter={() => {
                  secondaryHover.current = 'chipFirstTrial'
                  setTimeout(() => {
                    if (secondaryHover.current === 'chipFirstTrial') {
                      setHoverElement('chipFirstTrial')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                <Chip
                  id="chipFirstTrial"
                  img={firstTrialImg}
                  defaultState="off"
                  onClick={(state: 'on' | 'off') =>
                    handleChip('chipFirstTrial', state)
                  }
                />

                {hoverElement === 'chipFirstTrial' ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '50px', right: '-110px' }}
                  >
                    <HoverComponent
                      hoverText={getText('hover-25', user.settings)}
                      textAttribute="hover-25"
                      id="chipFirstTrial"
                    />
                  </div>
                ) : null}
              </div>

              {chipState.IsSecondTrial ? (
                <div
                  className="chipPlaceContainer"
                  onMouseEnter={() => {
                    secondaryHover.current = 'chipSecondTrial'
                    setTimeout(() => {
                      if (secondaryHover.current === 'chipSecondTrial') {
                        setHoverElement('chipSecondTrial')
                      }
                    }, 1000)
                  }}
                  onMouseLeave={() => {
                    secondaryHover.current = 'close'
                    setHoverElement('')
                  }}
                >
                  <Chip
                    id="chipSecondTrial"
                    img={secondTrialImg}
                    defaultState="off"
                    onClick={(state: 'on' | 'off') =>
                      handleChip('chipSecondTrial', state)
                    }
                  />
                  {hoverElement === 'chipSecondTrial' ? (
                    <div
                      className="hoverComponentContainer"
                      style={{ top: '50px', right: '-110px' }}
                    >
                      <HoverComponent
                        hoverText={getText('hover-26', user.settings)}
                        textAttribute="hover-26"
                        id="chipSecondTrial"
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
              {chipState.IsBankruptcy ? (
                <>
                  <div
                    className="chipPlaceContainer"
                    onMouseEnter={() => {
                      secondaryHover.current = 'chipBankruptcyFirstTrial'
                      setTimeout(() => {
                        if (
                          secondaryHover.current === 'chipBankruptcyFirstTrial'
                        ) {
                          setHoverElement('chipBankruptcyFirstTrial')
                        }
                      }, 1000)
                    }}
                    onMouseLeave={() => {
                      secondaryHover.current = 'close'
                      setHoverElement('')
                    }}
                  >
                    <Chip
                      id="chipBankruptcyFirstTrial"
                      img={bankruptcyFirstTrialImg}
                      defaultState="off"
                      onClick={(state: 'on' | 'off') =>
                        handleChip('chipBankruptcyFirstTrial', state)
                      }
                    />
                    {hoverElement === 'chipBankruptcyFirstTrial' ? (
                      <div
                        className="hoverComponentContainer"
                        style={{ top: '50px', right: '-110px' }}
                      >
                        <HoverComponent
                          hoverText={
                            props.currentSnapshot.court ===
                            TypeOfInstance.Public_Court
                              ? getText('hover-27', user.settings)
                              : props.currentSnapshot.court ===
                                TypeOfInstance.Arbitration
                              ? getText('hover-28', user.settings)
                              : getText('hover-29', user.settings)
                          }
                          textAttribute={
                            props.currentSnapshot.court ===
                            TypeOfInstance.Public_Court
                              ? 'hover-27'
                              : props.currentSnapshot.court ===
                                TypeOfInstance.Arbitration
                              ? 'hover-28'
                              : 'hover-29'
                          }
                          id="chipBankruptcyFirstTrial"
                        />
                      </div>
                    ) : null}
                  </div>
                  {chipState.IsSecondTrial ? (
                    <div
                      className="chipPlaceContainer"
                      onMouseEnter={() => {
                        secondaryHover.current = 'chipBankruptcySecondTrial'
                        setTimeout(() => {
                          if (
                            secondaryHover.current ===
                            'chipBankruptcySecondTrial'
                          ) {
                            setHoverElement('chipBankruptcySecondTrial')
                          }
                        }, 1000)
                      }}
                      onMouseLeave={() => {
                        secondaryHover.current = 'close'
                        setHoverElement('')
                      }}
                    >
                      <Chip
                        id="chipBankruptcySecondTrial"
                        img={bankruptcySecondTrialImg}
                        defaultState="off"
                        onClick={(state: 'on' | 'off') =>
                          handleChip('chipBankruptcySecondTrial', state)
                        }
                      />
                      {hoverElement === 'chipBankruptcySecondTrial' ? (
                        <div
                          className="hoverComponentContainer"
                          style={{ top: '50px', right: '-110px' }}
                        >
                          <HoverComponent
                            hoverText={
                              props.currentSnapshot.court ===
                              TypeOfInstance.Public_Court
                                ? getText('hover-30', user.settings)
                                : props.currentSnapshot.court ===
                                  TypeOfInstance.Arbitration
                                ? getText('hover-31', user.settings)
                                : getText('hover-32', user.settings)
                            }
                            textAttribute={
                              props.currentSnapshot.court ===
                              TypeOfInstance.Public_Court
                                ? 'hover-30'
                                : props.currentSnapshot.court ===
                                  TypeOfInstance.Arbitration
                                ? 'hover-31'
                                : 'hover-32'
                            }
                            id="chipBankruptcySecondTrial"
                          />
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : null}
            </div>
          )}
          <div className="graphContainer">
            <div
              className="tab-content"
              id="graphContainer"
              data-openreplay-obscured
            >
              {tab === 0 ? (
                props.resultsFromBackend.no_of_possible_outcomes > 1000 ? (
                  <h4
                    className="notAvailable"
                    style={{ width: props.widthOfDiv - 300 }}
                    id="detailedResultsNotAvailableDescription"
                    data-textattribute="description-75"
                  >
                    {getText('description-75', user.settings)}
                  </h4>
                ) : (
                  <ResultsList
                    resultsFromBackend={props.resultsFromBackend!}
                    currentSnapshot={props.currentSnapshot}
                    chipFilter={chipFilter}
                    resultsViewParty={props.resultsViewParty}
                  />
                )
              ) : tab === 1 ? (
                <BestWorstScenario
                  widthOfDiv={props.widthOfDiv}
                  heightOfDiv={props.heightOfDiv}
                  resultsFromBackend={props.resultsFromBackend!}
                  currentSnapshot={props.currentSnapshot}
                  resultsViewParty={props.resultsViewParty}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}
